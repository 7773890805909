import React from "react";

export const svgComponents = {

    LogOutBtn: <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22.001" viewBox="0 0 22 22.001">
        <g id="logout_icon" transform="translate(-286.229 -425.225)">
            <path id="Path_9322" data-name="Path 9322" d="M199.72,117.926l9.107,9.107a1.9,1.9,0,0,1,0,2.68l-9.1,9.1a1.9,1.9,0,0,1-2.682,0l-9.1-9.1a1.9,1.9,0,0,1,0-2.682l9.1-9.1a1.894,1.894,0,0,1,2.68,0Zm4.418,11.035a.929.929,0,0,0-.005-1.33l-4.468-4.389a.757.757,0,1,0-1.071,1.069l2.986,2.978c.147.147.1.265-.113.265h-8.239a.756.756,0,1,0,0,1.512h8.239c.209,0,.259.119.113.266l-2.986,2.977a.757.757,0,1,0,1.071,1.069Z" transform="translate(98.848 307.853)" fill="#5d5d5d" fillRule="evenodd" />
        </g>
    </svg>,

    Language: <svg xmlns="http://www.w3.org/2000/svg" width="27.163" height="19.363" viewBox="0 0 27.163 19.363">
        <g id="language_icon" transform="translate(-77 -84)">
            <path id="Path_9641" data-name="Path 9641" d="M448.168,226.89l1.458,4.006H446.71Z" transform="translate(-351.448 -135.832)" fill="#5d5d5d" />
            <path id="Path_9642" data-name="Path 9642" d="M336,89.532c0-2.608,0-3.912.81-4.722s2.114-.81,4.722-.81h3.257c2.694,0,4.041,0,4.857.845s.768,2.192.672,4.884l-.3,8.3c-.09,2.522-.135,3.784-.939,4.56s-2.066.775-4.59.775h-2.96c-2.608,0-3.912,0-4.722-.81s-.81-2.114-.81-4.722Zm3.958,9.682H337.75L341.8,88.1l2.06-.552L348.1,99.214H345.9l-.755-2.075h-4.427Z" transform="translate(-246.206 0)" fill="#5d5d5d" fillRule="evenodd" />
            <path id="Path_9643" data-name="Path 9643" d="M77,130.737h4.5v-1.383l2.075-.553v1.936h4.149v2.075H86.33a9.3,9.3,0,0,1-2.242,5.731,7.221,7.221,0,0,0,3.632,1.177v.008l-.542,2.033a9.288,9.288,0,0,1-4.645-1.773,9.286,9.286,0,0,1-4.677,1.776l-.509-2.036v-.008a7.221,7.221,0,0,0,3.631-1.177,9.318,9.318,0,0,1-1.87-3.437l2.005-.538a7.247,7.247,0,0,0,1.42,2.6,7.229,7.229,0,0,0,1.721-4.358H77.518Z" transform="translate(0 -42.588)" fill="#5d5d5d" />
        </g>
    </svg>,

    Rooms: (fillColor) => <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill={fillColor}>
        <g id="rooms" transform="translate(-94.125 -78.567)">
            <rect id="Rectangle_3975" data-name="Rectangle 3975" width="46" height="46" transform="translate(94.125 78.567)" opacity="0" />
            <path id="Path_1488" data-name="Path 1488" d="M122.064,295.179a8.581,8.581,0,0,0-.086-.982c0-.017,0-.034-.007-.051-.021-.127-.041-.25-.068-.376a7.675,7.675,0,0,0-.233-.876v0c-.034-.1-.068-.188-.106-.284a6.418,6.418,0,0,0-1.047-1.755c-.01-.014-.021-.024-.031-.038s-.021-.024-.031-.038c-.082-.092-.25-.267-.25-.267l-.092-.092-.174-.164a7.648,7.648,0,0,0-2.135-1.341,13.348,13.348,0,0,0-4.454-.958,18.847,18.847,0,0,0-4,.178,8.762,8.762,0,0,0-5.624,2.853,6.54,6.54,0,0,0-1.3,2.942,14.515,14.515,0,0,0-.13,2.528v1.187a2.353,2.353,0,0,0,.007.311,1.29,1.29,0,0,0,.53.9,1.7,1.7,0,0,0,1.074.233h16.874a1.281,1.281,0,0,0,.879-.342h-.01a1.35,1.35,0,0,0,.26-.342,1.376,1.376,0,0,0,.14-.424v0a3.719,3.719,0,0,0,.017-.438v-1.98c0-.01,0-.021.007-.031,0-.113-.007-.229-.01-.345Z" transform="translate(0 -187.243)" />
            <path id="Path_1489" data-name="Path 1489" d="M419.491,318.257a.3.3,0,0,1-.01.082v1.8a1.25,1.25,0,0,1-.328.93,1.161,1.161,0,0,1-.824.335c-.671.007-1.344,0-2.015,0h-7.006a2.192,2.192,0,0,0,.154-.342,2.418,2.418,0,0,0,.092-.342,4.614,4.614,0,0,0,.075-.924v-1.83a1.488,1.488,0,0,0,.01-.174,8.635,8.635,0,0,0-1.273-4.69c-.1-.144-.192-.284-.294-.421-.075-.1-.154-.2-.236-.3s-.164-.195-.25-.287a13.261,13.261,0,0,1,2.319-.339,15.714,15.714,0,0,1,3.821.209,8.75,8.75,0,0,1,3.1,1.153,5.63,5.63,0,0,1,1.946,2.052,6.593,6.593,0,0,1,.715,3.082Z" transform="translate(-286.197 -209.562)" />
            <path id="Path_1490" data-name="Path 1490" d="M395.068,144.349c0,.007-.007.01-.007.017s0,0,0,.007a5.77,5.77,0,0,1-.062.729,4.709,4.709,0,1,1,.065-.773c0,.007.007.014.007.021Z" transform="translate(-265.548 -48.201)" />
            <path id="Path_1491" data-name="Path 1491" d="M183.75,93.661c0,.01.007.017.007.021a5.863,5.863,0,0,1-.068.848,5.492,5.492,0,1,1,.068-.889c0,.007-.007.014-.007.021Z" transform="translate(-66.1)" />
        </g>
    </svg>,

    Lobby: (fillColor) => <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill={fillColor} >
        <g id="lobby_icon" transform="translate(7 9)">
            <path id="Path_39" data-name="Path 39" d="M8.333,14.556v2.889H22.778V14.556A5.778,5.778,0,0,1,27.111,8.98V7.333A4.346,4.346,0,0,0,22.778,3H8.333A4.346,4.346,0,0,0,4,7.333V8.98A5.778,5.778,0,0,1,8.333,14.556Z" transform="translate(0.333 -3)" />
            <path id="Path_40" data-name="Path 40" d="M29.889,9A2.9,2.9,0,0,0,27,11.889v5.778H6.778V11.889a2.889,2.889,0,0,0-5.778,0v7.222a4.346,4.346,0,0,0,4.333,4.333v1.444a1.444,1.444,0,1,0,2.889,0V23.444H25.556v1.444a1.444,1.444,0,1,0,2.889,0V23.444a4.346,4.346,0,0,0,4.333-4.333V11.889A2.9,2.9,0,0,0,29.889,9Z" transform="translate(-1 -0.333)" />
            <rect id="Rectangle_3972" data-name="Rectangle 3972" width="46" height="46" transform="translate(-7 -9)" fill="#fff" opacity="0" />
        </g>
    </svg>,

    LobbyRoomsNav: (fillColor) => <svg xmlns="http://www.w3.org/2000/svg" width="43.016" height="23.463" viewBox="0 0 43.016 23.463" fill={fillColor}>
        <path id="rooms_icon" d="M21.508,19.2a17.688,17.688,0,0,1,7.6,1.76A5.886,5.886,0,0,1,32.262,26.3v3.168H10.754V26.315a5.843,5.843,0,0,1,3.155-5.338A17.409,17.409,0,0,1,21.508,19.2Zm-14.339.489a3.769,3.769,0,0,0,3.585-3.911,3.769,3.769,0,0,0-3.585-3.911,3.769,3.769,0,0,0-3.585,3.911A3.769,3.769,0,0,0,7.169,19.687Zm2.025,2.151a11.5,11.5,0,0,0-2.025-.2,11.578,11.578,0,0,0-4.983,1.134A3.956,3.956,0,0,0,0,26.394v3.07H8.066V26.315A9.41,9.41,0,0,1,9.195,21.838Zm26.652-2.151a3.769,3.769,0,0,0,3.585-3.911,3.769,3.769,0,0,0-3.585-3.911,3.769,3.769,0,0,0-3.585,3.911A3.769,3.769,0,0,0,35.847,19.687Zm7.169,6.707a3.956,3.956,0,0,0-2.187-3.617,11.238,11.238,0,0,0-7.008-.939,9.41,9.41,0,0,1,1.129,4.478v3.148h8.066ZM21.508,6a5.63,5.63,0,0,1,5.377,5.866,5.63,5.63,0,0,1-5.377,5.866,5.63,5.63,0,0,1-5.377-5.866A5.63,5.63,0,0,1,21.508,6Z" transform="translate(0 -6)" />
    </svg>,

    LobbyExpoNav: (fillColor) => <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill={fillColor}>
        <g id="expo__icon" transform="translate(-78.565 -64.626)">
            <rect id="Rectangle_3976" data-name="Rectangle 3976" width="46" height="46" transform="translate(78.565 64.626)" opacity="0" />
            <path id="Union_14" data-name="Union 14" d="M-1794.3-302.572a1.461,1.461,0,0,1-1.123-1.6l.481-3.885a5.28,5.28,0,0,1,2.406-3.766,7.1,7.1,0,0,0,4.573,1.642,7.33,7.33,0,0,0,4.573-1.641,5.2,5.2,0,0,1,2.407,3.765l.481,3.885a1.427,1.427,0,0,1-1.123,1.6,32.381,32.381,0,0,1-6.337.6A32.382,32.382,0,0,1-1794.3-302.572Zm16.2-1.522v-.32l-.321-2.564h18.171v-21.471h-27.035v3.685a3.662,3.662,0,0,0-.682-.04,6.7,6.7,0,0,0-2.206.321v-5.447a1.437,1.437,0,0,1,1.444-1.443h29.963a1.436,1.436,0,0,1,1.444,1.442v24.4a1.463,1.463,0,0,1-1.445,1.442Zm-.963-6.209a8.114,8.114,0,0,0-2.527-3.245l4.733-5.088a1.17,1.17,0,0,1,1.564,0l2.928,3.085,1.484-1.562a1.168,1.168,0,0,1,1.564,0l4.774,4.967a1.117,1.117,0,0,1,.2,1.161,1.081,1.081,0,0,1-.963.681Zm-4.332-1.522h0Zm-9.466-5.688a4.89,4.89,0,0,1,4.894-4.887,4.89,4.89,0,0,1,4.893,4.887,4.89,4.89,0,0,1-4.893,4.887A4.89,4.89,0,0,1-1792.859-317.513Zm20.257-5.168a2.365,2.365,0,0,1,2.366-2.363,2.365,2.365,0,0,1,2.367,2.363,2.366,2.366,0,0,1-2.367,2.364A2.365,2.365,0,0,1-1772.6-322.681Z" transform="translate(1875.515 405.359)" />
        </g>
    </svg>,

    Schedule: (fillColor) => <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill={fillColor}>
        <g id="schedule_icon" transform="translate(9 8)">
            <rect id="Rectangle_3973" data-name="Rectangle 3973" width="46" height="46" transform="translate(-9 -8)" opacity="0" />
            <path id="schedule_icon-2" data-name="schedule_icon" d="M26.333,5.111H24.778V3.556a1.556,1.556,0,0,0-3.111,0V5.111H12.333V3.556a1.556,1.556,0,1,0-3.111,0V5.111H7.667A4.667,4.667,0,0,0,3,9.778V26.889a4.667,4.667,0,0,0,4.667,4.667H26.333A4.667,4.667,0,0,0,31,26.889V9.778a4.667,4.667,0,0,0-4.667-4.667ZM10.778,26.889a1.556,1.556,0,1,1,1.556-1.556A1.556,1.556,0,0,1,10.778,26.889Zm0-6.222a1.556,1.556,0,1,1,1.556-1.556A1.556,1.556,0,0,1,10.778,20.667ZM17,26.889a1.556,1.556,0,1,1,1.556-1.556A1.556,1.556,0,0,1,17,26.889Zm0-6.222a1.556,1.556,0,1,1,1.556-1.556A1.556,1.556,0,0,1,17,20.667Zm6.222,0a1.556,1.556,0,1,1,1.556-1.556A1.556,1.556,0,0,1,23.222,20.667Zm4.667-6.222H6.111V9.778A1.556,1.556,0,0,1,7.667,8.222H9.222V9.778a1.556,1.556,0,1,0,3.111,0V8.222h9.333V9.778a1.556,1.556,0,0,0,3.111,0V8.222h1.556a1.556,1.556,0,0,1,1.556,1.556Z" transform="translate(-3 -2)" />
        </g>
    </svg>,

    Stage: (fillColor) => <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill={fillColor}>
        <g id="stage_icon" transform="translate(8 10)">
            <rect id="Rectangle_3974" data-name="Rectangle 3974" width="46" height="46" transform="translate(-8 -10)" opacity="0" />
            <path id="Path_62" data-name="Path 62" d="M242.759,205.972a.874.874,0,0,1-.745.609c-.552-1.618-1.119-3.241-1.657-4.874-.234-.709-.4-1.439-.618-2.154-.034-.115-.151-.205-.229-.307-.032.129-.117.271-.087.384q.863,3.31,1.75,6.614c.069.257.152.51.239.8a3.737,3.737,0,0,1-.85.405.774.774,0,0,1-.627-.208,49.45,49.45,0,0,1-5.858-7.3c-.06-.1-.209-.156-.317-.233a1.077,1.077,0,0,0,.045.413,37.3,37.3,0,0,0,5.47,7.438c.055.056.1.117.182.207a1.562,1.562,0,0,1-1.493.093,23.25,23.25,0,0,1-7.448-6.467,20.566,20.566,0,0,1-3-6.248,1.249,1.249,0,0,0-.275-.521c-.048.124-.106.246-.142.373a19.658,19.658,0,0,1-4.664,8.385,25.205,25.205,0,0,1-5.819,4.486,1.578,1.578,0,0,1-1.478-.094c.542-.607,1.069-1.186,1.585-1.776a38.968,38.968,0,0,0,4.092-5.926.911.911,0,0,0,.042-.346c-.11.063-.267.1-.322.192a51.042,51.042,0,0,1-5.847,7.313.606.606,0,0,1-.851.16c-.2-.113-.4-.207-.653-.334.161-.548.335-1.1.485-1.665.5-1.856.988-3.712,1.463-5.573a1.675,1.675,0,0,0-.062-.569,3.074,3.074,0,0,0-.255.474q-1.128,3.376-2.244,6.757c-.012.035-.048.062-.071.091-.472-.136-.638-.321-.638-.78q0-5.674,0-11.349c0-.4.01-.8,0-1.2,0-.3.127-.451.434-.433.067,0,.134,0,.2,0q14.8,0,29.6,0c.26,0,.505,0,.657.257Z" transform="translate(-211.862 -192.8)" />
            <path id="Path_63" data-name="Path 63" d="M553.025,370.6a.631.631,0,0,1-.563.43c-.885.093-1.77.194-2.655.292a.53.53,0,0,1-.6-.415c-.081-.3.171-.446.415-.544q1.7-.687,3.405-1.367Z" transform="translate(-522.128 -354.865)" />
            <path id="Path_64" data-name="Path 64" d="M214.4,398.966c.953-.273,1.326-.1,1.666.745a15.946,15.946,0,0,1,1.227,7.51c-.068.725-.077.754-.811.75-1.255-.006-2.51-.026-3.765-.021-.374,0-.547-.146-.547-.515q0-4.368,0-8.735a1.663,1.663,0,0,1,.041-.224,8.2,8.2,0,0,1,1.226.348.791.791,0,0,1,.318.548c.2,1.291.36,2.588.546,3.881a1.529,1.529,0,0,0,.178.39,1.154,1.154,0,0,0,.155-.39A18.412,18.412,0,0,0,214.4,398.966Z" transform="translate(-212.141 -381.976)" />
            <path id="Path_65" data-name="Path 65" d="M534.549,399.055c-.036,1.477-.074,2.9-.1,4.326,0,.128.1.257.158.386a1.49,1.49,0,0,0,.182-.393q.268-1.922.51-3.847a.759.759,0,0,1,.682-.72c.287-.053.571-.12.92-.194v.469q0,4.149,0,8.3c0,.646-.045.688-.675.692-1.282.007-2.564.01-3.846.031-.365.006-.518-.122-.56-.5a15.9,15.9,0,0,1,1.318-8.047C533.382,398.984,533.821,398.853,534.549,399.055Z" transform="translate(-506.074 -382.102)" />
            <path id="Path_66" data-name="Path 66" d="M211.968,369.193c1.2.483,2.345.927,3.47,1.41.148.064.336.378.29.477-.083.18-.336.414-.5.4-.956-.063-1.908-.186-2.86-.3a.429.429,0,0,1-.4-.472C211.968,370.229,211.968,369.75,211.968,369.193Z" transform="translate(-211.96 -355.042)" />
        </g>
    </svg>,

    Expo: (fillColor) => <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill={fillColor}>
        <g id="expo__icon" transform="translate(-78.566 -64.626)">
            <rect id="Rectangle_3976" data-name="Rectangle 3976" width="46" height="46" transform="translate(78.566 64.626)" opacity="0" />
            <path id="Path_9328" data-name="Path 9328" d="M186.2,74.479H158.686A1.32,1.32,0,0,0,157.36,75.8v5a6.143,6.143,0,0,1,2.026-.294,3.382,3.382,0,0,1,.626.037V77.164h24.826V96.881H168.152l.295,2.354v.294H186.2a1.343,1.343,0,0,0,1.326-1.324V75.8a1.32,1.32,0,0,0-1.326-1.324Z" transform="translate(-68.649)" />
            <path id="Path_9326" data-name="Path 9326" d="M293.037,255.661a1.025,1.025,0,0,0-.184-1.067l-4.383-4.561a1.073,1.073,0,0,0-1.436,0l-1.363,1.435-2.689-2.832a1.073,1.073,0,0,0-1.436,0l-4.346,4.672a7.446,7.446,0,0,1,2.321,2.98h12.634a.993.993,0,0,0,.884-.625Z" transform="translate(-180.605 -162.458)" />
            <path id="Path_9327" data-name="Path 9327" d="M406.988,165.131a2.173,2.173,0,1,1-2.173-2.17,2.172,2.172,0,0,1,2.173,2.17" transform="translate(-297.798 -82.67)" />
            <path id="Path_9329" data-name="Path 9329" d="M94.935,347.76a6.727,6.727,0,0,1-4.2,1.508,6.517,6.517,0,0,1-4.2-1.508,4.85,4.85,0,0,0-2.21,3.458l-.442,3.568a1.342,1.342,0,0,0,1.031,1.471,30.973,30.973,0,0,0,11.639,0,1.311,1.311,0,0,0,1.031-1.471l-.442-3.568a4.779,4.779,0,0,0-2.21-3.458Z" transform="translate(0 -255.33)" />
            <path id="Path_9330" data-name="Path 9330" d="M128.827,204.411a4.494,4.494,0,1,1-4.494-4.487,4.491,4.491,0,0,1,4.494,4.487" transform="translate(-33.597 -117.205)" />
        </g>
    </svg>,

    Networking: (fillColor) => <svg id="networking_icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill={fillColor} >
        <path id="Path_45" data-name="Path 45" d="M17.389,14.673a2.716,2.716,0,0,0-1.34.371l-4.092-3.5V7.268a2.716,2.716,0,1,0-1.811,0v4.273l-4.092,3.5a2.724,2.724,0,1,0,1.186,1.367l3.811-3.259,3.811,3.259a2.711,2.711,0,1,0,2.526-1.738Z" transform="translate(5.655 4.605)" />
    </svg>,

    Leaderboard: (fillColor) => <svg id="leaderboard_icon" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill={fillColor} >
        <path id="Path_930" data-name="Path 930" d="M27.889,6.111H24.778V3H9.222V6.111H6.111A3.12,3.12,0,0,0,3,9.222v1.556a7.765,7.765,0,0,0,6.829,7.684,7.792,7.792,0,0,0,5.616,4.6v4.822H9.222V31H24.778V27.889H18.556V23.067a7.792,7.792,0,0,0,5.616-4.6A7.765,7.765,0,0,0,31,10.778V9.222A3.12,3.12,0,0,0,27.889,6.111ZM6.111,10.778V9.222H9.222v5.942A4.683,4.683,0,0,1,6.111,10.778Zm21.778,0a4.683,4.683,0,0,1-3.111,4.387V9.222h3.111Z" transform="translate(-3 -3)" />
    </svg>,

    ProfileSection: (fillColor) => <svg id="profile_icon" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill={fillColor} >
        <path id="Path_932" data-name="Path 932" d="M16,2A14,14,0,1,0,30,16,14.005,14.005,0,0,0,16,2Zm0,4.2a4.2,4.2,0,1,1-4.2,4.2A4.194,4.194,0,0,1,16,6.2Zm0,19.88a10.081,10.081,0,0,1-8.4-4.508c.042-2.786,5.6-4.312,8.4-4.312s8.358,1.526,8.4,4.312A10.081,10.081,0,0,1,16,26.08Z" transform="translate(-2 -2)" />
    </svg>,

    LobbyPeople: () => <svg id="people_icon" xmlns="http://www.w3.org/2000/svg" width="30.026" height="26.583" viewBox="0 0 30.026 26.583">
        <circle id="Ellipse_57" data-name="Ellipse 57" cx="5.958" cy="5.958" r="5.958" transform="translate(6.417)" fill="#333" />
        <path id="Path_936" data-name="Path 936" d="M14.536,14.013C10.437,13.862,2,15.9,2,20v3H16.323A8.932,8.932,0,0,1,14.536,14.013Z" transform="translate(-2 0.575)" fill="#333" />
        <path id="Path_937" data-name="Path 937" d="M23.155,21.038a5.8,5.8,0,0,0,.856-3.033,6.005,6.005,0,1,0-6.005,6.005,5.943,5.943,0,0,0,3.033-.856L24.9,27.013,27.013,24.9Zm-5.15-.03a3,3,0,1,1,3-3A3.011,3.011,0,0,1,18.005,21.008Z" transform="translate(3.013 -0.43)" fill="#333" />
    </svg>,

    LobbyChat: () => <svg xmlns="http://www.w3.org/2000/svg" width="25.667" height="25.667" viewBox="0 0 25.667 25.667">
        <path id="chat_icon" d="M25.1,2H4.567A2.563,2.563,0,0,0,2.013,4.567L2,27.667l5.133-5.133H25.1a2.574,2.574,0,0,0,2.567-2.567V4.567A2.574,2.574,0,0,0,25.1,2ZM7.133,10.983h15.4V13.55H7.133ZM17.4,17.4H7.133V14.833H17.4Zm5.133-7.7H7.133V7.133h15.4Z" transform="translate(-2 -2)" fill="#333" />
    </svg>,

    EventIcon: (fillColor) => <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill={fillColor}>
        <g id="event_icon" transform="translate(-69.812 -30.293)">
            <g id="Rectangle_3980" data-name="Rectangle 3980" transform="translate(69.812 30.293)" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
                <rect width="46" height="46" stroke="none" />
                <rect x="0.5" y="0.5" width="45" height="45" fill="none" />
            </g>
            <g id="Group_780" data-name="Group 780" transform="translate(-0.029 0.531)">
                <path id="Path_1481" data-name="Path 1481" d="M77.84,205.52v14.763a2.307,2.307,0,0,0,2.309,2.309h24.908a2.307,2.307,0,0,0,2.309-2.309V205.52Zm19.42,6.992-2.348,2.03,1.616,3.15a.305.305,0,0,1-.152.334.44.44,0,0,1-.213.061.228.228,0,0,1-.152-.03l-3.469-1.783-3.571,1.783a.318.318,0,0,1-.364-.03.387.387,0,0,1-.152-.334l1.718-3.15L87.7,212.355a.4.4,0,0,1-.091-.364.371.371,0,0,1,.273-.243h3.168l1.154-3.339a.37.37,0,0,1,.638,0L94,211.747l3.047.158a.37.37,0,0,1,.273.243.323.323,0,0,1-.061.364Z" transform="translate(0 -156.767)" />
                <path id="Path_1482" data-name="Path 1482" d="M105.058,39.762H80.15a2.307,2.307,0,0,0-2.309,2.309v5.1h29.526v-5.1a2.307,2.307,0,0,0-2.309-2.309Zm-20.5,5.1a1.428,1.428,0,1,1,1.428-1.428A1.426,1.426,0,0,1,84.554,44.865Zm16.1,0a1.428,1.428,0,1,1,1.428-1.428A1.407,1.407,0,0,1,100.653,44.865Z" transform="translate(-0.001)" />
            </g>
        </g>
    </svg>,

    Chat: <svg id="chat" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
        <path id="chat_icon" d="M25.1,2H4.567A2.563,2.563,0,0,0,2.013,4.567L2,27.667l5.133-5.133H25.1a2.574,2.574,0,0,0,2.567-2.567V4.567A2.574,2.574,0,0,0,25.1,2ZM7.133,10.983h15.4V13.55H7.133ZM17.4,17.4H7.133V14.833H17.4Zm5.133-7.7H7.133V7.133h15.4Z" transform="translate(0 0)" fill="#333" />
    </svg>,

    StagePoll: <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29">
        <path id="Path_951" d="M23.644,3H5.581A2.588,2.588,0,0,0,3,5.581V23.644a2.588,2.588,0,0,0,2.581,2.581H23.644a2.588,2.588,0,0,0,2.581-2.581V5.581A2.588,2.588,0,0,0,23.644,3Zm-12.9,18.064H8.161V12.032h2.581Zm5.161,0H13.322V8.161H15.9Zm5.161,0H18.483V15.9h2.581Z" transform="translate(0 0)" fill="#333" />
    </svg>,

    StageQA: <svg id="QnA_icon" xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29">
        <path id="Path_953" d="M26.067,7.067H23.533v11.4H7.067V21a1.27,1.27,0,0,0,1.267,1.267H22.267l5.067,5.067v-19A1.27,1.27,0,0,0,26.067,7.067ZM21,14.667V3.267A1.27,1.27,0,0,0,19.733,2H3.267A1.27,1.27,0,0,0,2,3.267V21l5.067-5.067H19.733A1.27,1.27,0,0,0,21,14.667Z" transform="translate(0 0)" fill="#333" />
    </svg>,

    StageJoin: (fillColor) => <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill={fillColor}>
        <g id="join_icon" transform="translate(0 4)">
            <path id="join_icon-2" data-name="join_icon" d="M32.528,15.521a2.143,2.143,0,0,0-2.144-2.144,2.168,2.168,0,0,0-1.834,1.016v-.056a2.169,2.169,0,0,0-4.006-1.157v-.056a2.169,2.169,0,0,0-4.006-1.157V4.744A2.12,2.12,0,0,0,18.394,2.6h0A2.149,2.149,0,0,0,16.25,4.772V18.906a.294.294,0,0,1-.2.282.308.308,0,0,1-.339-.056L13.541,16.9a2.181,2.181,0,0,0-3.7,1.241,2.222,2.222,0,0,0,.48,1.721l5.445,6.573A8.079,8.079,0,0,0,22,29.372H25.39A7.128,7.128,0,0,0,32.5,22.263Z" transform="translate(-4.824 -4.6)" />
        </g>
    </svg>,

    Stagepeople: <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
        <g id="people_icon" transform="translate(6.25 7)">
            <g id="Rectangle_4004" data-name="Rectangle 4004" transform="translate(-6.25 -7)" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
                <rect width="38" height="38" stroke="none" />
                <rect x="0.5" y="0.5" width="37" height="37" fill="none" />
            </g>
            <g id="Group_815" data-name="Group 815" transform="translate(-2.25 -1)">
                <circle id="Ellipse_57" data-name="Ellipse 57" cx="5.958" cy="5.958" r="5.958" transform="translate(6.416)" />
                <path id="Path_936" data-name="Path 936" d="M14.536,14.013C10.437,13.862,2,15.9,2,20v3H16.323A8.932,8.932,0,0,1,14.536,14.013Z" transform="translate(-2 0.575)" />
                <path id="Path_937" data-name="Path 937" d="M23.155,21.038a5.8,5.8,0,0,0,.856-3.033,6.005,6.005,0,1,0-6.005,6.005,5.943,5.943,0,0,0,3.033-.856L24.9,27.013,27.013,24.9Zm-5.15-.03a3,3,0,1,1,3-3A3.011,3.011,0,0,1,18.005,21.008Z" transform="translate(3.014 -0.43)" />
            </g>
        </g>
    </svg>,

    LiveIcon: <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
        <g id="recording_icon" transform="translate(-0.046 -0.484)">
            <g id="Rectangle_3891" data-name="Rectangle 3891" transform="translate(0.046 0.484)" fill="none" stroke="#e31317" strokeWidth="1">
                <rect width="14" height="14" rx="7" stroke="none" />
                <rect x="0.5" y="0.5" width="13" height="13" rx="6.5" fill="none" />
            </g>
            <rect id="Rectangle_3892" data-name="Rectangle 3892" width="8" height="8" rx="4" transform="translate(3.046 3.484)" fill="#e31317" />
        </g>
    </svg>,

    People: <svg id="people_icon" xmlns="http://www.w3.org/2000/svg" width="30.026" height="26.583" viewBox="0 0 30.026 26.583">
        <circle id="Ellipse_57" data-name="Ellipse 57" cx="5.958" cy="5.958" r="5.958" transform="translate(6.417)" fill="#333" />
        <path id="Path_936" data-name="Path 936" d="M14.536,14.013C10.437,13.862,2,15.9,2,20v3H16.323A8.932,8.932,0,0,1,14.536,14.013Z" transform="translate(-2 0.575)" fill="#333" />
        <path id="Path_937" data-name="Path 937" d="M23.155,21.038a5.8,5.8,0,0,0,.856-3.033,6.005,6.005,0,1,0-6.005,6.005,5.943,5.943,0,0,0,3.033-.856L24.9,27.013,27.013,24.9Zm-5.15-.03a3,3,0,1,1,3-3A3.011,3.011,0,0,1,18.005,21.008Z" transform="translate(3.013 -0.43)" fill="#333" />
    </svg>,

    InviteIcon: (fillColor) => <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill={fillColor}>
        <g id="invite_icon" transform="translate(24 9.667)">
            <rect id="Rectangle_3988" data-name="Rectangle 3988" width="46" height="46" transform="translate(-24 -9.667)" fill={fillColor} opacity="0" />
            <g id="icon" transform="translate(-133.672 -139.667)">
                <path id="Path_9542" data-name="Path 9542" d="M129.22,140a6.17,6.17,0,0,0-4.437,1.9,6.651,6.651,0,0,0,0,9.181,6.127,6.127,0,0,0,8.873,0,6.651,6.651,0,0,0,0-9.181A6.17,6.17,0,0,0,129.22,140Zm7.17,16.692H122.05a5.288,5.288,0,0,0-3.8,1.63,5.663,5.663,0,0,0-1.575,3.934v3.71h25.1v-3.71a5.663,5.663,0,0,0-1.575-3.934A5.288,5.288,0,0,0,136.391,156.692Z" fill={fillColor} />
                <path id="Path_9543" data-name="Path 9543" d="M405.1,191.348h-3.746v3.746h-.936v-3.746H396.67v-.936h3.746v-3.746h.936v3.746H405.1Z" transform="translate(-255.903 -39.728)" fill={fillColor} stroke={fillColor} strokeWidth="1" />
            </g>
        </g>
    </svg>,

    BackStagePushToStage: <svg xmlns="http://www.w3.org/2000/svg" width="22.703" height="26.772" viewBox="0 0 22.703 26.772">
        <path id="join_icon" d="M32.528,15.521a2.143,2.143,0,0,0-2.144-2.144,2.168,2.168,0,0,0-1.834,1.016v-.056a2.169,2.169,0,0,0-4.006-1.157v-.056a2.169,2.169,0,0,0-4.006-1.157V4.744A2.12,2.12,0,0,0,18.394,2.6h0A2.149,2.149,0,0,0,16.25,4.772V18.906a.294.294,0,0,1-.2.282.308.308,0,0,1-.339-.056L13.541,16.9a2.181,2.181,0,0,0-3.7,1.241,2.222,2.222,0,0,0,.48,1.721l5.445,6.573A8.079,8.079,0,0,0,22,29.372H25.39A7.128,7.128,0,0,0,32.5,22.263Z" transform="translate(-9.825 -2.6)" fill="#333" />
    </svg>,

    BackStageLeaveUser: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path className="b" d="M10.09,15.59,11.5,17l5-5-5-5L10.09,8.41,12.67,11H3v2h9.67ZM19,3H5A2,2,0,0,0,3,5V9H5V5H19V19H5V15H3v4a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3Z" /></svg>,

    BackStageLocalUnmuteMic: <svg id="mic_on" xmlns="http://www.w3.org/2000/svg" width="33.81" height="33.81" viewBox="0 0 33.81 33.81">
        <path id="Path_1019" data-name="Path 1019" d="M0,0H33.81V33.81H0Z" fill="none" />
        <path id="Path_1020" data-name="Path 1020" d="M15.621,19.385a4.447,4.447,0,0,0,4.552-4.346V6.346A4.447,4.447,0,0,0,15.621,2a4.447,4.447,0,0,0-4.552,4.346v8.693A4.447,4.447,0,0,0,15.621,19.385ZM14.1,6.346a1.519,1.519,0,0,1,3.035,0v8.693a1.519,1.519,0,0,1-3.035,0Zm9.1,8.693a7.424,7.424,0,0,1-7.587,7.244,7.424,7.424,0,0,1-7.587-7.244H5a10.3,10.3,0,0,0,9.1,10.025v4.462h3.035V25.064a10.3,10.3,0,0,0,9.1-10.025Z" transform="translate(1.284 0.513)" fill="" />
    </svg>,

    BackStageLocalMuteMic: <svg xmlns="http://www.w3.org/2000/svg" width="33.813" height="33.813" viewBox="0 0 33.813 33.813">
        <g id="mic_off" transform="translate(0.001)">
            <path id="Path_1017" data-name="Path 1017" d="M0,0H33.813V33.813H0Z" transform="translate(-0.001)" fill="none" />
            <path id="Path_1018" data-name="Path 1018" d="M13.989,6.086a1.691,1.691,0,1,1,3.381,0l-.014,5.509,2.55,2.522V6.227a4.212,4.212,0,0,0-8.4-.493l2.48,2.48ZM25.542,14.68h-2.4a7.288,7.288,0,0,1-.38,2.311l1.789,1.789A9.164,9.164,0,0,0,25.542,14.68ZM4.986,3.212,3,5.2l8.453,8.453V14.68a4.221,4.221,0,0,0,4.227,4.227,3.881,3.881,0,0,0,.916-.113l2.339,2.339a7.746,7.746,0,0,1-3.254.733A7.31,7.31,0,0,1,8.213,14.68h-2.4a9.839,9.839,0,0,0,8.453,9.468v4.621h2.818V24.147a10.044,10.044,0,0,0,3.593-1.268L26.6,28.8l1.986-1.986Z" transform="translate(1.226 0.818)" fill="" />
        </g>
    </svg>,

    BackStageLocalVideoOn: <svg id="video_on" xmlns="http://www.w3.org/2000/svg" width="36.15" height="36.15" viewBox="0 0 36.15 36.15">
        <path id="Path_1013" data-name="Path 1013" d="M0,0H36.15V36.15H0Z" fill="none" />
        <path id="Path_1014" data-name="Path 1014" d="M21.075,9.012v12.05H6.012V9.012H21.075M22.581,6H4.506A1.511,1.511,0,0,0,3,7.506V22.569a1.511,1.511,0,0,0,1.506,1.506H22.581a1.511,1.511,0,0,0,1.506-1.506V17.3l6.025,6.025V6.753l-6.025,6.025V7.506A1.511,1.511,0,0,0,22.581,6Z" transform="translate(1.519 3.037)" fill="" />
    </svg>,

    BackStageBackround: <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
        <g id="effect_icon" transform="translate(-142.629 -72.628)">
            <path id="Path_9629" data-name="Path 9629" d="M161.145,93.628H145.113a2.486,2.486,0,0,1-2.484-2.484V75.112a2.486,2.486,0,0,1,2.484-2.484h11.61v1.938h-11.61a.546.546,0,0,0-.545.545V91.144a.546.546,0,0,0,.545.545h16.033a.546.546,0,0,0,.545-.545V79.535h1.938V91.144A2.486,2.486,0,0,1,161.145,93.628Z" />
            <path id="Path_9630" data-name="Path 9630" d="M252.646,177.161a.415.415,0,0,1-.3.4l-1.024.3a5.058,5.058,0,0,0-3.465,3.464l-.3,1.024a.415.415,0,0,1-.8,0l-.3-1.024A5.057,5.057,0,0,0,243,177.857l-1.024-.3a.415.415,0,0,1,0-.8l1.024-.3A5.057,5.057,0,0,0,246.464,173l.3-1.024a.415.415,0,0,1,.8,0l.3,1.024a5.059,5.059,0,0,0,3.465,3.465l1.024.3A.415.415,0,0,1,252.646,177.161Z" transform="translate(-94.032 -94.033)" />
            <path id="Path_9631" data-name="Path 9631" d="M457.583,85.936a.415.415,0,0,1-.3.4l-.505.147a1.489,1.489,0,0,0-1.019,1.019l-.147.505a.415.415,0,0,1-.8,0l-.147-.505a1.489,1.489,0,0,0-1.019-1.019l-.505-.147a.415.415,0,0,1,0-.8l.505-.147a1.489,1.489,0,0,0,1.019-1.019l.147-.505a.415.415,0,0,1,.8,0l.147.505a1.489,1.489,0,0,0,1.019,1.019l.505.147A.416.416,0,0,1,457.583,85.936Z" transform="translate(-294.508 -10.385)" />
        </g>
    </svg>,

    BackStageLocalVideoOff: <svg id="video_off" xmlns="http://www.w3.org/2000/svg" width="36.154" height="36.154" viewBox="0 0 36.154 36.154">
        <path id="Path_1015" data-name="Path 1015" d="M0,0H36.154V36.154H0Z" fill="none" />
        <path id="Path_1016" data-name="Path 1016" d="M13.388,11.109,10.376,8.1,4.124,1.86,2,3.984,6.112,8.1h-1.1A1.511,1.511,0,0,0,3.506,9.6V24.667a1.511,1.511,0,0,0,1.506,1.506H23.09a1.551,1.551,0,0,0,.829-.271l4.79,4.79,2.124-2.124L17.486,15.222ZM6.519,23.161V11.109H9.125L21.177,23.161ZM21.583,11.109v3.932l9.038,9.038V8.85L24.6,14.875V9.6A1.511,1.511,0,0,0,23.09,8.1H14.639l3.013,3.013Z" transform="translate(1.013 0.942)" fill="" />
    </svg>,

    BackStageLocalScreenShare: <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37">
        <path className="b" d="M30.833,29.417A3.079,3.079,0,0,0,33.9,26.333l.015-15.417a3.082,3.082,0,0,0-3.083-3.083H6.167a3.073,3.073,0,0,0-3.083,3.083V26.333a3.082,3.082,0,0,0,3.083,3.083H0V32.5H37V29.417ZM6.167,26.333V10.917H30.833V26.349ZM20.042,15.742c-6,.833-8.387,4.933-9.25,9.05,2.143-2.883,4.964-4.193,9.25-4.193v3.376l6.167-5.766-6.167-5.75Z" transform="translate(0 -1.667)" fill="" />
    </svg>,

    BackStageLocalScreenShareStop: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#5d5d5d">
        <path fill="none" d="M0 0h24v24H0z" /><path d="M21.79 18l2 2H24v-2h-2.21zM1.11 2.98l1.55 1.56c-.41.37-.66.89-.66 1.48V16c0 1.1.9 2 2.01 2H0v2h18.13l2.71 2.71 1.41-1.41L2.52 1.57 1.11 2.98zM4 6.02h.13l4.95 4.93C7.94 12.07 7.31 13.52 7 15c.96-1.29 2.13-2.08 3.67-2.46l3.46 3.48H4v-10zm16 0v10.19l1.3 1.3c.42-.37.7-.89.7-1.49v-10c0-1.11-.9-2-2-2H7.8l2 2H20zm-7.07 3.13l2.79 2.78 1.28-1.2L13 7v2.13l-.07.02z" />
    </svg>,

    CameraPreviewThumbnail: <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 477.867 477.867">
        <path d="M469.777,122.01c-5.031-3.111-11.315-3.395-16.606-0.751l-111.838,55.927v-40.653c0-28.277-22.923-51.2-51.2-51.2H51.2
        c-28.277,0-51.2,22.923-51.2,51.2v204.8c0,28.277,22.923,51.2,51.2,51.2h238.933c28.277,0,51.2-22.923,51.2-51.2v-40.653
        l111.838,56.013c8.432,4.213,18.682,0.794,22.896-7.638c1.198-2.397,1.815-5.043,1.8-7.722v-204.8
        C477.87,130.617,474.809,125.122,469.777,122.01z M170.667,307.2c-37.703,0-68.267-30.564-68.267-68.267
        s30.564-68.267,68.267-68.267s68.267,30.564,68.267,68.267S208.369,307.2,170.667,307.2z"/>
    </svg>,

    CameraPreviewThumbnailOff: <svg id="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path d="M20.31,6H4A2,2,0,0,0,2,8V24a2.85,2.85,0,0,0,0,.29Z"></path>
        <path d="M29.46,8.11a1,1,0,0,0-1,.08L23,12.06V10.44l7-7L28.56,2,2,28.56,3.44,30l4-4H21a2,2,0,0,0,2-2V19.94l5.42,3.87A1,1,0,0,0,30,23V9A1,1,0,0,0,29.46,8.11Z"></path>
    </svg>,

    MutedMicRedBg: <svg xmlns="http://www.w3.org/2000/svg" width="33.813" height="33.813" viewBox="0 0 33.813 33.813">
        <g id="mic_off" transform="translate(0.001)">
            <path id="Path_1017" data-name="Path 1017" d="M0,0H33.813V33.813H0Z" transform="translate(-0.001)" fill="none"></path>
            <path id="Path_1018" data-name="Path 1018" d="M13.989,6.086a1.691,1.691,0,1,1,3.381,0l-.014,5.509,2.55,2.522V6.227a4.212,4.212,0,0,0-8.4-.493l2.48,2.48ZM25.542,14.68h-2.4a7.288,7.288,0,0,1-.38,2.311l1.789,1.789A9.164,9.164,0,0,0,25.542,14.68ZM4.986,3.212,3,5.2l8.453,8.453V14.68a4.221,4.221,0,0,0,4.227,4.227,3.881,3.881,0,0,0,.916-.113l2.339,2.339a7.746,7.746,0,0,1-3.254.733A7.31,7.31,0,0,1,8.213,14.68h-2.4a9.839,9.839,0,0,0,8.453,9.468v4.621h2.818V24.147a10.044,10.044,0,0,0,3.593-1.268L26.6,28.8l1.986-1.986Z" transform="translate(1.226 0.818)"></path>
        </g>
    </svg>,

    MutedCameraRedBg: <svg id="video_off" xmlns="http://www.w3.org/2000/svg" width="36.154" height="36.154" viewBox="0 0 36.154 36.154">
        <path id="Path_1015" data-name="Path 1015" d="M0,0H36.154V36.154H0Z" fill="none"></path>
        <path id="Path_1016" data-name="Path 1016" d="M13.388,11.109,10.376,8.1,4.124,1.86,2,3.984,6.112,8.1h-1.1A1.511,1.511,0,0,0,3.506,9.6V24.667a1.511,1.511,0,0,0,1.506,1.506H23.09a1.551,1.551,0,0,0,.829-.271l4.79,4.79,2.124-2.124L17.486,15.222ZM6.519,23.161V11.109H9.125L21.177,23.161ZM21.583,11.109v3.932l9.038,9.038V8.85L24.6,14.875V9.6A1.511,1.511,0,0,0,23.09,8.1H14.639l3.013,3.013Z" transform="translate(1.013 0.942)"></path>
    </svg>,

    UserMicOff: <svg xmlns="http://www.w3.org/2000/svg" width="33.813" height="33.813" viewBox="0 0 33.813 33.813"><g id="mic_off" transform="translate(0.001)"><path id="Path_1017" data-name="Path 1017" d="M0,0H33.813V33.813H0Z" transform="translate(-0.001)" fill="none"></path><path id="Path_1018" data-name="Path 1018" d="M13.989,6.086a1.691,1.691,0,1,1,3.381,0l-.014,5.509,2.55,2.522V6.227a4.212,4.212,0,0,0-8.4-.493l2.48,2.48ZM25.542,14.68h-2.4a7.288,7.288,0,0,1-.38,2.311l1.789,1.789A9.164,9.164,0,0,0,25.542,14.68ZM4.986,3.212,3,5.2l8.453,8.453V14.68a4.221,4.221,0,0,0,4.227,4.227,3.881,3.881,0,0,0,.916-.113l2.339,2.339a7.746,7.746,0,0,1-3.254.733A7.31,7.31,0,0,1,8.213,14.68h-2.4a9.839,9.839,0,0,0,8.453,9.468v4.621h2.818V24.147a10.044,10.044,0,0,0,3.593-1.268L26.6,28.8l1.986-1.986Z" transform="translate(1.226 0.818)"></path></g></svg>,

    UserCameraOff: <svg id="video_off" xmlns="http://www.w3.org/2000/svg" width="36.154" height="36.154" viewBox="0 0 36.154 36.154"><path id="Path_1015" data-name="Path 1015" d="M0,0H36.154V36.154H0Z" fill="none"></path><path id="Path_1016" data-name="Path 1016" d="M13.388,11.109,10.376,8.1,4.124,1.86,2,3.984,6.112,8.1h-1.1A1.511,1.511,0,0,0,3.506,9.6V24.667a1.511,1.511,0,0,0,1.506,1.506H23.09a1.551,1.551,0,0,0,.829-.271l4.79,4.79,2.124-2.124L17.486,15.222ZM6.519,23.161V11.109H9.125L21.177,23.161ZM21.583,11.109v3.932l9.038,9.038V8.85L24.6,14.875V9.6A1.511,1.511,0,0,0,23.09,8.1H14.639l3.013,3.013Z" transform="translate(1.013 0.942)"></path></svg>,

    Loader: <svg viewBox="0 0 38 38" stroke="currentcolor" className="icon-30 p-c"><g fill="none" fillRule="evenodd"><g transform="translate(1 1)" strokeWidth="2"><circle strokeOpacity=".5" cx="18" cy="18" r="18"></circle><path d="M36 18c0-9.94-8.06-18-18-18"><animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" repeatCount="indefinite" dur="0.6s"></animateTransform></path></g></g></svg>,
    LoaderOverlay: <svg viewBox="0 0 38 38" stroke="currentcolor" className="icon-30 p-c"><g fill="none" fillRule="evenodd" stroke="#397dc9"><g transform="translate(1 1)" strokeWidth="2"><circle stroke="#f8f8f8" strokeOpacity=".5" cx="18" cy="18" r="18"></circle><path d="M36 18c0-9.94-8.06-18-18-18"><animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" repeatCount="indefinite" dur="0.6s"></animateTransform></path></g></g></svg>,

    Notification: <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1080 1080">
        <path d="M7.68,433.89c2.68-19.85,4.72-39.81,8.14-59.53C34.57,266.25,82.99,173.21,159.05,94.38c16.3-16.9,34.43-18.81,48.5-5.46 c13.83,13.12,12.79,32.27-3.22,48.7c-45.91,47.11-80.75,101.25-103.99,162.8c-19.93,52.78-29.6,107.47-30.16,163.84
    c-0.19,18.69-11.76,31.87-28.86,33.58c-13.91,1.39-24.9-6.57-33.63-24.36C7.68,460.29,7.68,447.09,7.68,433.89z"/>
        <path d="M541.22,866.15c-97.21,0-194.42,0.01-291.64-0.03c-5.2,0-10.47,0.04-15.57-0.8c-20.86-3.43-31.33-25.65-21.33-45.97 c18.53-37.64,37.8-74.91,55.95-112.72c4.02-8.37,6.5-18.31,6.64-27.57c0.77-53.11-1.85-106.38,1.31-159.31
    c6.34-105.93,60.35-181.26,155.11-227.78c7.95-3.9,12.51-8.06,14.81-17.46c10.78-44.02,51.91-74.72,96.63-73.56 c45.47,1.18,83.97,33.08,94.14,77.32c0.96,4.19,4.79,9.21,8.63,11.05c103.65,49.8,158.27,131.56,160.62,246.9
    c1,49.28,0.03,98.6,0.36,147.9c0.04,5.96,1.61,12.42,4.23,17.78c18.44,37.67,36.26,75.71,56.47,112.43 c13.93,25.3,0.63,52.63-32.66,52.24C737.04,865.41,639.12,866.15,541.22,866.15z"/>
        <path d="M1073.9,452.24c0,6.93,1.06,14.06-0.21,20.74c-2.93,15.43-17.86,26.27-32.64,24.87c-16.36-1.56-27.57-14.48-29.1-31.41 c-2.93-32.43-4.27-65.25-10.52-97.07c-17.36-88.39-59.11-164.54-121.29-229.64c-5.39-5.64-10.68-12.24-13.41-19.38
    c-5.05-13.25,1.22-27.99,13.04-35.23c12.8-7.84,27.81-6.27,39.11,4.95c30.19,29.98,56.33,63.23,78.4,99.61 C1046.24,270.34,1071.44,358.04,1073.9,452.24z"/>
        <path d="M141.57,456.67c4.15-105.14,42.06-195.01,114.67-270.17c9.26-9.59,20.1-13.6,32.98-10.15 c11.69,3.13,19.05,11.21,21.84,22.94c3.02,12.75-2.07,22.95-10.86,31.91c-27.74,28.25-49.8,60.41-66.2,96.49
    c-19.08,41.96-28.48,86.07-29.92,132.01c-0.16,5.18-0.11,10.54-1.41,15.48c-3.77,14.23-18.25,24.1-32.27,22.65 c-15.61-1.61-27.37-13.56-28.76-29.5C141.26,464.19,141.57,460,141.57,456.67z"/>
        <path d="M940.75,447.64c0,5.2-0.02,10.41,0,15.61c0.1,19.85-13.02,34.5-31.09,34.7c-17.62,0.19-30.94-13.55-31.33-33.18 c-1.42-71.38-21.47-136.7-63.64-194.62c-10.18-13.98-21.92-26.88-33.46-39.81c-13.63-15.27-14.51-33.77-1.51-46.45
    c13.14-12.82,32.53-11.39,47.05,3.47C895.57,257.72,933.11,343.88,940.75,447.64z"/>
        <path d="M405.47,929.37c91.33,0,180.18,0,271.19,0c-33.54,44.51-76.18,68.73-129.95,70.39 C488.6,1001.56,442.3,977.3,405.47,929.37z" />
    </svg>,

    /*ChatIcon: <svg xmlns="http://www.w3.org/2000/svg" width="27.022" height="27.022" viewBox="0 0 27.022 27.022">
        <path id="chat_icon" d="M26.32,2H4.7A2.7,2.7,0,0,0,2.014,4.7L2,29.022l5.4-5.4H26.32a2.71,2.71,0,0,0,2.7-2.7V4.7A2.71,2.71,0,0,0,26.32,2ZM7.4,11.458H23.617v2.7H7.4Zm10.809,6.755H7.4v-2.7H18.213Zm5.4-8.107H7.4V7.4H23.617Z" transform="translate(-2 -2)" fill="#fff" />
    </svg>,*/

    ChatIcon: (fillColor) => <svg id="chat_icon" xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill={fillColor}>
        <g id="Rectangle_3966" data-name="Rectangle 3966" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
            <rect width="46" height="46" stroke="none" />
            <rect x="0.5" y="0.5" width="45" height="45" fill="none" />
        </g>
        <g id="chat_icon-2" data-name="chat_icon" transform="translate(7.292 7.03)">
            <path id="Path_9354" data-name="Path 9354" d="M127.025,52.873a15.961,15.961,0,1,0-4.252,4.212,14.431,14.431,0,0,1,2.807,1.132c1.255.6,2.37,1.21,2.382,1.216h0a1.1,1.1,0,0,0,1.486-1.512,30.993,30.993,0,0,1-2.422-5.049Zm-4.168-5.733-2.1,1.273h0a1.115,1.115,0,0,1-1.155,0l-1.524-.924-1.524.924h0a1.114,1.114,0,0,1-1.155,0l-1.524-.924-1.524.924a1.116,1.116,0,0,1-1.155,0l-1.524-.924-1.524.924h0a1.115,1.115,0,0,1-1.156,0l-2.1-1.274h0a1.114,1.114,0,1,1,1.155-1.906l1.524.924,1.524-.924h0a1.116,1.116,0,0,1,1.155,0l1.524.924,1.524-.924h0a1.115,1.115,0,0,1,1.155,0l1.524.924,1.524-.924h0a1.116,1.116,0,0,1,1.155,0l1.524.924,1.524-.924h0a1.115,1.115,0,1,1,1.155,1.906Zm0-5.946-2.1,1.274a1.114,1.114,0,0,1-1.155,0l-1.524-.924-1.524.924h0a1.116,1.116,0,0,1-1.155,0l-1.524-.925-1.524.925a1.115,1.115,0,0,1-1.155,0l-1.524-.924-1.524.924a1.114,1.114,0,0,1-1.156,0l-2.1-1.276a1.115,1.115,0,1,1,1.155-1.906l1.524.924,1.524-.924h0a1.115,1.115,0,0,1,1.155,0l1.524.924,1.524-.924h0a1.114,1.114,0,0,1,1.155,0l1.524.924,1.524-.924h0a1.115,1.115,0,0,1,1.155,0l1.524.924,1.524-.924h0a1.114,1.114,0,0,1,1.154,1.906Z" transform="translate(-97.92 -27.896)" />
        </g>
    </svg>,


    /*FeedBackIcon: <svg xmlns="http://www.w3.org/2000/svg" width="32" height="30.383" viewBox="0 0 32 30.383">
        <path id="feedback_icon" d="M130.143,47.363H111.857A6.862,6.862,0,0,0,105,54.22V76.608a1.143,1.143,0,0,0,1.954.8l4.709-4.708a4.578,4.578,0,0,1,3.234-1.337h15.246A6.862,6.862,0,0,0,137,64.505V54.219a6.862,6.862,0,0,0-6.857-6.857ZM127.56,58.448l-2.286,2.217h0a.9.9,0,0,0-.251.8l.537,3.131h0a.912.912,0,0,1-1.326.96l-2.811-1.474a.921.921,0,0,0-.846,0l-2.811,1.474a.913.913,0,0,1-1.326-.96l.537-3.131a.894.894,0,0,0-.252-.8l-2.286-2.217h0a.913.913,0,0,1,.5-1.554l3.154-.457a.916.916,0,0,0,.686-.5l1.406-2.846h0a.9.9,0,0,1,1.623,0l1.406,2.846a.917.917,0,0,0,.686.5l3.154.457a.912.912,0,0,1,.5,1.554Z" transform="translate(-105 -47.362)" fill="#ed374f" />
    </svg>,*/

    FeedBackIcon: (fillColor) => <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill={fillColor}>
        <g id="feedback_icon" transform="translate(7.009 2.87)">
            <g id="Rectangle_3965" data-name="Rectangle 3965" transform="translate(-7.009 -2.87)" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
                <rect width="46" height="46" stroke="none" />
                <rect x="0.5" y="0.5" width="45" height="45" fill="none" />
            </g>
            <g id="Group_772" data-name="Group 772" transform="translate(-0.009 0.13)">
                <path id="Path_9356" data-name="Path 9356" d="M313.016,246.818a.894.894,0,0,0,.583.229.939.939,0,0,0,.375-.083l5.6-2.573-5.114-4.426-1.739,5.926a.916.916,0,0,0,.292.927Z" transform="translate(-298.57 -226.383)" />
                <path id="Path_9357" data-name="Path 9357" d="M337.01,136.387l7.205-8.078,5.608,4.851-7.205,8.078Z" transform="translate(-320.171 -124.381)" />
                <path id="Path_9358" data-name="Path 9358" d="M444.031,83.412a3.708,3.708,0,0,0-.375-5.228,3.63,3.63,0,0,0-2.687-.9,3.686,3.686,0,0,0-2.541,1.271l-1.468,1.723,5.6,4.853Z" transform="translate(-412.246 -77.278)" />
                <path id="Path_9359" data-name="Path 9359" d="M187.017,249.173h3.437v5.77a.893.893,0,0,0,.562.833.787.787,0,0,0,.343.073.941.941,0,0,0,.646-.26l6.405-6.415h16.873a.894.894,0,0,0,.906-.906v-20a.894.894,0,0,0-.906-.906h-.292l-5.655,6.54a2.619,2.619,0,0,1-.958.708l-6.093,2.791a2.775,2.775,0,0,1-1.135.24,2.675,2.675,0,0,1-1.781-.677,2.515,2.515,0,0,1-.781-1.135H192.1a.906.906,0,1,1,0-1.812h6.479l1.864-6.332a1.82,1.82,0,0,1,.125-.333h-13.55a.9.9,0,0,0-.906.906v20a.909.909,0,0,0,.906.917Zm5.083-8.467h18.1a.906.906,0,1,1,0,1.812H192.1a.906.906,0,1,1,0-1.812Z" transform="translate(-186.111 -215.178)" />
            </g>
        </g>
    </svg>,

    AddChatIcon: <svg xmlns="http://www.w3.org/2000/svg" width="22.6" height="22.6" viewBox="0 0 22.6 22.6">
        <g id="new_chat_icon" transform="translate(-116.67 -46.664)">
            <path id="Path_1057" data-name="Path 1057" d="M449.494,376.412h-1.027V373.33h-2.055v3.082H443.33v2.055h3.082v3.082h2.055v-3.082h3.082v-2.055Z" transform="translate(-312.279 -312.284)" fill="#3a3a3a" />
            <path id="Path_1058" data-name="Path 1058" d="M420,256.67v2.055h1.027a1.027,1.027,0,0,0,1.027-1.027V256.67Z" transform="translate(-289.976 -200.76)" fill="#3a3a3a" />
            <path id="Path_1059" data-name="Path 1059" d="M326.67,256.67v2.055H327.7a1.027,1.027,0,0,0,1.027-1.027V256.67Z" transform="translate(-200.755 -200.76)" fill="#3a3a3a" />
            <path id="Path_1060" data-name="Path 1060" d="M233.33,256.67v2.055h1.027a1.027,1.027,0,0,0,1.027-1.027V256.67Z" transform="translate(-111.524 -200.76)" fill="#3a3a3a" />
            <path id="Path_1061" data-name="Path 1061" d="M126.943,46.664a10.273,10.273,0,1,0,0,20.545H129V65.155h-2.055a8.218,8.218,0,1,1,8.218-8.218v2.055h2.055V56.937a10.272,10.272,0,0,0-10.273-10.273Z" fill="#3a3a3a" />
        </g>
    </svg>,

    PinIcon: <svg xmlns="http://www.w3.org/2000/svg" width="61" height="61" viewBox="0 0 61 61">
        <defs>
            <filter id="Ellipse_2839" x="0" y="0" width="61" height="61" filterUnits="userSpaceOnUse">
                <feOffset dy="3" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="3" result="blur" />
                <feFlood floodOpacity="0.161" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
        </defs>
        <g id="pin" transform="translate(9 6)">
            <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Ellipse_2839)">
                <circle id="Ellipse_2839-2" data-name="Ellipse 2839" cx="21.5" cy="21.5" r="21.5" transform="translate(9 6)" fill="#fff" opacity="0" />
            </g>
            <g id="Group_842" data-name="Group 842">
                <path id="Path_9633" data-name="Path 9633" d="M93.188,399l1.182,1.182-5.431,5.431a.832.832,0,0,1-1.165-1.186Z" transform="translate(-77.408 -372.944)" fill="#1a78e5" />
                <path id="Path_9634" data-name="Path 9634" d="M120.918,243.83l10.043,10.043a1.347,1.347,0,0,1,0,1.773l-.266.266a1.253,1.253,0,0,1-1.773,0l-10.043-10.043a1.347,1.347,0,0,1,0-1.772l.266-.266A1.254,1.254,0,0,1,120.918,243.83Z" transform="translate(-106.944 -224.833)" fill="#1a78e5" />
                <path id="Path_9635" data-name="Path 9635" d="M201.219,104.56l-8.109,7,8.848,8.848,7.006-8.109Z" transform="translate(-177.948 -92.562)" fill="#1a78e5" />
                <path id="Path_9636" data-name="Path 9636" d="M365.878,20.09l8.067,8.067a1.8,1.8,0,0,1,0,2.363l-.449.449a1.671,1.671,0,0,1-2.363,0L363.066,22.9a1.8,1.8,0,0,1,0-2.363l.449-.449a1.671,1.671,0,0,1,2.363,0Z" transform="translate(-339.366 -11.659)" fill="#1a78e5" />
            </g>
        </g>
    </svg>,

    DeleteFillIcon: <svg xmlns="http://www.w3.org/2000/svg" width="61" height="61" viewBox="0 0 61 61">
        <defs>
            <filter id="Ellipse_2840" x="0" y="0" width="61" height="61" filterUnits="userSpaceOnUse">
                <feOffset dy="3" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="3" result="blur" />
                <feFlood floodOpacity="0.161" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
        </defs>
        <g id="delete_icon" transform="translate(9 6)">
            <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Ellipse_2840)">
                <circle id="Ellipse_2840-2" data-name="Ellipse 2840" cx="21.5" cy="21.5" r="21.5" transform="translate(9 6)" fill="#fff" opacity="0" />
            </g>
            <path id="delete_icon-2" data-name="delete_icon" d="M4.5,27a3.009,3.009,0,0,0,3,3h9a3.009,3.009,0,0,0,3-3V12H4.5ZM21,7.5H16.5L15,6H9L7.5,7.5H3v3H21Z" transform="translate(10 4)" fill="#ed374f" />
        </g>
    </svg>,

    UnPinIcon: <svg xmlns="http://www.w3.org/2000/svg" width="61" height="61" viewBox="0 0 61 61">
        <defs>
            <filter id="Ellipse_2838" x="0" y="0" width="61" height="61" filterUnits="userSpaceOnUse">
                <feOffset dy="3" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="3" result="blur" />
                <feFlood floodOpacity="0.161" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
        </defs>
        <g id="unpin" transform="translate(9 6)">
            <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Ellipse_2838)">
                <circle id="Ellipse_2838-2" data-name="Ellipse 2838" cx="21.5" cy="21.5" r="21.5" transform="translate(9 6)" fill="#fff" opacity="0" />
            </g>
            <g id="unpin-2" data-name="unpin" transform="translate(10.121 7.942)">
                <path id="Path_9633" data-name="Path 9633" d="M93.188,399l1.182,1.182-5.431,5.431a.832.832,0,0,1-1.165-1.186Z" transform="translate(-87.529 -380.886)" fill="#1a78e5" />
                <path id="Path_9634" data-name="Path 9634" d="M120.918,243.83l10.043,10.043a1.347,1.347,0,0,1,0,1.773l-.266.266a1.253,1.253,0,0,1-1.773,0l-10.043-10.043a1.347,1.347,0,0,1,0-1.772l.266-.266A1.254,1.254,0,0,1,120.918,243.83Z" transform="translate(-117.065 -232.774)" fill="#1a78e5" />
                <path id="Path_9635" data-name="Path 9635" d="M201.219,104.56l-8.109,7,8.848,8.848,7.006-8.109Z" transform="translate(-188.069 -100.503)" fill="#1a78e5" />
                <path id="Path_9636" data-name="Path 9636" d="M365.878,20.09l8.067,8.067a1.8,1.8,0,0,1,0,2.363l-.449.449a1.671,1.671,0,0,1-2.363,0L363.066,22.9a1.8,1.8,0,0,1,0-2.363l.449-.449a1.671,1.671,0,0,1,2.363,0Z" transform="translate(-349.487 -19.601)" fill="#1a78e5" />
                <rect id="Rectangle_4054" data-name="Rectangle 4054" width="5" height="26" transform="translate(2.527 4.425) rotate(-45)" fill="#fff" />
                <rect id="Rectangle_4055" data-name="Rectangle 4055" width="3.294" height="26" transform="translate(2.479 4.984) rotate(-45)" fill="#1a78e5" />
            </g>
        </g>
    </svg>,

    Delete: <svg xmlns="http://www.w3.org/2000/svg" width="14" height="22" viewBox="0 0 14 22"><path className="a" d="M0,19.938A2.081,2.081,0,0,0,2.1,22h9.8A2.081,2.081,0,0,0,14,19.938V5.5H0ZM1.4,6.875H12.6V19.938a.694.694,0,0,1-.7.688H2.1a.694.694,0,0,1-.7-.687Z" /><rect className="a" width="1.4" height="11" transform="translate(8.4 8.25)" /><rect className="a" width="1.4" height="11" transform="translate(4.2 8.25)" /><path className="a" d="M9.8,0H4.2V2.75H0V4.125H14V2.75H9.8ZM8.4,2.75H5.6V1.375H8.4Z" /></svg>,
    CloseIcon: <svg xmlns="http://www.w3.org/2000/svg" width="13.625" height="13.625" viewBox="0 0 13.625 13.625">
        <path id="nextc_ross_icon" d="M1899.9,95.1a.718.718,0,0,1,0-1.016l5.586-5.586-5.586-5.587a.718.718,0,0,1,1.015-1.015l5.587,5.586,5.586-5.586a.718.718,0,0,1,1.016,1.015l-5.587,5.587,5.587,5.586a.718.718,0,1,1-1.016,1.016l-5.586-5.587-5.587,5.587a.717.717,0,0,1-1.015,0Z" transform="translate(-1899.688 -81.688)" fill="#fff" />
    </svg>,

    Pdf: <svg xmlns="http://www.w3.org/2000/svg" width="31.138" height="42.92" viewBox="0 0 31.138 42.92">
        <g id="pdf_icon" transform="translate(-146.654 0.003)">
            <path id="Path_1064" data-name="Path 1064" d="M328.946,421.47a4.815,4.815,0,0,0-1.045.091v6.68a4.245,4.245,0,0,0,.8.046c2.09.015,3.453-1.136,3.453-3.574A2.928,2.928,0,0,0,328.946,421.47Z" transform="translate(-167.351 -389.159)" fill="#5d5d5d" />
            <path id="Path_1065" data-name="Path 1065" d="M213.669,421.08a3.961,3.961,0,0,0-.954.09v3.015a3.539,3.539,0,0,0,.771.061c1.228,0,1.985-.621,1.985-1.666C215.472,421.64,214.82,421.08,213.669,421.08Z" transform="translate(-60.996 -388.798)" fill="#5d5d5d" />
            <path id="Path_1066" data-name="Path 1066" d="M177.784,10.282a.833.833,0,0,0-.2-.545L169.308.285s0,0-.007-.006a.848.848,0,0,0-.167-.14l-.056-.033a.878.878,0,0,0-.166-.069l-.045-.015A.827.827,0,0,0,168.675,0H148.337a1.685,1.685,0,0,0-1.683,1.683V41.233a1.685,1.685,0,0,0,1.683,1.683h27.772a1.685,1.685,0,0,0,1.683-1.683V10.377c0-.032-.005-.063-.008-.094ZM155.822,36.143a4.733,4.733,0,0,1-3.317,1.076,5.911,5.911,0,0,1-.787-.045v3.65h-2.287V30.751a18.947,18.947,0,0,1,3.12-.212,4.9,4.9,0,0,1,3.12.818,2.983,2.983,0,0,1,1.09,2.363,3.26,3.26,0,0,1-.939,2.423Zm9.739,3.469a7.323,7.323,0,0,1-4.709,1.318,20.071,20.071,0,0,1-2.621-.151V30.751a20.7,20.7,0,0,1,3.121-.212,6.572,6.572,0,0,1,4.18,1.09,4.517,4.517,0,0,1,1.727,3.847,5.18,5.18,0,0,1-1.7,4.135Zm9.453-7.1h-3.924v2.332h3.666V36.72h-3.666v4.1h-2.317V30.616h6.24Zm-26.678-3.933V1.68h19.5v8.612a.842.842,0,0,0,.842.842h7.433V28.575Z" transform="translate(0 0)" fill="#5d5d5d" />
            <path id="Path_1067" data-name="Path 1067" d="M218.275,87.57c-.049,0-1.235-.113-3.055-.113-.57,0-1.145.011-1.712.033a90.8,90.8,0,0,1-8.116-6.911c.029-.167.049-.3.058-.4.208-2.195-.023-3.676-.685-4.4a1.67,1.67,0,0,0-1.732-.452,2.071,2.071,0,0,0-1.419,1.321c-.269.9.164,1.99,1.3,3.255.018.019.4.424,1.106,1.11-.456,2.175-1.65,6.869-2.229,9.122a11.834,11.834,0,0,0-3.372,2.608l-.057.066-.037.079a2.729,2.729,0,0,0-.2,1.968,1.341,1.341,0,0,0,.8.761l.1.027a1.329,1.329,0,0,0,.252.019c.7,0,2.435-.369,3.365-3.794l.225-.868a27.571,27.571,0,0,1,10.24-2.228c1.512,1.122,3.017,2.152,4.475,3.064l.048.028a3.508,3.508,0,0,0,1.459.353,2.152,2.152,0,0,0,2.143-1.8l.015-.078a2.148,2.148,0,0,0-.475-1.7,3.788,3.788,0,0,0-2.5-1.06ZM199.309,94.4a.075.075,0,0,1-.013-.023,1.535,1.535,0,0,1,.137-.874,9.906,9.906,0,0,1,1.9-1.624c-.714,2.311-1.752,2.511-2.022,2.522Zm4.533-15.355c-1.1-1.22-1.08-1.825-1.021-2.029.1-.341.534-.469.538-.471.22-.06.353-.048.472.083.269.3.5,1.187.409,2.822-.259-.26-.4-.405-.4-.405Zm-.566,10.393.019-.072h0c.549-2.15,1.341-5.3,1.8-7.321l.016.016v-.01c1.473,1.387,3.731,3.415,6.435,5.525l-.03,0,.045.034a28.606,28.606,0,0,0-8.28,1.826ZM220,90.14c-.194.713-.567.81-.909.81a2.27,2.27,0,0,1-.866-.205c-.993-.622-2.008-1.3-3.033-2.026h.025c1.757,0,2.927.107,2.973.11a2.61,2.61,0,0,1,1.622.619.868.868,0,0,1,.188.692Z" transform="translate(-47.418 -69.484)" fill="#5d5d5d" />
        </g>
    </svg>,

    Doc: <svg xmlns="http://www.w3.org/2000/svg" width="28.719" height="34.77" viewBox="0 0 28.719 34.77">
        <g id="doc_icon" transform="translate(-186.663 -82.827)">
            <path id="Path_1068" data-name="Path 1068" d="M215.382,91.894a1.12,1.12,0,0,0-.051-.266.377.377,0,0,0,0-.092,1.2,1.2,0,0,0-.195-.308l-8.205-8.205h0a1.2,1.2,0,0,0-.308-.2H187.689a1.026,1.026,0,0,0-1.025,1.026v32.719a1.025,1.025,0,0,0,1.025,1.025h26.668a1.025,1.025,0,0,0,1.025-1.025V91.894Zm-8.205-5.672,4.708,4.708h-4.708Zm6.154,29.324H188.715V84.776h16.411v7.18a1.025,1.025,0,0,0,1.025,1.025h7.18Z" transform="translate(0 0)" fill="#5d5d5d" />
            <path id="Path_1069" data-name="Path 1069" d="M245,222.691a1.026,1.026,0,0,0,1.026,1.026h16.41a1.026,1.026,0,1,0,0-2.051h-16.41A1.026,1.026,0,0,0,245,222.691Z" transform="translate(-53.208 -126.633)" fill="#5d5d5d" />
            <path id="Path_1070" data-name="Path 1070" d="M281.666,293.719a1.026,1.026,0,1,0,0-2.051H269.358a1.026,1.026,0,1,0,0,2.051Z" transform="translate(-74.489 -190.481)" fill="#5d5d5d" />
            <path id="Path_1071" data-name="Path 1071" d="M262.438,361.67h-16.41a1.026,1.026,0,1,0,0,2.051h16.41a1.026,1.026,0,1,0,0-2.051Z" transform="translate(-53.21 -254.329)" fill="#5d5d5d" />
        </g>
    </svg>,

    Csv: <svg xmlns="http://www.w3.org/2000/svg" width="31.82" height="38.183" viewBox="0 0 31.82 38.183">
        <path id="csv_icon" d="M162.016,29.2a3.444,3.444,0,0,0-2.889-1.2H142.54c-1.823,0-2.539.716-2.539,2.539V63.645c0,1.823.716,2.539,2.539,2.539h26.742c1.823,0,2.539-.716,2.539-2.539V40.693a3.444,3.444,0,0,0-1.2-2.889ZM169.6,63.286c0,.486-.191.677-.677.677H142.9c-.486,0-.677-.191-.677-.677V55.073H169.6ZM150.54,51.753a4.193,4.193,0,0,0,1.4-.241l.252,1.307a4.776,4.776,0,0,1-1.9.319,3.522,3.522,0,0,1-3.879-3.7,3.775,3.775,0,0,1,4.044-3.933,4.369,4.369,0,0,1,1.824.33l-.341,1.33a3.621,3.621,0,0,0-1.406-.275,2.435,2.435,0,0,0,.011,4.867Zm4.591-1.835c-1.23-.429-2.032-1.11-2.032-2.187,0-1.263,1.055-2.23,2.8-2.23a4.54,4.54,0,0,1,1.89.374l-.373,1.351a3.526,3.526,0,0,0-1.549-.352c-.725,0-1.077.33-1.077.714,0,.472.418.681,1.373,1.044,1.308.483,1.923,1.165,1.923,2.209,0,1.242-.955,2.3-2.988,2.3a4.848,4.848,0,0,1-2.1-.45l.341-1.384a4.267,4.267,0,0,0,1.856.462c.769,0,1.176-.319,1.176-.8,0-.462-.352-.725-1.242-1.044Zm3.46-4.3h1.835l.9,3.131c.252.879.484,1.725.66,2.648h.033c.187-.89.417-1.769.67-2.615l.945-3.165h1.78l-2.495,7.406h-1.956ZM169.6,43.565H142.223V30.9c0-.486.191-.677.677-.677H159.8c.243,0,.339.1.339.338V37.14c0,1.823.716,2.539,2.538,2.539h6.581c.243,0,.339.1.339.339Z" transform="translate(-140.001 -28)" fill="#5d5d5d" />
    </svg>,

    Bell: (fillColor) => <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill={fillColor}>
        <g id="notification_icon" transform="translate(11 8.249)">
            <g id="Rectangle_3967" data-name="Rectangle 3967" transform="translate(-11 -8.249)" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
                <rect width="46" height="46" stroke="none" />
                <rect x="0.5" y="0.5" width="45" height="45" fill="none" />
            </g>
            <path id="Path_102" data-name="Path 102" d="M43.907,37.278a7.513,7.513,0,0,1-1.7-4.767v-3.32a8.311,8.311,0,0,0-6.554-8.129V19.488a1.788,1.788,0,1,0-3.575,0v1.575a8.311,8.311,0,0,0-6.554,8.129v3.32a7.513,7.513,0,0,1-1.7,4.767l-1.4,1.788a1.93,1.93,0,0,0,1.49,3.15h20a1.93,1.93,0,0,0,1.49-3.15Z" transform="translate(-21.979 -17.7)" />
            <path id="Path_107" data-name="Path 107" d="M44.256,85.156A4.269,4.269,0,0,0,48.512,80.9H40A4.269,4.269,0,0,0,44.256,85.156Z" transform="translate(-32.33 -54.002)" />
        </g>
    </svg>,

    CustomerChat: (fillColor) => <svg width="79" height="79" viewBox="0 0 79 79">
        <defs>
            <filter id="Ellipse_2815" x="0" y="0" width="79" height="79" filterUnits="userSpaceOnUse">
                <feOffset input="SourceAlpha" />
                <feGaussianBlur stdDeviation="3" result="blur" />
                <feFlood floodOpacity="0.161" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
            <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                <stop offset="0" stop-color="#4776e6" />
                <stop offset="1" stop-color="#8e54e9" />
            </linearGradient>
        </defs>
        <g id="help_icon_new" transform="translate(9 9)">
            <g transform="matrix(1, 0, 0, 1, -9, -9)" filter="url(#Ellipse_2815)">
                <circle id="Ellipse_2815-2" data-name="Ellipse 2815" cx="30.5" cy="30.5" r="30.5" transform="translate(9 9)" fill="#fff" />
            </g>
            <g id="noun-chatbot-4116565" transform="translate(-246.681 -408.001)">
                <path id="Path_9315" data-name="Path 9315" d="M140.77,83.2a2.343,2.343,0,0,0,0-4.686,2.386,2.386,0,0,0-2.343,2.343A2.272,2.272,0,0,0,140.77,83.2Zm12.979,0a2.389,2.389,0,1,0-2.434-2.343A2.348,2.348,0,0,0,153.749,83.2ZM128.242,85.36V85a17.348,17.348,0,0,1,17.4-17.4V65.441a2.482,2.482,0,0,1-.541-3.515,2.564,2.564,0,0,1,3.515-.541,2.482,2.482,0,0,1,.541,3.515c-.18.18-.36.451-.541.541V67.6a17.348,17.348,0,0,1,17.4,17.4v.36a8.27,8.27,0,0,1-8.292,8.292H136.535a8.388,8.388,0,0,1-8.293-8.292Zm22.533,2.524h-7.12A3.519,3.519,0,0,0,147.17,91.4,3.484,3.484,0,0,0,150.775,87.883ZM136.084,81.3a3.924,3.924,0,0,0,3.875,3.966H154.29a3.847,3.847,0,0,0,3.875-3.875V80.313a3.924,3.924,0,0,0-3.875-3.966H140.05a3.924,3.924,0,0,0-3.966,3.875Z" transform="translate(130.439 358.335)" fill="url(#linear-gradient)" />
            </g>
        </g>
    </svg>,

    feedbackImg: <svg xmlns="http://www.w3.org/2000/svg" width="362.868" height="295.7" viewBox="0 0 362.868 295.7">
        <g id="feedback_graphic" transform="translate(0 0)">
            <path id="Path_9288" data-name="Path 9288" d="M527.08,570.095h7.8L538.6,540H527.079Z" transform="translate(-418.039 -283.167)" fill="#a0616a" />
            <path id="Path_9289" data-name="Path 9289" d="M772.8,745.761l8.325-3.382.246-.1,4.6,3.483a9.788,9.788,0,0,1,9.78,9.366c.013.137.013.282.013.427v.321H770.6V745.762Z" transform="translate(-663.551 -461.384)" fill="#2f2e41" />
            <path id="Path_9290" data-name="Path 9290" d="M483.238,559.568l7.361,2.59L504.09,535l-10.863-3.821Z" transform="translate(-355.924 -279.901)" fill="#a0616a" />
            <path id="Path_9291" data-name="Path 9291" d="M752.038,736.189l8.976-.427.265-.014,3.181,4.811a9.788,9.788,0,0,1,6.118,12.08c-.034.135-.082.271-.13.408l-.107.3L746.608,745l3.357-9.541Z" transform="translate(-623.682 -458.859)" fill="#2f2e41" />
            <path id="Path_9292" data-name="Path 9292" d="M717.9,538.914c-7.138-.277,1.7,56.61,1.7,56.61s-2.587,49.944-2.038,52.565.069,9.69.069,9.69c1.4-2.545,15.827-.624,15.827-.624l1.963-50.618,10.022-40.557,13.894,27.186-13.317,41.729s-5.393,4.99-4.819,6.962,14.7,6.419,17.3,6.52,1.424-3.194,1.55-6.439,12.341-33.318,16.637-43.55.956-24.66-1.237-35.144-13.731-30.159-13.731-30.159C747.4,516.931,725.042,539.19,717.9,538.914Z" transform="translate(-611.62 -381.755)" fill="#2f2e41" />
            <path id="Path_9293" data-name="Path 9293" d="M698.362,467.792a5.768,5.768,0,0,1-8.641,1.886l-17.166,11.2.052-10.651,16.293-9a5.8,5.8,0,0,1,9.461,6.567Z" transform="translate(-505.084 -356.605)" fill="#a0616a" />
            <path id="Path_9294" data-name="Path 9294" d="M763.034,504.3c17.7,0,27.458-3.543,32.639-6.707,6.335-3.869,7.418-7.949,7.462-8.121l.013-.049-.617-7.759-.27-.6a12.283,12.283,0,0,1-.462-8.821l.019-.06,0-.062-4.139-53.684-14.648-4.8-4.261-4.282-18.973-.275-2.644,5.359-14.958,5.631,7.348,43.741,3.523,6.072-1.418,6.614.01.06,4.621,27.584.254.011Q759.947,504.3,763.034,504.3Z" transform="translate(-653.043 -338.022)" fill="#3f3d56" />
            <path id="Path_9295" data-name="Path 9295" d="M714.24,475.946l6.945-4.117.435-.628a9.909,9.909,0,0,1,7.843-4.25l.081,0,9.565-5.624-3.965-11.615-14.995,4.43L709.7,428.165a8.312,8.312,0,0,0-14.784-1.264,8.58,8.58,0,0,0-1.239,4.538v17l.063.084Z" transform="translate(-558.451 -343.16)" fill="#3f3d56" />
            <path id="Path_9296" data-name="Path 9296" d="M830.567,532.522a5.768,5.768,0,0,0-7.675-4.4l-13.008-15.84-3.15,10.175,12.836,13.482a5.8,5.8,0,0,0,11-3.422Z" transform="translate(-721.209 -376.233)" fill="#a0616a" />
            <path id="Path_9297" data-name="Path 9297" d="M837.625,497.645l8.638-8.639-5.5-13.4L832.8,464.727c8.492-4.134,14.866-13.927,19.485-22.034l.052-.091-1.15-9.008a11.568,11.568,0,0,0-21.275-4.682L814.42,463.34l.174.26,12.457,21.235,3.4,2.2Z" transform="translate(-747.717 -343.358)" fill="#3f3d56" />
            <path id="Path_9298" data-name="Path 9298" d="M767.778,369.787a15.81,15.81,0,1,0,31.331-4.264l-.031-.224a15.809,15.809,0,1,0-31.3,4.488Z" transform="translate(-665.167 -316.79)" fill="#a0616a" />
            <path id="Path_9299" data-name="Path 9299" d="M774.242,375.453c-.812-1.649-1.646-3.42-1.418-5.244s2.017-3.568,3.774-3.025c1.05.324,1.844,1.361,2.942,1.414,1.511.073,2.377-1.656,2.847-3.093l1.915-5.855a15.841,15.841,0,0,0,13.156,2.973,7.509,7.509,0,0,0,4.618-2.7c1.043-1.5,1.037-3.817-.394-4.949-.7-.555-1.635-.766-2.305-1.358a2.583,2.583,0,0,1,2.063-4.5l-4.822-.6,1.437-2.6a4.745,4.745,0,0,1-3.773.692c-1.3-.234-2.519-.768-3.788-1.124a14.58,14.58,0,0,0-16.219,6.811,6.173,6.173,0,0,0-6.73,1.943,9.818,9.818,0,0,0-1.692,5.91,25.64,25.64,0,0,0,2.614,11.878,9.731,9.731,0,0,0,2.2,3.115,4.395,4.395,0,0,0,3.528,1.154" transform="translate(-667.607 -314.312)" fill="#2f2e41" />
            <path id="Path_9300" data-name="Path 9300" d="M609.8,341.123a24.557,24.557,0,0,1-3.174,10.464c-3.859,6.777-10.476,11.683-17.6,14.6-8.9,3.644-18.83,3.756-28.28,2.943-8.777-.755-17.464-2.5-26.274-2.864a183.061,183.061,0,0,0-20.427.487c-13.182.936-26.309,2.672-39.352,4.776q-5.3.844-10.575,1.815a5.231,5.231,0,0,0-.982.182c-.064.026-.167.006-.225.043-.106.067.429-.17.1.1a9.741,9.741,0,0,0-1.2,1.55l-4.817,6.2c-1.381,1.778-2.663,3.938-4.449,5.339a8.055,8.055,0,0,1-8.917.621,8.911,8.911,0,0,1-3.316-3.767,32.507,32.507,0,0,0-3.943-5.692c-5.731-6.685-13.7-10.8-20.357-16.423a36.258,36.258,0,0,1-8.142-9.307,26.319,26.319,0,0,1-3.364-10.451c-.8-7.476,1.5-15.1,5.355-21.46a42.869,42.869,0,0,1,17.4-15.929c8.062-4.107,16.984-6.108,25.926-7.105a234.916,234.916,0,0,1,28.641-.948q16.061.179,32.065,1.413,16.025,1.24,31.924,3.526c10.17,1.462,20.465,2.986,30.42,5.574,8.795,2.286,17.564,5.858,24.132,12.331C606.205,324.894,610.267,332.77,609.8,341.123Z" transform="translate(-246.965 -296.249)" fill="#6c63ff" />
            <path id="Path_9301" data-name="Path 9301" d="M486.7,360.181a3.153,3.153,0,0,0,2.987-3.542l-.226-1.779a2.124,2.124,0,0,1,.691-1.85l1.336-1.194a3.144,3.144,0,0,0-1.508-5.434l-1.762-.335a2.121,2.121,0,0,1-1.545-1.228l-.723-1.642a3.145,3.145,0,0,0-5.634-.244l-.862,1.572A2.121,2.121,0,0,1,477.8,345.6l-1.783.18a3.144,3.144,0,0,0-1.974,5.283l1.228,1.306a2.117,2.117,0,0,1,.528,1.9l-.379,1.754a3.144,3.144,0,0,0,4.414,3.509l1.622-.764a2.116,2.116,0,0,1,1.973.085l1.55.9A3.148,3.148,0,0,0,486.7,360.181Z" transform="translate(-173.025 -312.93)" opacity="0.329" />
            <path id="Path_9302" data-name="Path 9302" d="M526.79,359.016a3.176,3.176,0,0,0,2.118-.946,3.258,3.258,0,0,0,.853-2.722l-.207-1.632a2.161,2.161,0,0,1,.7-1.884l1.24-1.108a3.233,3.233,0,0,0,1.133-2.537,3.143,3.143,0,0,0-2.56-2.97l-1.741-.331a2.161,2.161,0,0,1-1.574-1.252l-.649-1.473a3.291,3.291,0,0,0-2.775-2.019,3.128,3.128,0,0,0-2.924,1.627l-.852,1.554a2.162,2.162,0,0,1-1.678,1.112l-1.653.167a3.231,3.231,0,0,0-2.409,1.388,3.144,3.144,0,0,0,.327,3.906l1.214,1.291a2.161,2.161,0,0,1,.538,1.936l-.35,1.619a3.246,3.246,0,0,0,.57,2.72,3.136,3.136,0,0,0,3.819.9l1.605-.756a2.16,2.16,0,0,1,2.007.087l1.532.892A3.148,3.148,0,0,0,526.79,359.016Z" transform="translate(-238.369 -312.498)" fill="#ffeb34" />
            <path id="Path_9303" data-name="Path 9303" d="M566.991,358.847a3.153,3.153,0,0,0,2.987-3.542l-.226-1.779a2.124,2.124,0,0,1,.691-1.85l1.336-1.194a3.144,3.144,0,0,0-1.508-5.434l-1.762-.335a2.12,2.12,0,0,1-1.545-1.228l-.723-1.642a3.145,3.145,0,0,0-5.634-.244l-.862,1.572a2.121,2.121,0,0,1-1.646,1.091l-1.783.18a3.144,3.144,0,0,0-1.974,5.283l1.228,1.306a2.117,2.117,0,0,1,.528,1.9l-.379,1.754a3.144,3.144,0,0,0,4.414,3.509l1.622-.764a2.116,2.116,0,0,1,1.973.085l1.55.9A3.148,3.148,0,0,0,566.991,358.847Z" transform="translate(-303.886 -312.435)" fill="#ffeb34" />
            <path id="Path_9304" data-name="Path 9304" d="M606.091,357.786a3.153,3.153,0,0,0,2.987-3.542l-.226-1.779a2.124,2.124,0,0,1,.691-1.85l1.336-1.194a3.144,3.144,0,0,0-1.508-5.434l-1.762-.335a2.12,2.12,0,0,1-1.545-1.227l-.723-1.642a3.145,3.145,0,0,0-5.634-.244l-.862,1.572A2.121,2.121,0,0,1,597.2,343.2l-1.783.18a3.144,3.144,0,0,0-1.974,5.283l1.228,1.306a2.117,2.117,0,0,1,.528,1.9l-.379,1.754a3.144,3.144,0,0,0,4.414,3.509l1.622-.764a2.116,2.116,0,0,1,1.973.085l1.55.9A3.149,3.149,0,0,0,606.091,357.786Z" transform="translate(-367.611 -312.043)" fill="#ffeb00" />
            <path id="Path_9305" data-name="Path 9305" d="M645.192,356.725a3.153,3.153,0,0,0,2.987-3.542l-.226-1.779a2.124,2.124,0,0,1,.691-1.85l1.336-1.194a3.144,3.144,0,0,0-1.508-5.434l-1.762-.335a2.121,2.121,0,0,1-1.545-1.228l-.723-1.642a3.145,3.145,0,0,0-5.634-.244l-.862,1.572a2.121,2.121,0,0,1-1.647,1.091l-1.783.18a3.144,3.144,0,0,0-1.974,5.283l1.228,1.306a2.117,2.117,0,0,1,.528,1.9l-.379,1.754a3.144,3.144,0,0,0,4.414,3.509l1.622-.764a2.116,2.116,0,0,1,1.972.085l1.55.9A3.148,3.148,0,0,0,645.192,356.725Z" transform="translate(-431.337 -311.65)" fill="#ffeb00" />
            <path id="Path_9306" data-name="Path 9306" d="M872.653,688.286l-.72-16.2a52.312,52.312,0,0,0-24.4-6.177c11.719,9.581,10.255,28.05,18.2,40.935a31.466,31.466,0,0,0,23.074,14.541l9.807,6A52.727,52.727,0,0,0,887.5,684.665a50.932,50.932,0,0,0-9.36-8.7C875.791,682.161,872.653,688.286,872.653,688.286Z" transform="translate(-815.68 -433.111)" fill="#f2f2f2" />
            <path id="Path_9307" data-name="Path 9307" d="M561.525,764.907H824.378a.75.75,0,1,0,0-1.5H561.525a.75.75,0,1,0,0,1.5Z" transform="translate(-560.775 -469.206)" fill="#cacaca" />
        </g>
    </svg>,

    SpeakerIconStage: <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
        <g id="speakers_icon" transform="translate(-86.421 3.004)">
            <path id="Path_9388" data-name="Path 9388" d="M0,0H38V38H0Z" transform="translate(86.421 -3.004)" fill="none" />
            <g id="Group_688" data-name="Group 688" transform="translate(1.795 1.936)">
                <path id="Path_9375" data-name="Path 9375" d="M219.09,13.8a6.911,6.911,0,1,0-4.877-2.024A6.9,6.9,0,0,0,219.09,13.8Zm0-11.748a4.855,4.855,0,1,1-3.424,1.423,4.844,4.844,0,0,1,3.424-1.423Z" transform="translate(-115.67)" fill="" />
                <circle id="Ellipse_2818" data-name="Ellipse 2818" cx="6" cy="6" r="6" transform="translate(97.421 1.06)" fill="" />
                <path id="Path_9376" data-name="Path 9376" d="M92.479,313.419a10.942,10.942,0,0,1,21.884,0h2.058a13,13,0,1,0-26,0Z" transform="translate(0 -285.378)" fill="" />
            </g>
        </g>
    </svg>,

    VideoIconStage: <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
        <g id="video_icon" transform="translate(-133.475 -101.672)">
            <path id="Path_9386" data-name="Path 9386" d="M0,0H38V38H0Z" transform="translate(133.475 101.672)" fill="none" />
            <g id="Group_686" data-name="Group 686" transform="translate(-0.188 1)">
                <path id="Path_9369" data-name="Path 9369" d="M168.373,106.963a1,1,0,0,0-.71-.29h-30a1,1,0,0,0-1,1v24a1,1,0,0,0,1,1h30a1,1,0,0,0,1-1v-24A1,1,0,0,0,168.373,106.963Zm-29.71,6.71h28v17h-28Z" transform="translate(0)" fill="" />
                <path id="Path_9370" data-name="Path 9370" d="M283.33,240v11l11-5Z" transform="translate(-135.667 -123.328)" fill="" />
            </g>
        </g>
    </svg>,

    ImageIconStage: <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
        <g id="image_icon" transform="translate(-66.812 -62.199)">
            <path id="Path_9385" data-name="Path 9385" d="M0,0H38V38H0Z" transform="translate(66.812 62.199)" fill="none" />
            <g id="Group_685" data-name="Group 685" transform="translate(-0.188 2)">
                <path id="Path_9371" data-name="Path 9371" d="M70,67.2v24.32h32V67.2ZM99.413,88.925H72.568V69.741H99.413Z" fill="" />
                <path id="Path_9372" data-name="Path 9372" d="M499.44,136.318a1.92,1.92,0,1,1-1.92-1.92,1.92,1.92,0,0,1,1.92,1.92" transform="translate(-403.161 -61.478)" fill="" />
                <path id="Path_9373" data-name="Path 9373" d="M146.16,179.2l-8.96,14.08h10.24l3.805-6.089Zm8.96,3.84-6.4,10.24h12.8Z" transform="translate(-63.36 -105.601)" fill="" />
            </g>
        </g>
    </svg>,

    BackgroundImageIconStage: <svg xmlns="http://www.w3.org/2000/svg" width="39" height="28.935" viewBox="0 0 39 28.935">
        <g id="background_image" transform="translate(-78.75 -78.75)">
            <path id="Path_9544" data-name="Path 9544" d="M455,175h2.516v2.516H455Z" transform="translate(-349.202 -89.331)" fill="#adadad" />
            <path id="Path_9545" data-name="Path 9545" d="M385,317.516h.107l2.409-2.409V315H385Z" transform="translate(-284.234 -219.266)" fill="#adadad" />
            <path id="Path_9546" data-name="Path 9546" d="M245,175h2.516v2.516H245Z" transform="translate(-154.298 -89.331)" fill="#adadad" />
            <path id="Path_9547" data-name="Path 9547" d="M576.01,350l1.365,1.365V350Z" transform="translate(-461.512 -251.75)" fill="#adadad" />
            <path id="Path_9548" data-name="Path 9548" d="M315,105h2.516v2.516H315Z" transform="translate(-219.266 -24.363)" fill="#adadad" />
            <path id="Path_9549" data-name="Path 9549" d="M385,105h2.516v2.516H385Z" transform="translate(-284.234 -24.363)" fill="#adadad" />
            <path id="Path_9550" data-name="Path 9550" d="M245,105h2.516v2.516H245Z" transform="translate(-154.298 -24.363)" fill="#adadad" />
            <path id="Path_9551" data-name="Path 9551" d="M105,105h2.516v2.516H105Z" transform="translate(-24.363 -24.363)" fill="#adadad" />
            <path id="Path_9552" data-name="Path 9552" d="M385,245h2.516v2.516H385Z" transform="translate(-284.234 -154.298)" fill="#adadad" />
            <path id="Path_9553" data-name="Path 9553" d="M105,175h2.516v2.516H105Z" transform="translate(-24.363 -89.331)" fill="#adadad" />
            <path id="Path_9554" data-name="Path 9554" d="M455,105h2.516v2.516H455Z" transform="translate(-349.202 -24.363)" fill="#adadad" />
            <path id="Path_9555" data-name="Path 9555" d="M507.377,281.367V280h-1.365Z" transform="translate(-396.546 -186.784)" fill="#adadad" />
            <path id="Path_9556" data-name="Path 9556" d="M542.377,316.367V315h-1.365Z" transform="translate(-429.03 -219.268)" fill="#adadad" />
            <path id="Path_9557" data-name="Path 9557" d="M525,105h2.516v2.516H525Z" transform="translate(-414.169 -24.363)" fill="#adadad" />
            <path id="Path_9558" data-name="Path 9558" d="M525,245h2.516v2.516H525Z" transform="translate(-414.169 -154.298)" fill="#adadad" />
            <path id="Path_9559" data-name="Path 9559" d="M560,140h2.516v2.516H560Z" transform="translate(-446.653 -56.847)" fill="#adadad" />
            <path id="Path_9560" data-name="Path 9560" d="M560,280h2.516v2.516H560Z" transform="translate(-446.653 -186.782)" fill="#adadad" />
            <path id="Path_9561" data-name="Path 9561" d="M560,210h2.516v2.516H560Z" transform="translate(-446.653 -121.815)" fill="#adadad" />
            <path id="Path_9562" data-name="Path 9562" d="M175,105h2.516v2.516H175Z" transform="translate(-89.331 -24.363)" fill="#adadad" />
            <path id="Path_9563" data-name="Path 9563" d="M211.367,210H210v1.365Z" transform="translate(-121.816 -121.815)" fill="#adadad" />
            <path id="Path_9564" data-name="Path 9564" d="M210,140h2.516v2.516H210Z" transform="translate(-121.815 -56.847)" fill="#adadad" />
            <path id="Path_9565" data-name="Path 9565" d="M175,175h2.516v2.516H175Z" transform="translate(-89.331 -89.331)" fill="#adadad" />
            <path id="Path_9566" data-name="Path 9566" d="M140,140h2.516v2.516H140Z" transform="translate(-56.847 -56.847)" fill="#adadad" />
            <path id="Path_9567" data-name="Path 9567" d="M350,140h2.516v2.516H350Z" transform="translate(-251.75 -56.847)" fill="#adadad" />
            <path id="Path_9568" data-name="Path 9568" d="M280,140h2.516v2.516H280Z" transform="translate(-186.782 -56.847)" fill="#adadad" />
            <path id="Path_9569" data-name="Path 9569" d="M176.367,245H175v1.365Z" transform="translate(-89.332 -154.298)" fill="#adadad" />
            <path id="Path_9570" data-name="Path 9570" d="M140,210h2.516v2.516H140Z" transform="translate(-56.847 -121.815)" fill="#adadad" />
            <path id="Path_9571" data-name="Path 9571" d="M141.367,280H140v1.365Z" transform="translate(-56.849 -186.782)" fill="#adadad" />
            <path id="Path_9572" data-name="Path 9572" d="M106.367,315H105v1.365Z" transform="translate(-24.365 -219.266)" fill="#adadad" />
            <path id="Path_9573" data-name="Path 9573" d="M350,210h2.516v2.516H350Z" transform="translate(-251.75 -121.815)" fill="#adadad" />
            <path id="Path_9574" data-name="Path 9574" d="M105,245h2.516v2.516H105Z" transform="translate(-24.363 -154.298)" fill="#adadad" />
            <path id="Path_9575" data-name="Path 9575" d="M420,140h2.516v2.516H420Z" transform="translate(-316.718 -56.847)" fill="#adadad" />
            <path id="Path_9576" data-name="Path 9576" d="M315,245h2.516v2.516H315Z" transform="translate(-219.266 -154.298)" fill="#adadad" />
            <path id="Path_9577" data-name="Path 9577" d="M315,175h2.516v2.516H315Z" transform="translate(-219.266 -89.331)" fill="#adadad" />
            <path id="Path_9578" data-name="Path 9578" d="M280,210h2.516v2.516H280Z" transform="translate(-186.782 -121.815)" fill="#adadad" />
            <path id="Path_9579" data-name="Path 9579" d="M385,175h2.516v2.516H385Z" transform="translate(-284.234 -89.331)" fill="#adadad" />
            <path id="Path_9580" data-name="Path 9580" d="M490,140h2.516v2.516H490Z" transform="translate(-381.685 -56.847)" fill="#adadad" />
            <path id="Path_9581" data-name="Path 9581" d="M420,210h2.516v2.516H420Z" transform="translate(-316.718 -121.815)" fill="#adadad" />
            <path id="Path_9582" data-name="Path 9582" d="M490,210h2.516v2.516H490Z" transform="translate(-381.685 -121.815)" fill="#adadad" />
            <path id="Path_9583" data-name="Path 9583" d="M350,280h2.516v2.516H350Z" transform="translate(-251.75 -186.782)" fill="#adadad" />
            <path id="Path_9584" data-name="Path 9584" d="M525,175h2.516v2.516H525Z" transform="translate(-414.169 -89.331)" fill="#adadad" />
            <path id="Path_9585" data-name="Path 9585" d="M455,247.516h.107l1.78-1.78.629.629V245H455Z" transform="translate(-349.202 -154.298)" fill="#adadad" />
            <path id="Path_9586" data-name="Path 9586" d="M420,282.516h.107l2.409-2.409V280H420Z" transform="translate(-316.718 -186.782)" fill="#adadad" />
            <path id="Path_9587" data-name="Path 9587" d="M78.75,78.75v28.935h39V78.75Zm28.935,13.581-6.919,6.919L90.073,88.556,80.008,98.621V80.008h36.484v21.129Z" fill="#adadad" />
        </g>
    </svg>,

    ExtrasIconStage: <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
        <g id="extras_icon" transform="translate(-24 -378)">
            <path id="Path_9384" data-name="Path 9384" d="M0,0H38V38H0Z" transform="translate(24 378)" fill="none" />
            <g id="Group_684" data-name="Group 684" transform="translate(2.438 4.438)">
                <g id="Ellipse_2816" data-name="Ellipse 2816" transform="translate(27.563 378.563)" fill="none" stroke="" strokeWidth="3">
                    <circle cx="13.5" cy="13.5" r="13.5" stroke="none" />
                </g>
                <g id="dots" transform="translate(33.03 390.755)">
                    <circle id="Ellipse_2817" data-name="Ellipse 2817" cx="2" cy="2" r="2" transform="translate(-0.468 -0.192)" fill="" />
                    <circle id="Ellipse_2817-2" data-name="Ellipse 2817" cx="2" cy="2" r="2" transform="translate(6.532 -0.192)" fill="" />
                    <circle id="Ellipse_2817-3" data-name="Ellipse 2817" cx="2" cy="2" r="2" transform="translate(13.532 -0.192)" fill="" />
                </g>
            </g>
        </g>
    </svg>,

    pollIconStage: (fillColor) => <svg id="polls_icon" xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill={fillColor}>
        <path id="Path_950" data-name="Path 950" d="M0,0H38V38H0Z" fill="none" />
        <path id="Path_951" data-name="Path 951" d="M30.022,3H6.378A3.388,3.388,0,0,0,3,6.378V30.022A3.388,3.388,0,0,0,6.378,33.4H30.022A3.388,3.388,0,0,0,33.4,30.022V6.378A3.388,3.388,0,0,0,30.022,3ZM13.133,26.644H9.756V14.822h3.378Zm6.756,0H16.511V9.756h3.378Zm6.756,0H23.267V19.889h3.378Z" transform="translate(0.8 0.8)" fill="" />
    </svg>,

    pollSvgIconStage: <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
        <path id="Path_950" data-name="Path 950" d="M0,0H38V38H0Z" fill="none" />
        <path id="Path_951" data-name="Path 951" d="M30.022,3H6.378A3.388,3.388,0,0,0,3,6.378V30.022A3.388,3.388,0,0,0,6.378,33.4H30.022A3.388,3.388,0,0,0,33.4,30.022V6.378A3.388,3.388,0,0,0,30.022,3ZM13.133,26.644H9.756V14.822h3.378Zm6.756,0H16.511V9.756h3.378Zm6.756,0H23.267V19.889h3.378Z" transform="translate(0.8 0.8)" fill="" />
    </svg>,

    LiveUserStage: <svg xmlns="http://www.w3.org/2000/svg" width="38" height="41.3" viewBox="0 0 38 41.3">
        <path id="live_user" d="M109.678,30.739a12.518,12.518,0,1,1,19.2,10.58l-1.064-1.69a10.513,10.513,0,1,0-11.242,0l-1.064,1.69a12.51,12.51,0,0,1-5.832-10.58ZM127.809,49.2,125.19,38.982a2.175,2.175,0,0,0-2.129-1.653h-1.729a2.175,2.175,0,0,0-2.129,1.653L116.585,49.2a3.065,3.065,0,0,0,2.98,3.84h5.265a3.065,3.065,0,0,0,2.98-3.84ZM122.2,26.353a4.385,4.385,0,1,1-4.386,4.386A4.386,4.386,0,0,1,122.2,26.353Zm-19,4.386a19,19,0,1,1,27.25,17.117l-.869-1.8a17,17,0,1,0-14.762,0l-.869,1.8A19.008,19.008,0,0,1,103.2,30.739Z" transform="translate(-103.196 -11.739)" fill="" />
    </svg>,

    RequestIconStage: <svg xmlns="http://www.w3.org/2000/svg" width="40" height="39.835" viewBox="0 0 40 39.835">
        <g id="request_icon" transform="translate(-123.01 -53.94)">
            <path id="Path_9432" data-name="Path 9432" d="M163.01,69.992c0-8.865-8.963-16.052-20-16.052s-20,7.187-20,16.052,8.963,16.052,20,16.052h.378l11.168,7.73-.707-10.3c5.51-2.846,9.162-7.829,9.162-13.487Zm-22.96,1.563h-1.48v1.48a1.316,1.316,0,0,1-2.632,0v-1.48h-1.48a1.316,1.316,0,0,1,0-2.632h1.48v-1.48a1.316,1.316,0,1,1,2.632,0v1.48h1.48a1.316,1.316,0,1,1,0,2.632Zm8.207-8.536a2.878,2.878,0,1,1-2.878,2.878A2.881,2.881,0,0,1,148.256,63.019Zm0,14.095s-4.655.346-4.655-2.385a4.663,4.663,0,1,1,9.309,0C152.911,77.459,148.256,77.114,148.256,77.114Z" fill="" />
        </g>
    </svg>,

    DotsIcon: <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
        <g id="extras_icon" transform="translate(-24 -378)">
            <path id="Path_9384" data-name="Path 9384" d="M0,0H38V38H0Z" transform="translate(24 378)" fill="none" />
            <g id="Group_684" data-name="Group 684" transform="translate(2.438 4.438)">
                <g id="Ellipse_2816" data-name="Ellipse 2816" transform="translate(27.563 378.563)" fill="#5d5e5d" stroke="#5d5e5d" strokeWidth="3">
                    <circle cx="13.5" cy="13.5" r="13.5" stroke="none" />
                </g>
                <g id="dots" transform="translate(33.03 390.755)">
                    <circle id="Ellipse_2817" data-name="Ellipse 2817" cx="2" cy="2" r="2" transform="translate(-0.468 -0.192)" fill="#fff" />
                    <circle id="Ellipse_2817-2" data-name="Ellipse 2817" cx="2" cy="2" r="2" transform="translate(6.532 -0.192)" fill="#fff" />
                    <circle id="Ellipse_2817-3" data-name="Ellipse 2817" cx="2" cy="2" r="2" transform="translate(13.532 -0.192)" fill="#fff" />
                </g>
            </g>
        </g>
    </svg>,

    GrayDotsIcon: <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
        <g id="extras_icon" transform="translate(-24 -378)">
            <path id="Path_9384" data-name="Path 9384" d="M0,0H38V38H0Z" transform="translate(24 378)" fill="none" />
            <g id="Group_684" data-name="Group 684" transform="translate(2.438 4.438)">
                <g id="Ellipse_2816" data-name="Ellipse 2816" transform="translate(27.563 378.563)" fill="#dbdbdb" stroke="#dbdbdb" strokeWidth="3">
                    <circle cx="13.5" cy="13.5" r="13.5" stroke="none" />
                </g>
                <g id="dots" transform="translate(33.03 390.755)">
                    <circle id="Ellipse_2817" data-name="Ellipse 2817" cx="2" cy="2" r="2" transform="translate(-0.468 -0.192)" fill="#333333" />
                    <circle id="Ellipse_2817-2" data-name="Ellipse 2817" cx="2" cy="2" r="2" transform="translate(6.532 -0.192)" fill="#333333" />
                    <circle id="Ellipse_2817-3" data-name="Ellipse 2817" cx="2" cy="2" r="2" transform="translate(13.532 -0.192)" fill="#333333" />
                </g>
            </g>
        </g>
    </svg>,

    DeleteIcon: <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" width="48" height="48"><path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4V14H12v24zM38 8h-7l-2-2H19l-2 2h-7v4h28V8z" /><path fill="none" d="M0 0h48v48H0z" /></svg>,

    AddIcon: <svg xmlns="http://www.w3.org/2000/svg" width="20.891" height="20.89" viewBox="0 0 20.891 20.89">
        <path id="add_icon" d="M1899.9,95.1a.718.718,0,0,1,0-1.016l5.586-5.586-5.586-5.587a.718.718,0,0,1,1.015-1.015l5.587,5.586,5.586-5.586a.718.718,0,0,1,1.016,1.015l-5.587,5.587,5.587,5.586a.718.718,0,1,1-1.016,1.016l-5.586-5.587-5.587,5.587a.717.717,0,0,1-1.015,0Z" transform="translate(-1275.075 -1400.233) rotate(45)" fill="#5d5d5d" stroke="#5d5d5d" strokeWidth="1" />
    </svg>,

    AddMoreIcon: <svg xmlns="http://www.w3.org/2000/svg" width="74" height="74" viewBox="0 0 74 74">
        <g id="add_icon" transform="translate(301.891 -1464)">
            <rect id="Rectangle_3923" data-name="Rectangle 3923" width="74" height="74" rx="37" transform="translate(-301.891 1464)" fill="rgb(255 255 255 / 40%)" opacity="0.284" />
            <rect id="Rectangle_3922" data-name="Rectangle 3922" width="56" height="56" rx="28" transform="translate(-292.891 1473)" fill="#1a78e5" />
            <path id="cross_icon" d="M1899.9,95.1a.718.718,0,0,1,0-1.016l5.586-5.586-5.586-5.587a.718.718,0,0,1,1.015-1.015l5.587,5.586,5.586-5.586a.718.718,0,0,1,1.016,1.015l-5.587,5.587,5.587,5.586a.718.718,0,1,1-1.016,1.016l-5.586-5.587-5.587,5.587a.717.717,0,0,1-1.015,0Z" transform="translate(-1550.775 89.956) rotate(45)" fill="#fff" stroke="#fff" strokeWidth="1" />
        </g>
    </svg>,

    TimerIcon: <svg xmlns="http://www.w3.org/2000/svg" width="22.976" height="22.976" viewBox="0 0 22.976 22.976">
        <path id="timer_icon" d="M4.488,15.336A11.264,11.264,0,0,1,6.039,9.562a11.478,11.478,0,0,1,4.193-4.193,11.466,11.466,0,0,1,10.213-.633,11.591,11.591,0,0,1,3.663,2.449,11.249,11.249,0,0,1,2.449,3.673A11.409,11.409,0,0,1,20.445,25.9a11.45,11.45,0,0,1-8.937,0,11.305,11.305,0,0,1-6.121-6.1A11.056,11.056,0,0,1,4.488,15.336Zm2.53,0a8.671,8.671,0,0,0,2.642,6.305,8.617,8.617,0,0,0,6.326,2.642,8.674,8.674,0,0,0,4.479-1.2,9.052,9.052,0,0,0,3.275-3.265,8.887,8.887,0,0,0,0-8.968,9,9,0,0,0-3.275-3.275,8.933,8.933,0,0,0-8.958,0,9.187,9.187,0,0,0-3.285,3.275A8.639,8.639,0,0,0,7.018,15.336Zm8.08,0V8.542a.847.847,0,1,1,1.694,0v5.938h3.857a.836.836,0,0,1,.847.847.858.858,0,0,1-.245.6.8.8,0,0,1-.6.255h-4.53a.783.783,0,0,1-.184.01.82.82,0,0,1-.837-.857Z" transform="translate(-4.488 -3.828)" />
    </svg>,

    EditIcon: <svg xmlns="http://www.w3.org/2000/svg" width="19.482" height="19.482" viewBox="0 0 19.482 19.482">
        <path id="edit_profile" d="M4.5,19.92v4.058H8.558L20.527,12.009,16.469,7.951ZM23.666,8.871a1.078,1.078,0,0,0,0-1.526L21.133,4.813a1.078,1.078,0,0,0-1.526,0l-1.98,1.98,4.058,4.058,1.98-1.98Z" transform="translate(-4.5 -4.496)" fill="#5d5d5d" />
    </svg>,

    QnAIconStage: (fillColor) => <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill={fillColor}>
        <g id="QnA_icon" transform="translate(0 0.104)">
            <path id="Path_952" data-name="Path 952" d="M0,0H38V38H0Z" transform="translate(0 -0.104)" fill="none" />
            <path id="Path_953" data-name="Path 953" d="M30.5,8h-3V21.5H8v3A1.5,1.5,0,0,0,9.5,26H26l6,6V9.5A1.5,1.5,0,0,0,30.5,8Zm-6,9V3.5A1.5,1.5,0,0,0,23,2H3.5A1.5,1.5,0,0,0,2,3.5v21l6-6H23A1.5,1.5,0,0,0,24.5,17Z" transform="translate(1.999 1.896)" fill="" />
        </g>
    </svg>,

    QnASvgIconStage: <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
        <g id="QnA_icon" transform="translate(0 0.104)">
            <path id="Path_952" data-name="Path 952" d="M0,0H38V38H0Z" transform="translate(0 -0.104)" fill="none" />
            <path id="Path_953" data-name="Path 953" d="M30.5,8h-3V21.5H8v3A1.5,1.5,0,0,0,9.5,26H26l6,6V9.5A1.5,1.5,0,0,0,30.5,8Zm-6,9V3.5A1.5,1.5,0,0,0,23,2H3.5A1.5,1.5,0,0,0,2,3.5v21l6-6H23A1.5,1.5,0,0,0,24.5,17Z" transform="translate(1.999 1.896)" fill="" />
        </g>
    </svg>,

    EditIconMob: (fillColor) => <svg xmlns="http://www.w3.org/2000/svg" width="19.482" height="19.482" viewBox="0 0 19.482 19.482" fill={fillColor}>
        <path id="edit_profile" d="M4.5,19.92v4.058H8.558L20.527,12.009,16.469,7.951ZM23.666,8.871a1.078,1.078,0,0,0,0-1.526L21.133,4.813a1.078,1.078,0,0,0-1.526,0l-1.98,1.98,4.058,4.058,1.98-1.98Z" transform="translate(-4.5 -4.496)" />
    </svg>,

    LogOutBtnMob: (fillColor) => <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22.001" viewBox="0 0 22 22.001" fill={fillColor}>
        <g id="logout_icon" transform="translate(-286.229 -425.225)">
            <path id="Path_9322" data-name="Path 9322" d="M199.72,117.926l9.107,9.107a1.9,1.9,0,0,1,0,2.68l-9.1,9.1a1.9,1.9,0,0,1-2.682,0l-9.1-9.1a1.9,1.9,0,0,1,0-2.682l9.1-9.1a1.894,1.894,0,0,1,2.68,0Zm4.418,11.035a.929.929,0,0,0-.005-1.33l-4.468-4.389a.757.757,0,1,0-1.071,1.069l2.986,2.978c.147.147.1.265-.113.265h-8.239a.756.756,0,1,0,0,1.512h8.239c.209,0,.259.119.113.266l-2.986,2.977a.757.757,0,1,0,1.071,1.069Z" transform="translate(98.848 307.853)" fillRule="evenodd" />
        </g>
    </svg>,

    CopyIcon: <svg xmlns="http://www.w3.org/2000/svg" width="15.14" height="15.14" viewBox="0 0 15.14 15.14">
        <g id="duplicate_icon" transform="translate(-70)">
            <path id="Path_9395" data-name="Path 9395" d="M70.688,4.129c-.688,0-.688,0-.688.688v9.635c0,.688,0,.688.688.688h9.635c.688,0,.688,0,.688-.688V11.7H79.634v2.065H71.376V5.505h2.065V4.129ZM74.817,0c-.688,0-.688,0-.688.688v9.635c0,.688,0,.688.688.688h9.635c.688,0,.688,0,.688-.688V.688c0-.688,0-.688-.688-.688Z" fill="#fff" />
        </g>
    </svg>,

    StartQnAIcon: <svg xmlns="http://www.w3.org/2000/svg" width="89.545" height="87.947" viewBox="0 0 89.545 87.947">
        <g id="question_answer" transform="translate(-105.007 -35)">
            <path id="Path_9396" data-name="Path 9396" d="M147.749,105H113.434a8.428,8.428,0,0,0-8.427,8.427v24.72a8.428,8.428,0,0,0,8.427,8.427H124.2v11.193a1.6,1.6,0,0,0,1.072,1.5,1.562,1.562,0,0,0,1.775-.512l9.754-12.185h10.954a8.428,8.428,0,0,0,8.427-8.427v-24.72A8.428,8.428,0,0,0,147.749,105Zm-17.158,33.58a1.6,1.6,0,1,1,1.6-1.6A1.6,1.6,0,0,1,130.591,138.58Zm3.07-10.2a2.37,2.37,0,0,0-1.471,2.206,1.6,1.6,0,1,1-3.2,0,5.6,5.6,0,0,1,3.438-5.165,4.8,4.8,0,1,0-6.636-4.429,1.6,1.6,0,1,1-3.2,0,8,8,0,1,1,11.066,7.388Z" transform="translate(0 -57.208)" fill="#5d5d5d" />
            <path id="Path_9397" data-name="Path 9397" d="M348.58,246.867a1.6,1.6,0,0,1-1.249-.6l-9.753-12.193H323.424A8.433,8.433,0,0,1,315,225.652v-2.77a1.6,1.6,0,1,1,3.2,0v2.77a5.23,5.23,0,0,0,5.225,5.225h14.923a1.6,1.6,0,0,1,1.249.6l7.386,9.234v-8.234a1.6,1.6,0,0,1,1.6-1.6h9.167a5.23,5.23,0,0,0,5.225-5.225V200.922a5.231,5.231,0,0,0-4.9-5.215c-.1-.006-.215-.009-.328-.009l-7.565.012-17.592-.012a1.6,1.6,0,0,1,0-3.2h0l17.589.012,7.564-.012c.176,0,.348.006.518.016h0a8.436,8.436,0,0,1,7.907,8.407v24.729a8.433,8.433,0,0,1-8.423,8.423h-7.568v11.193a1.6,1.6,0,0,1-1.6,1.6Z" transform="translate(-171.617 -128.717)" fill="#5d5d5d" />
            <path id="Path_9398" data-name="Path 9398" d="M429.684,248.2H404.1a1.6,1.6,0,1,1,0-3.2h25.585a1.6,1.6,0,1,1,0,3.2Z" transform="translate(-243.127 -171.623)" fill="#5d5d5d" />
            <path id="Path_9399" data-name="Path 9399" d="M429.684,283.2H404.1a1.6,1.6,0,1,1,0-3.2h25.585a1.6,1.6,0,1,1,0,3.2Z" transform="translate(-243.127 -200.227)" fill="#5d5d5d" />
            <path id="Path_9400" data-name="Path 9400" d="M429.684,318.2H404.1a1.6,1.6,0,1,1,0-3.2h25.585a1.6,1.6,0,1,1,0,3.2Z" transform="translate(-243.127 -228.831)" fill="#5d5d5d" />
            <path id="Path_9401" data-name="Path 9401" d="M412.094,353.2h-8a1.6,1.6,0,1,1,0-3.2h8a1.6,1.6,0,1,1,0,3.2Z" transform="translate(-243.127 -257.434)" fill="#5d5d5d" />
            <path id="Path_9402" data-name="Path 9402" d="M456.961,70.069l-4-4a1.611,1.611,0,0,0-2.27,0l-4,4a1.611,1.611,0,0,0,0,2.27l4,4a1.62,1.62,0,0,0,2.27,0l4-4A1.61,1.61,0,0,0,456.961,70.069Zm-5.133,2.878L450.085,71.2l1.743-1.743,1.743,1.743Z" transform="translate(-278.863 -25.011)" fill="#5d5d5d" />
            <path id="Path_9403" data-name="Path 9403" d="M528.2,141.6a1.6,1.6,0,1,1-1.6-1.6,1.6,1.6,0,0,1,1.6,1.6" transform="translate(-343.24 -85.811)" fill="#5d5d5d" />
            <path id="Path_9404" data-name="Path 9404" d="M405.7,36.6a1.6,1.6,0,1,1-1.6-1.6,1.6,1.6,0,0,1,1.6,1.6" transform="translate(-243.127)" fill="#5d5d5d" />
            <path id="Path_9405" data-name="Path 9405" d="M171.048,463.75a4.811,4.811,0,1,0,3.388,1.409,4.8,4.8,0,0,0-3.388-1.409Zm0,6.4a1.6,1.6,0,1,1,1.6-1.6A1.6,1.6,0,0,1,171.048,470.146Z" transform="translate(-50.051 -350.397)" fill="#5d5d5d" />
            <path id="Path_9406" data-name="Path 9406" d="M300.7,439.1a1.6,1.6,0,1,1-1.6-1.6,1.6,1.6,0,0,1,1.6,1.6" transform="translate(-157.315 -328.944)" fill="#5d5d5d" />
            <path id="Path_9407" data-name="Path 9407" d="M125.7,404.1a1.6,1.6,0,1,1-1.6-1.6,1.6,1.6,0,0,1,1.6,1.6" transform="translate(-14.296 -300.34)" fill="#5d5d5d" />
        </g>
    </svg>,

    StartPollIcon: <svg xmlns="http://www.w3.org/2000/svg" width="63.245" height="63.245" viewBox="0 0 63.245 63.245">
        <g id="start_poll" transform="translate(-84.001 -14)">
            <path id="Path_9427" data-name="Path 9427" d="M138.325,14h-45.4A8.921,8.921,0,0,0,84,22.921v45.4a8.921,8.921,0,0,0,8.921,8.921h45.4a8.921,8.921,0,0,0,8.921-8.921v-45.4A8.922,8.922,0,0,0,138.325,14Zm3.662,54.324a3.694,3.694,0,0,1-3.662,3.662h-45.4a3.694,3.694,0,0,1-3.662-3.662v-45.4a3.694,3.694,0,0,1,3.662-3.662h45.4a3.694,3.694,0,0,1,3.662,3.662Z" fill="#5d5d5d" />
            <path id="Path_9428" data-name="Path 9428" d="M209.578,205.52h-5.526a1.335,1.335,0,0,0-1.331,1.331v22.835a1.335,1.335,0,0,0,1.331,1.331h5.526a1.335,1.335,0,0,0,1.331-1.331V206.851a1.336,1.336,0,0,0-1.332-1.331Z" transform="translate(-104.606 -168.752)" fill="#5d5d5d" />
            <path id="Path_9429" data-name="Path 9429" d="M322.7,140h-5.526a1.335,1.335,0,0,0-1.331,1.331v30.624a1.335,1.335,0,0,0,1.331,1.331H322.7a1.335,1.335,0,0,0,1.331-1.331V141.331A1.43,1.43,0,0,0,322.7,140Z" transform="translate(-204.279 -111.021)" fill="#5d5d5d" />
            <path id="Path_9430" data-name="Path 9430" d="M435.257,265.44h-5.525a1.335,1.335,0,0,0-1.331,1.331v15.711a1.335,1.335,0,0,0,1.331,1.331h5.526a1.335,1.335,0,0,0,1.331-1.331V266.771a1.336,1.336,0,0,0-1.332-1.331Z" transform="translate(-303.456 -221.548)" fill="#5d5d5d" />
        </g>
    </svg>,

    LiveStreamIcon: <svg xmlns="http://www.w3.org/2000/svg" width="22.04" height="22.04" viewBox="0 0 22.04 22.04">
        <path id="leave_stream" d="M8.491,3.612,7.759,2.88a.788.788,0,0,0-1.118,0L.232,9.286a.788.788,0,0,0,0,1.118l6.409,6.409a.788.788,0,0,0,1.118,0l.732-.732a.792.792,0,0,0-.013-1.131L4.505,11.165h9.475a.789.789,0,0,0,.791-.791V9.319a.789.789,0,0,0-.791-.791H4.505L8.478,4.742A.786.786,0,0,0,8.491,3.612Z" transform="translate(23.205 12.76) rotate(135)" fill="#fff" stroke="" strokeWidth="1" />
    </svg>,

    SettingIcon: <svg xmlns="http://www.w3.org/2000/svg" width="21.4" height="22" viewBox="0 0 21.4 22">
        <path id="setting_icon" d="M22.281,15.078A8.573,8.573,0,0,0,22.358,14a8.573,8.573,0,0,0-.077-1.078L24.6,11.107a.555.555,0,0,0,.132-.7L22.534,6.6a.553.553,0,0,0-.671-.242l-2.739,1.1a8.037,8.037,0,0,0-1.859-1.078l-.418-2.915A.536.536,0,0,0,16.308,3h-4.4a.536.536,0,0,0-.539.462l-.418,2.915A8.451,8.451,0,0,0,9.092,7.455l-2.739-1.1a.537.537,0,0,0-.671.242L3.482,10.4a.542.542,0,0,0,.132.7l2.321,1.815A8.724,8.724,0,0,0,5.858,14a8.724,8.724,0,0,0,.077,1.078L3.614,16.893a.555.555,0,0,0-.132.7l2.2,3.806a.553.553,0,0,0,.671.242l2.739-1.1a8.037,8.037,0,0,0,1.859,1.078l.418,2.915a.536.536,0,0,0,.539.462h4.4a.536.536,0,0,0,.539-.462l.418-2.915a8.451,8.451,0,0,0,1.859-1.078l2.739,1.1a.537.537,0,0,0,.671-.242l2.2-3.806a.555.555,0,0,0-.132-.7l-2.321-1.815ZM14.108,17.85A3.85,3.85,0,1,1,17.958,14,3.854,3.854,0,0,1,14.108,17.85Z" transform="translate(-3.406 -3)" fill="" />
    </svg>,

    AcceptIcon: <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
        <path id="accept" d="M28.563,14.563a14,14,0,1,1-14-14A14,14,0,0,1,28.563,14.563ZM12.943,21.975,23.33,11.588a.9.9,0,0,0,0-1.277L22.053,9.034a.9.9,0,0,0-1.277,0L12.3,17.5,8.349,13.55a.9.9,0,0,0-1.277,0L5.795,14.827a.9.9,0,0,0,0,1.277l5.871,5.871a.9.9,0,0,0,1.277,0Z" transform="translate(-0.563 -0.563)" fill="#4bd93f" />
    </svg>,

    RejectIcon: <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
        <path id="reject" d="M17.375,3.375a14,14,0,1,0,14,14A14,14,0,0,0,17.375,3.375Zm3.547,19.068L17.375,18.9l-3.547,3.547a1.076,1.076,0,1,1-1.521-1.521l3.547-3.547-3.547-3.547a1.076,1.076,0,0,1,1.521-1.521l3.547,3.547,3.547-3.547a1.076,1.076,0,1,1,1.521,1.521L18.9,17.375l3.547,3.547a1.081,1.081,0,0,1,0,1.521A1.068,1.068,0,0,1,20.922,22.443Z" transform="translate(-3.375 -3.375)" fill="#ed374f" />
    </svg>,

    ShowResultIcon: <svg id="show_result" xmlns="http://www.w3.org/2000/svg" width="24" height="12.836" viewBox="0 0 24 12.836">
        <path id="show_result-2" data-name="show_result" d="M15.653,5.2a1.478,1.478,0,0,1,2.109,2.069L9.9,17.1a1.478,1.478,0,0,1-2.128.039L2.556,11.925A1.478,1.478,0,1,1,4.645,9.836L8.771,13.96l6.842-8.72a.5.5,0,0,1,.039-.043ZM13.84,15.326l1.813,1.813a1.478,1.478,0,0,0,2.126-.039l7.867-9.833A1.478,1.478,0,1,0,23.5,5.24l-6.844,8.72L15.7,13l-1.858,2.323Z" transform="translate(-2.085 -4.736)" fill="#5d5d5d" />
    </svg>,

    HidePollIcon: <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <path d="M8.246 21.924c-3.001-2.544-4.246-5.864-4.246-5.864s3.273-8.727 12-8.727c2.3 0 4.221 0.606 5.803 1.499l3.821-3.69c0.53-0.512 1.374-0.497 1.885 0.033s0.497 1.374-0.033 1.885l-3.472 3.353c2.82 2.512 3.996 5.646 3.996 5.646s-3.273 8.727-12 8.727c-2.155 0-3.977-0.532-5.5-1.333l-4.124 3.983c-0.53 0.512-1.374 0.497-1.885-0.033s-0.497-1.374 0.033-1.885l3.723-3.595zM19.021 11.519c-0.865-0.577-1.904-0.913-3.021-0.913-3.012 0-5.455 2.443-5.455 5.455 0 1.049 0.296 2.029 0.81 2.861l1.633-1.577c-0.168-0.394-0.261-0.828-0.261-1.284 0-1.807 1.465-3.273 3.273-3.273 0.496 0 0.967 0.11 1.388 0.308l1.633-1.577zM20.814 13.495l-1.68 1.622c0.090 0.299 0.138 0.616 0.138 0.944 0 1.807-1.465 3.273-3.273 3.273-0.368 0-0.722-0.061-1.053-0.173l-1.68 1.622c0.803 0.466 1.737 0.733 2.732 0.733 3.012 0 5.455-2.443 5.455-5.455 0-0.928-0.232-1.801-0.64-2.566z"></path>
    </svg>,

    NextBgIcon: <svg xmlns="http://www.w3.org/2000/svg" width="130" height="27" viewBox="0 0 130 27">
        <g id="up_next_arrow" transform="translate(53)">
            <g id="Ellipse_2828" data-name="Ellipse 2828" stroke="#fff" strokeWidth="1">
                <circle cx="13.5" cy="13.5" r="13.5" stroke="none" />
            </g>
            <g id="dropdown_arrow" transform="translate(40 -2.397)" fill="#fff">
                <path d="M 21.29462051391602 19.49187088012695 L 16.0534553527832 14.25946617126465 L 15.70060539245605 13.90721607208252 L 15.34735488891602 14.25905609130859 L 10.09520721435547 19.49018478393555 L 9.706057548522949 19.09870338439941 L 15.7001953125 13.10455703735352 L 21.6922435760498 19.09660339355469 L 21.29462051391602 19.49187088012695 Z" stroke="none" />
                <path d="M 21.29386520385742 20.19763565063477 L 15.7001953125 14.61331653594971 L 10.09343528747559 20.19763565063477 L 9.000005722045898 19.09765625 L 15.7001953125 12.39745616912842 L 22.40039443969727 19.09765625 L 21.29386520385742 20.19763565063477 Z" stroke="none" fill="#fff" />
            </g>
            <text id="Next" transform="translate(-36 18)" fill="#fff" font-size="14" font-family="Poppins-Medium, Poppins" font-weight="500"><tspan x="0" y="0">Next Scene</tspan></text>
        </g>
    </svg>,

    StopPoll: <svg xmlns="http://www.w3.org/2000/svg" width="26.819" height="29.112" viewBox="0 0 26.819 29.112">
        <g id="stop_poll" transform="translate(-108.451 -15.69)">
            <path id="Path_9447" data-name="Path 9447" d="M126.247,24.894H117.4a.74.74,0,0,0-.748.748v9.27a.74.74,0,0,0,.748.748h8.851a.74.74,0,0,0,.748-.748v-9.27A.74.74,0,0,0,126.247,24.894Zm-.748,9.27h-7.356V26.39H125.5Zm9.031-9.719a5.343,5.343,0,0,0-2.452-3.17c-1.256-.748-2.512-1.465-3.768-2.183s-2.512-1.435-3.768-2.183a5.407,5.407,0,0,0-2.512-.718,5.092,5.092,0,0,0-2.811.658c-.718.419-1.435.807-2.123,1.226-1.794,1.017-3.648,2.093-5.472,3.17a5.213,5.213,0,0,0-2.661,4.635c-.03,1.944,0,3.917,0,5.831v2.9a4.992,4.992,0,0,0,.239,1.555,5.5,5.5,0,0,0,2.422,3.08c1.226.718,2.482,1.435,3.738,2.153s2.512,1.435,3.738,2.153a5.233,5.233,0,0,0,2.422.748h.419a5.123,5.123,0,0,0,2.6-.688c2.452-1.435,4.994-2.931,7.506-4.366a4.977,4.977,0,0,0,1.944-1.914,4.892,4.892,0,0,0,.777-2.542V25.851a6.259,6.259,0,0,0-.239-1.405Zm-1.286,10.406a3.436,3.436,0,0,1-.6,1.794,3.386,3.386,0,0,1-1.376,1.376c-2.542,1.435-5.084,2.931-7.536,4.366a3.891,3.891,0,0,1-2.153.479,3.965,3.965,0,0,1-1.764-.568c-1.256-.718-2.482-1.435-3.738-2.153s-2.482-1.435-3.738-2.153a3.837,3.837,0,0,1-1.764-2.273,2.9,2.9,0,0,1-.179-1.077V25.912a3.693,3.693,0,0,1,1.944-3.379c1.794-1.077,3.648-2.123,5.442-3.17.718-.419,1.435-.807,2.153-1.226a3.323,3.323,0,0,1,1.824-.479h.179a3.922,3.922,0,0,1,1.824.538c1.256.748,2.512,1.465,3.768,2.183s2.512,1.435,3.768,2.183a3.882,3.882,0,0,1,1.764,2.332,3.627,3.627,0,0,1,.15,1.017v8.941Z" fill="#5d5d5d" stroke="#5d5d5d" strokeWidth="1" />
        </g>
    </svg>,

    MoveArchived: <svg xmlns="http://www.w3.org/2000/svg" width="24.934" height="26.363" viewBox="0 0 24.934 26.363">
        <g id="archived_icon" transform="translate(-161.901 -80.699)">
            <path id="Path_9442" data-name="Path 9442" d="M178.476,81.2H170.26a2.858,2.858,0,0,0-2.858,2.858v1.072a2.858,2.858,0,0,0-2.858,2.858v1.372A3.573,3.573,0,0,0,162.4,92.63v10.36a3.572,3.572,0,0,0,3.572,3.572h16.79a3.572,3.572,0,0,0,3.572-3.572V92.63a3.573,3.573,0,0,0-2.143-3.272V87.986a2.858,2.858,0,0,0-2.858-2.858V84.057a2.858,2.858,0,0,0-2.858-2.858Zm6.43,11.431v10.36a2.143,2.143,0,0,1-2.143,2.143h-16.79a2.143,2.143,0,0,1-2.143-2.143V92.63a2.143,2.143,0,0,1,2.143-2.143h16.79a2.143,2.143,0,0,1,2.143,2.143Zm-2.143-4.644v1.072h-16.79V87.986h0a1.429,1.429,0,0,1,1.429-1.429h13.932a1.429,1.429,0,0,1,1.429,1.429Zm-13.932-2.858V84.057h0a1.429,1.429,0,0,1,1.429-1.429h8.216a1.429,1.429,0,0,1,1.429,1.429v1.072Z" fill="#5d5d5d" stroke="#5d5d5d" strokeWidth="1" />
            <path id="Path_9443" data-name="Path 9443" d="M293.1,305.2h-6.787a.715.715,0,0,0,0,1.429H293.1a.715.715,0,0,0,0-1.429Z" transform="translate(-115.341 -209.712)" fill="#5d5d5d" stroke="#5d5d5d" strokeWidth="1" />
        </g>
    </svg>,

    GoogleCalender: <svg id="Google" xmlns="http://www.w3.org/2000/svg" width="23.637" height="24.152" viewBox="0 0 23.637 24.152">
        <rect id="Rectangle_3780" data-name="Rectangle 3780" width="23.407" height="23.406" transform="translate(0.115 0.373)" fill="none" />
        <path id="Path_1050" data-name="Path 1050" d="M6.313,41a7.139,7.139,0,0,1,.363-2.242l-4.07-3.108a12.159,12.159,0,0,0,0,10.7L6.672,43.23A7.144,7.144,0,0,1,6.313,41" transform="translate(-1.366 -28.922)" fill="#fbbc05" fillRule="evenodd" />
        <path id="Path_1051" data-name="Path 1051" d="M18.791,4.94a7,7,0,0,1,4.452,1.592l3.518-3.513A12.086,12.086,0,0,0,7.938,6.726l4.07,3.108A7.127,7.127,0,0,1,18.791,4.94" transform="translate(-6.698 0)" fill="#ea4335" fillRule="evenodd" />
        <path id="Path_1052" data-name="Path 1052" d="M18.791,80.775a7.127,7.127,0,0,1-6.783-4.894l-4.07,3.107a12.057,12.057,0,0,0,10.853,6.727,11.524,11.524,0,0,0,7.879-3.01l-3.863-2.987a7.481,7.481,0,0,1-4.016,1.056" transform="translate(-6.698 -61.563)" fill="#34a853" fillRule="evenodd" />
        <path id="Path_1053" data-name="Path 1053" d="M77,54.56a10,10,0,0,0-.275-2.2H65.457V57.03h6.486a5.438,5.438,0,0,1-2.47,3.61l3.863,2.987A12.01,12.01,0,0,0,77,54.56" transform="translate(-53.364 -42.484)" fill="#4285f4" fillRule="evenodd" />
    </svg>,

    OutlookCalender: <svg id="outlook_calender" xmlns="http://www.w3.org/2000/svg" width="28.088" height="28.088" viewBox="0 0 28.088 28.088">
        <path id="Path_1047" data-name="Path 1047" d="M47.6,22.1H57.805a1.03,1.03,0,0,1,1.03,1.03V36.518a1.03,1.03,0,0,1-1.03,1.03H47.6Z" transform="translate(-30.747 -15.78)" fill="#1976d2" />
        <rect id="Rectangle_3764" data-name="Rectangle 3764" width="9.831" height="10.913" transform="translate(16.853 9.801)" fill="#fff" />
        <path id="Path_1048" data-name="Path 1048" d="M22.951,34.888,6.8,31.728V9.96L22.951,6.8Z" transform="translate(-6.8 -6.8)" fill="#1976d2" />
        <path id="Path_1049" data-name="Path 1049" d="M20.188,28.05c-2.23,0-4.038,2.358-4.038,5.266s1.807,5.266,4.038,5.266,4.038-2.358,4.038-5.266S22.418,28.05,20.188,28.05Zm-.176,8.426c-1.164,0-2.107-1.415-2.107-3.16s.943-3.16,2.107-3.16,2.107,1.415,2.107,3.16S21.176,36.476,20.012,36.476Z" transform="translate(-12.288 -19.273)" fill="#fff" />
        <rect id="Rectangle_3765" data-name="Rectangle 3765" width="1.896" height="2.036" transform="translate(16.886 18.072)" fill="#1976d2" />
        <rect id="Rectangle_3766" data-name="Rectangle 3766" width="1.896" height="2.036" transform="translate(19.274 18.072)" fill="#1976d2" />
        <rect id="Rectangle_3767" data-name="Rectangle 3767" width="1.896" height="2.036" transform="translate(21.662 18.072)" fill="#1976d2" />
        <rect id="Rectangle_3768" data-name="Rectangle 3768" width="1.896" height="2.036" transform="translate(16.886 15.56)" fill="#1976d2" />
        <rect id="Rectangle_3769" data-name="Rectangle 3769" width="1.896" height="2.036" transform="translate(19.274 15.56)" fill="#1976d2" />
        <rect id="Rectangle_3770" data-name="Rectangle 3770" width="1.896" height="2.036" transform="translate(21.662 15.56)" fill="#1976d2" />
        <rect id="Rectangle_3771" data-name="Rectangle 3771" width="1.896" height="2.036" transform="translate(24.05 15.56)" fill="#1976d2" />
        <rect id="Rectangle_3772" data-name="Rectangle 3772" width="1.896" height="2.036" transform="translate(16.886 13.135)" fill="#1976d2" />
        <rect id="Rectangle_3773" data-name="Rectangle 3773" width="1.896" height="2.036" transform="translate(19.274 13.135)" fill="#1976d2" />
        <rect id="Rectangle_3774" data-name="Rectangle 3774" width="1.896" height="2.036" transform="translate(21.662 13.135)" fill="#1976d2" />
        <rect id="Rectangle_3775" data-name="Rectangle 3775" width="1.896" height="2.036" transform="translate(24.05 13.135)" fill="#1976d2" />
        <rect id="Rectangle_3776" data-name="Rectangle 3776" width="1.896" height="2.036" transform="translate(19.274 10.612)" fill="#1976d2" />
        <rect id="Rectangle_3777" data-name="Rectangle 3777" width="1.896" height="2.036" transform="translate(21.662 10.612)" fill="#1976d2" />
        <rect id="Rectangle_3778" data-name="Rectangle 3778" width="1.896" height="2.036" transform="translate(24.05 10.612)" fill="#1976d2" />
    </svg>,

    AppleCalender: <svg id="apple" xmlns="http://www.w3.org/2000/svg" width="21.067" height="24.963" viewBox="0 0 21.067 24.963">
        <path id="Path_1054" data-name="Path 1054" d="M23.03,19.365V19.2a5.351,5.351,0,0,1-3.158-5.014,5.414,5.414,0,0,1,2.36-4.57l.069-.034a5.672,5.672,0,0,0-4.443-2.455c-1.457,0-2.672,1.4-4.685,1.4-1.6,0-2.256-1.33-3.992-1.33A7.415,7.415,0,0,0,2,14.489a18.967,18.967,0,0,0,2.36,8.493C5.539,25.13,7,26.528,8.628,26.528c1.18,0,2.568-1.057,4.234-1.057,1.457,0,2.812.956,4.13.956,3.159,0,6.074-4.024,6.074-6.718C23.065,19.571,23.03,19.468,23.03,19.365Z" transform="translate(-1.998 -1.566)" fill="#282b3c" fillRule="evenodd" />
        <path id="Path_1055" data-name="Path 1055" d="M16.2,6.514c2.36-.716,4.547-2.456,4.547-5.491,0-.239-.034-.545-.069-.886L20.643,0C18.249.647,16.1,2.489,16.1,5.388a5.7,5.7,0,0,0,.1.989v.137Z" transform="translate(-5.097 0)" fill="#282b3c" fillRule="evenodd" />
    </svg>,

    MobFilter: (fillColor) => <svg xmlns="http://www.w3.org/2000/svg" width="16.368" height="16.368" viewBox="0 0 16.368 16.368" fill={fillColor}>
        <path id="filter_icon" d="M186.642,112.514a.824.824,0,0,1-.179.9l-5.7,5.7a.945.945,0,0,0-.192.464v7.971a.826.826,0,0,1-1.41.584l-2.453-2.453a.825.825,0,0,1-.242-.584v-5.518a.939.939,0,0,0-.192-.463l-5.7-5.7a.826.826,0,0,1,.584-1.409h14.717a.824.824,0,0,1,.763.51Z" transform="translate(-170.337 -112.004)" />
    </svg>,

    DotsFilter: <svg xmlns="http://www.w3.org/2000/svg" width="6" height="22" viewBox="0 0 6 22">
        <g id="dot_icon" transform="translate(-236 -93)">
            <rect id="Rectangle_3890" data-name="Rectangle 3890" width="6" height="6" rx="3" transform="translate(236 93)" fill="#333" />
            <rect id="Rectangle_3891" data-name="Rectangle 3891" width="6" height="6" rx="3" transform="translate(236 101)" fill="#333" />
            <rect id="Rectangle_3892" data-name="Rectangle 3892" width="6" height="6" rx="3" transform="translate(236 109)" fill="#333" />
        </g>
    </svg>,

    PDFIcon: <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
        <g id="pdf_icon" transform="translate(-22 -633)">
            <path id="Path_9539" data-name="Path 9539" d="M0,0H42V42H0Z" transform="translate(22 633)" fill="none" />
            <path id="noun-pdf-2956524" d="M140.911,11.645a.918.918,0,0,0-.919.918V50.749a.918.918,0,0,0,.919.918h29.483a.918.918,0,0,0,.919-.918V24.778H159.1a.919.919,0,0,1-.919-.918l.024-12.215Zm19.138.566-.027,10.73h10.751Zm-6.523,15.346a2.236,2.236,0,0,1,1.7.887A4.837,4.837,0,0,1,155.964,31a20.682,20.682,0,0,1-.416,3.189,23.294,23.294,0,0,0,1.828,2.369,23.308,23.308,0,0,0,2.369,1.828,20.773,20.773,0,0,1,3.188-.425,4.825,4.825,0,0,1,2.554.741,2.273,2.273,0,0,1-1.087,4.059,4.824,4.824,0,0,1-2.579-.644,20.765,20.765,0,0,1-2.554-1.957,23.318,23.318,0,0,0-2.967.4,23.175,23.175,0,0,0-2.768,1.136,20.784,20.784,0,0,1-1.23,2.975,4.82,4.82,0,0,1-1.912,1.845,2.278,2.278,0,0,1-2.979-2.973,4.842,4.842,0,0,1,1.852-1.913,20.705,20.705,0,0,1,2.97-1.23,23.226,23.226,0,0,0,1.137-2.768,23.385,23.385,0,0,0,.4-2.967,20.722,20.722,0,0,1-1.957-2.551,4.836,4.836,0,0,1-.641-2.585,2.273,2.273,0,0,1,2.355-1.976Zm-.008,1.682c-.057.009-.132.029-.233.056-.4.108-.374.118-.4.408a3.71,3.71,0,0,0,.472,1.655c.133.271.547.651.731.95.01-.352.183-.89.163-1.193a3.718,3.718,0,0,0-.415-1.668c-.127-.178-.145-.235-.316-.207Zm1.715,7.327c-.116.535-.057.973-.2,1.509s-.415.892-.583,1.415c.522-.167.87-.442,1.407-.586s.978-.083,1.514-.2c-.407-.369-.818-.535-1.212-.928s-.558-.806-.926-1.211Zm7.585,3.116c-.3-.02-.839.15-1.191.16.3.185.682.6.955.739a3.685,3.685,0,0,0,1.65.471c.29-.028.3.006.408-.4s.087-.383-.149-.552a3.722,3.722,0,0,0-1.672-.42ZM151.332,42.6c-.311.168-.86.291-1.113.461a3.713,3.713,0,0,0-1.237,1.2c-.12.265-.154.253.141.549s.287.263.552.143a3.7,3.7,0,0,0,1.2-1.235c.169-.253.289-.8.457-1.115Z" transform="translate(-112.992 622.355)" fill="#adadad" />
        </g>
    </svg>,

    recordingIcon: <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
        <g id="recording_icon" transform="translate(-165 -128)">
            <g id="Rectangle_3891" data-name="Rectangle 3891" transform="translate(165 128)" fill="none" stroke="#fff" strokeWidth="1">
                <rect width="17" height="17" rx="8.5" stroke="none"></rect>
                <rect x="0.5" y="0.5" width="16" height="16" rx="8" fill="none"></rect>
            </g>
            <rect id="Rectangle_3892" data-name="Rectangle 3892" width="11" height="11" rx="5.5" transform="translate(168 131)" fill="#fff"></rect>
        </g>
    </svg>,

    InstagramSocialIcon: <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
        <g id="insta" transform="translate(-3.7 -5.2)">
            <circle id="Ellipse_2834" data-name="Ellipse 2834" cx="3.041" cy="3.041" r="3.041" transform="translate(17.521 19.297)" />
            <path id="Path_9540" data-name="Path 9540" d="M28.564,19.2h-7.6a3.776,3.776,0,0,0-2.7.967,3.776,3.776,0,0,0-.967,2.7v7.6a3.809,3.809,0,0,0,1.037,2.764,3.9,3.9,0,0,0,2.7.967h7.532a3.776,3.776,0,0,0,2.7-.967,3.54,3.54,0,0,0,1.037-2.7v-7.6a3.9,3.9,0,0,0-.967-2.7A3.7,3.7,0,0,0,28.564,19.2Zm-3.8,12.162a4.7,4.7,0,0,1,0-9.4,4.7,4.7,0,1,1,0,9.4Zm4.906-8.5a1.106,1.106,0,1,1,1.106-1.106A1.092,1.092,0,0,1,29.67,22.863Z" transform="translate(-4.202 -4.325)" />
            <path id="Path_9541" data-name="Path 9541" d="M20.7,5.2a17,17,0,1,0,17,17A16.988,16.988,0,0,0,20.7,5.2Zm9.053,21.008a5.478,5.478,0,0,1-1.52,3.939,5.378,5.378,0,0,1-3.87,1.451H16.83a5.378,5.378,0,0,1-3.87-1.451,5.207,5.207,0,0,1-1.52-3.939v-7.6a5.114,5.114,0,0,1,5.39-5.39h7.6a5.1,5.1,0,0,1,5.321,5.39v7.6Z" transform="translate(0 0)" />
        </g>
    </svg>,
    FacebookSocialIcon: <svg xmlns="http://www.w3.org/2000/svg" width="34" height="33.999" viewBox="0 0 34 33.999">
        <path id="facebook" d="M20.722,5.157a17,17,0,1,0,17,17A17,17,0,0,0,20.722,5.157Zm4.5,16.929H22.277v10.5H17.914v-10.5H15.839V18.379h2.075v-2.4a4.091,4.091,0,0,1,4.4-4.4l3.231.012v3.6H23.2a.887.887,0,0,0-.925,1.009V18.38H25.6Z" transform="translate(-3.722 -5.157)" />
    </svg>,

    TwitterSocialIcon: <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
        <path id="twitter" d="M17,0A17,17,0,1,1,0,17,17.008,17.008,0,0,1,17,0ZM14.006,26.042A11.558,11.558,0,0,0,25.642,14.406c0-.178,0-.356-.008-.526a8.378,8.378,0,0,0,2.044-2.12,8.3,8.3,0,0,1-2.349.645,4.087,4.087,0,0,0,1.8-2.264,8.283,8.283,0,0,1-2.6.992,4.092,4.092,0,0,0-7.073,2.8,3.749,3.749,0,0,0,.11.933,11.6,11.6,0,0,1-8.43-4.274,4.087,4.087,0,0,0,1.272,5.453,4.017,4.017,0,0,1-1.849-.509v.051A4.094,4.094,0,0,0,11.843,19.6a4.079,4.079,0,0,1-1.077.144,3.9,3.9,0,0,1-.772-.076,4.083,4.083,0,0,0,3.816,2.841,8.22,8.22,0,0,1-5.08,1.747,7.443,7.443,0,0,1-.975-.059,11.394,11.394,0,0,0,6.25,1.849Z" fillRule="evenodd" />
    </svg>,

    LinkedInSocialIcon: <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
        <path id="linkedin" d="M17,0A17,17,0,1,0,34,17,17,17,0,0,0,17,0ZM12.181,25.411H8.392V13.524h3.789ZM10.287,11.967a2.132,2.132,0,1,1,2.132-2.132,2.132,2.132,0,0,1-2.132,2.132ZM25.845,25.411H22.127v-6.5c0-.33.052-2.52-1.808-2.52a2.055,2.055,0,0,0-1.705.835,2.6,2.6,0,0,0-.519,1.58v6.6H14.38V13.524h3.534v1.668a4.291,4.291,0,0,1,7.931,2.213Z" />
    </svg>,

    YouTubeSocial: <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
        <path id="youtube" d="M17,0A17,17,0,1,1,0,17,17.008,17.008,0,0,1,17,0ZM27.181,11.885A2.661,2.661,0,0,0,25.3,10.007c-1.657-.444-8.3-.444-8.3-.444s-6.645,0-8.3.444a2.661,2.661,0,0,0-1.879,1.879A27.662,27.662,0,0,0,6.375,17a27.659,27.659,0,0,0,.444,5.115A2.662,2.662,0,0,0,8.7,23.994c1.657.444,8.3.444,8.3.444s6.645,0,8.3-.444a2.661,2.661,0,0,0,1.879-1.879A27.659,27.659,0,0,0,27.625,17a27.662,27.662,0,0,0-.444-5.115Zm-12.306,8.3V13.813L20.4,17l-5.521,3.188Z" fillRule="evenodd" />
    </svg>,

    TelegramSocialIcon: <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
        <path id="telegram" d="M17,0A17,17,0,1,0,34,17,17,17,0,0,0,17,0Zm8.836,10.754L22.817,25.168a1.063,1.063,0,0,1-1.672.637L16.753,22.56l-2.661,2.51a.361.361,0,0,1-.514-.02l-.057-.063.462-4.474L22.316,12.9a.223.223,0,0,0-.27-.353L11.39,19.292,6.8,17.751a.624.624,0,0,1-.021-1.175L24.736,9.811a.826.826,0,0,1,1.1.942Z" />
    </svg>,

    TagsIcon: <svg xmlns="http://www.w3.org/2000/svg" width="13.396" height="9.562" viewBox="0 0 13.396 9.562">
        <path id="tag_check" d="M17.275,9,9.523,16.751,6,13.228" transform="translate(-4.939 -7.939)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" strokeWidth="1.5" />
    </svg>,

    SideChatIcon: (fillColor) => <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill={fillColor}>
        <g id="chat_icon" transform="translate(1 6)">
            <g id="Rectangle_4002" data-name="Rectangle 4002" transform="translate(-3.5 -6)" stroke="#707070" strokeWidth="1" opacity="0">
                <rect width="38" height="38" stroke="none" />
                <rect x="0.5" y="0.5" width="38" height="38" fill="none" />
            </g>
            <path id="chat_icon-2" data-name="chat_icon" d="M25.1,2H4.567A2.563,2.563,0,0,0,2.013,4.567L2,27.667l5.133-5.133H25.1a2.574,2.574,0,0,0,2.567-2.567V4.567A2.574,2.574,0,0,0,25.1,2ZM7.133,10.983h15.4V13.55H7.133ZM17.4,17.4H7.133V14.833H17.4Zm5.133-7.7H7.133V7.133h15.4Z" transform="translate(0.5 -2)" />
        </g>
    </svg>,

    InstagramLobbyIcon: <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
        <g id="insta" transform="translate(-3.7 -5.2)">
            <circle id="Ellipse_2834" data-name="Ellipse 2834" cx="3.041" cy="3.041" r="3.041" transform="translate(17.521 19.297)" />
            <path id="Path_9540" data-name="Path 9540" d="M28.564,19.2h-7.6a3.776,3.776,0,0,0-2.7.967,3.776,3.776,0,0,0-.967,2.7v7.6a3.809,3.809,0,0,0,1.037,2.764,3.9,3.9,0,0,0,2.7.967h7.532a3.776,3.776,0,0,0,2.7-.967,3.54,3.54,0,0,0,1.037-2.7v-7.6a3.9,3.9,0,0,0-.967-2.7A3.7,3.7,0,0,0,28.564,19.2Zm-3.8,12.162a4.7,4.7,0,0,1,0-9.4,4.7,4.7,0,1,1,0,9.4Zm4.906-8.5a1.106,1.106,0,1,1,1.106-1.106A1.092,1.092,0,0,1,29.67,22.863Z" transform="translate(-4.202 -4.325)" />
            <path id="Path_9541" data-name="Path 9541" d="M20.7,5.2a17,17,0,1,0,17,17A16.988,16.988,0,0,0,20.7,5.2Zm9.053,21.008a5.478,5.478,0,0,1-1.52,3.939,5.378,5.378,0,0,1-3.87,1.451H16.83a5.378,5.378,0,0,1-3.87-1.451,5.207,5.207,0,0,1-1.52-3.939v-7.6a5.114,5.114,0,0,1,5.39-5.39h7.6a5.1,5.1,0,0,1,5.321,5.39v7.6Z" transform="translate(0 0)" />
        </g>
    </svg>,

    FacebookLobbyIcon: <svg xmlns="http://www.w3.org/2000/svg" width="34" height="33.999" viewBox="0 0 34 33.999">
        <path id="facebook" d="M20.722,5.157a17,17,0,1,0,17,17A17,17,0,0,0,20.722,5.157Zm4.5,16.929H22.277v10.5H17.914v-10.5H15.839V18.379h2.075v-2.4a4.091,4.091,0,0,1,4.4-4.4l3.231.012v3.6H23.2a.887.887,0,0,0-.925,1.009V18.38H25.6Z" transform="translate(-3.722 -5.157)" />
    </svg>,

    TwitterLobbyIcon: <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
        <path id="twitter" d="M17,0A17,17,0,1,1,0,17,17.008,17.008,0,0,1,17,0ZM14.006,26.042A11.558,11.558,0,0,0,25.642,14.406c0-.178,0-.356-.008-.526a8.378,8.378,0,0,0,2.044-2.12,8.3,8.3,0,0,1-2.349.645,4.087,4.087,0,0,0,1.8-2.264,8.283,8.283,0,0,1-2.6.992,4.092,4.092,0,0,0-7.073,2.8,3.749,3.749,0,0,0,.11.933,11.6,11.6,0,0,1-8.43-4.274,4.087,4.087,0,0,0,1.272,5.453,4.017,4.017,0,0,1-1.849-.509v.051A4.094,4.094,0,0,0,11.843,19.6a4.079,4.079,0,0,1-1.077.144,3.9,3.9,0,0,1-.772-.076,4.083,4.083,0,0,0,3.816,2.841,8.22,8.22,0,0,1-5.08,1.747,7.443,7.443,0,0,1-.975-.059,11.394,11.394,0,0,0,6.25,1.849Z" fillRule="evenodd" />
    </svg>,

    LinkedInLobbyIcon: <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
        <path id="linkedin" d="M17,0A17,17,0,1,0,34,17,17,17,0,0,0,17,0ZM12.181,25.411H8.392V13.524h3.789ZM10.287,11.967a2.132,2.132,0,1,1,2.132-2.132,2.132,2.132,0,0,1-2.132,2.132ZM25.845,25.411H22.127v-6.5c0-.33.052-2.52-1.808-2.52a2.055,2.055,0,0,0-1.705.835,2.6,2.6,0,0,0-.519,1.58v6.6H14.38V13.524h3.534v1.668a4.291,4.291,0,0,1,7.931,2.213Z" />
    </svg>,

    YouTubeLobby: <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
        <path id="youtube" d="M17,0A17,17,0,1,1,0,17,17.008,17.008,0,0,1,17,0ZM27.181,11.885A2.661,2.661,0,0,0,25.3,10.007c-1.657-.444-8.3-.444-8.3-.444s-6.645,0-8.3.444a2.661,2.661,0,0,0-1.879,1.879A27.662,27.662,0,0,0,6.375,17a27.659,27.659,0,0,0,.444,5.115A2.662,2.662,0,0,0,8.7,23.994c1.657.444,8.3.444,8.3.444s6.645,0,8.3-.444a2.661,2.661,0,0,0,1.879-1.879A27.659,27.659,0,0,0,27.625,17a27.662,27.662,0,0,0-.444-5.115Zm-12.306,8.3V13.813L20.4,17l-5.521,3.188Z" fillRule="evenodd" />
    </svg>,

    TelegramLobbyIcon: <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
        <path id="telegram" d="M17,0A17,17,0,1,0,34,17,17,17,0,0,0,17,0Zm8.836,10.754L22.817,25.168a1.063,1.063,0,0,1-1.672.637L16.753,22.56l-2.661,2.51a.361.361,0,0,1-.514-.02l-.057-.063.462-4.474L22.316,12.9a.223.223,0,0,0-.27-.353L11.39,19.292,6.8,17.751a.624.624,0,0,1-.021-1.175L24.736,9.811a.826.826,0,0,1,1.1.942Z" />
    </svg>,

    SideInfoIcon: (fillColor) => <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill={fillColor}>
        <g id="about_icon" transform="translate(-95.069 -26.163)">
            <path id="Path_9371" data-name="Path 9371" d="M0,0H28V28H0Z" transform="translate(95.069 26.163)" fill="none" />
            <path id="Path_9370" data-name="Path 9370" d="M109.646,28a11.645,11.645,0,1,0,5.822,1.56A11.645,11.645,0,0,0,109.646,28ZM109.6,47.144c-1.277.617-3.25.086-2.906-1.2L108.558,39c.651-2.431-1.14-1.634-1.942-1.281A4.955,4.955,0,0,1,108.583,36c1.277-.617,3.25-.086,2.906,1.2l-1.862,6.949c-.651,2.431,1.14,1.634,1.942,1.281a4.958,4.958,0,0,1-1.967,1.716Zm1.725-12.56h0a1.349,1.349,0,1,1,.954-.4,1.35,1.35,0,0,1-.954.4Z" transform="translate(-0.932 0.164)" fill="" />
        </g>
    </svg>,

    SideFileIcon: (fillColor) => <svg xmlns="http://www.w3.org/2000/svg" width="21.897" height="25.025" viewBox="0 0 21.897 25.025" fill={fillColor}>
        <g id="fies_icon" transform="translate(-105)">
            <path id="Path_9627" data-name="Path 9627" d="M106.563,21.9H122.2a1.564,1.564,0,0,0,1.565-1.564V1.564A1.564,1.564,0,0,0,122.2,0H111.255L105,6.256V20.333a1.563,1.563,0,0,0,1.563,1.564Zm1.564-15.641h3.129V3.128l1.564-1.564V7.82h-6.257Z" transform="translate(0)" fill="" />
            <path id="Path_9628" data-name="Path 9628" d="M192.186,70V90.333H174.98a1.564,1.564,0,0,0,1.564,1.564h15.641a1.564,1.564,0,0,0,1.565-1.564V71.564c0-.864,0-1.564-1.564-1.564Z" transform="translate(-66.853 -66.872)" fill="" />
        </g>
    </svg>,

    MobSideFileIcon: (fillColor) => <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill={fillColor}>
        <g id="fies_icon" transform="translate(-101.948 0.108)">
            <path id="Path_9629" data-name="Path 9629" d="M0,0H28V28H0Z" transform="translate(101.948 -0.108)" fill="none" />
            <g id="Group_1804" data-name="Group 1804" transform="translate(0.948 2.892)">
                <path id="Path_9627" data-name="Path 9627" d="M106.428,20h14.286a1.429,1.429,0,0,0,1.429-1.429V1.429A1.429,1.429,0,0,0,120.714,0h-10L105,5.714V18.571A1.428,1.428,0,0,0,106.428,20Zm1.429-14.286h2.858V2.857l1.429-1.429V7.143h-5.715Z" />
                <path id="Path_9628" data-name="Path 9628" d="M190.7,70V88.572H174.98A1.428,1.428,0,0,0,176.409,90h14.286a1.429,1.429,0,0,0,1.429-1.429V71.429c0-.789,0-1.429-1.429-1.429Z" transform="translate(-67.124 -67.143)" />
            </g>
        </g>
    </svg>,

    SideVisitorIcon: (fillColor) => <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill={fillColor}>
        <g id="visitor_icon_sel" transform="translate(-101.948 0.108)">
            <path id="Path_9630" data-name="Path 9630" d="M0,0H28V28H0Z" transform="translate(101.948 -0.108)" fill="none" />
            <g id="noun-users-1280440" transform="translate(22.616 -106.176)">
                <path id="Path_9631" data-name="Path 9631" d="M350.93,124.441a5.02,5.02,0,0,0,.483.193,9.833,9.833,0,0,1,2.7,1.448h10.766c0-3.814-5.021-1.883-5.841-5.841a5.641,5.641,0,0,0,3.331-1.931c-2.221-1.4-.29-7.241-4.441-7.241a2.564,2.564,0,0,0-1.931.821c-3.379.724-.821,5.021-3.041,6.42a5.55,5.55,0,0,0,3.331,1.931c-.724,3.186-4.1,2.559-5.359,4.2Z" transform="translate(-257.55)" fill="" />
                <path id="Path_9632" data-name="Path 9632" d="M85.27,120.395c-1.014.676-1.014,2.317-.676,3.331a2.887,2.887,0,0,0,.676,2.317,4.6,4.6,0,0,0,1.014,2.655c0,4.635-6.952,2.269-6.952,7.917H97.194c0-5.648-6.952-3.283-6.952-7.917a5.007,5.007,0,0,0,1.014-2.655,2.886,2.886,0,0,0,.676-2.317c1.014-1.979-1.014-2.993-.338-4.972-2.559-1.207-5.648-.772-6.324,1.641Z" transform="translate(0 -6.671)" fill="" />
            </g>
        </g>
    </svg>,

    StagePeople: (fillColor) => <svg id="people_icon" xmlns="http://www.w3.org/2000/svg" width="30.026" height="26.583" viewBox="0 0 30.026 26.583" fill={fillColor}>
        <circle id="Ellipse_57" data-name="Ellipse 57" cx="5.958" cy="5.958" r="5.958" transform="translate(6.417)" />
        <path id="Path_936" data-name="Path 936" d="M14.536,14.013C10.437,13.862,2,15.9,2,20v3H16.323A8.932,8.932,0,0,1,14.536,14.013Z" transform="translate(-2 0.575)" />
        <path id="Path_937" data-name="Path 937" d="M23.155,21.038a5.8,5.8,0,0,0,.856-3.033,6.005,6.005,0,1,0-6.005,6.005,5.943,5.943,0,0,0,3.033-.856L24.9,27.013,27.013,24.9Zm-5.15-.03a3,3,0,1,1,3-3A3.011,3.011,0,0,1,18.005,21.008Z" transform="translate(3.013 -0.43)" />
    </svg>,

    SearchIcon: <svg id="search_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path id="Path_938" data-name="Path 938" d="M0,0H24V24H0Z" fill="none" />
        <path id="Path_939" data-name="Path 939" d="M15.5,14h-.79l-.28-.27a6.51,6.51,0,1,0-.7.7l.27.28v.79l5,4.99L20.49,19Zm-6,0A4.5,4.5,0,1,1,14,9.5,4.494,4.494,0,0,1,9.5,14Z" />
    </svg>,

    MicroSocial: (fillColor) => <svg xmlns="http://www.w3.org/2000/svg" width="17.638" height="20.31" viewBox="0 0 17.638 20.31" fill={fillColor}>
        <g id="share_icon" transform="translate(0 0)">
            <path id="Path_9457" data-name="Path 9457" d="M179.632,67.2a3.217,3.217,0,0,0-2.981,4.4l-6.1,3.382a3.2,3.2,0,1,0,.008,4.743l6.1,3.382a3.162,3.162,0,0,0-.234,1.194,3.219,3.219,0,1,0,.793-2.1l-5.988-3.324a3.2,3.2,0,0,0,0-3.04l5.988-3.324a3.2,3.2,0,1,0,2.413-5.311Z" transform="translate(-165.202 -67.203)" fill={fillColor} />
        </g>
    </svg>,

    FacebookSocial: <svg id="facebook" xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 58 58">
        <path id="Path_9444" data-name="Path 9444" d="M29.026,58A29,29,0,1,0,0,29.026,29.073,29.073,0,0,0,29.026,58Z" fill="#3a5ba2" fillRule="evenodd" />
        <path id="Path_9445" data-name="Path 9445" d="M181.4,103.383a7.236,7.236,0,0,1,7.243-7.244h5.446v7.244h-3.648a1.821,1.821,0,0,0-1.8,1.8v3.647h5.446v7.243h-5.446v16.337H181.4V116.071h-5.445v-7.243H181.4Z" transform="translate(-156.025 -85.248)" fill="#fff" fillRule="evenodd" />
    </svg>,

    TwitterSocial: <svg id="twitter" xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 58 58">
        <circle id="Ellipse_6" data-name="Ellipse 6" cx="29" cy="29" r="29" fill="#55acee" />
        <path id="Path_9446" data-name="Path 9446" d="M42.273,11.433A14.985,14.985,0,0,1,38,12.586,7.336,7.336,0,0,0,41.272,8.54a15.068,15.068,0,0,1-4.727,1.774A7.492,7.492,0,0,0,31.115,8a7.378,7.378,0,0,0-7.44,7.321A7.526,7.526,0,0,0,23.863,17,21.276,21.276,0,0,1,8.528,9.345a7.191,7.191,0,0,0-1.013,3.681,7.31,7.31,0,0,0,3.313,6.1A7.528,7.528,0,0,1,7.46,18.2c0,.031,0,.06,0,.092a7.361,7.361,0,0,0,5.969,7.183,7.723,7.723,0,0,1-1.961.256,7.562,7.562,0,0,1-1.4-.129,7.442,7.442,0,0,0,6.949,5.086,15.086,15.086,0,0,1-9.242,3.136A14.715,14.715,0,0,1,6,33.722a21.317,21.317,0,0,0,11.41,3.288A20.851,20.851,0,0,0,38.584,16.175c0-.314-.011-.636-.025-.944A15.179,15.179,0,0,0,42.273,11.433Z" transform="translate(4.876 6.503)" fill="#fff" />
    </svg>,

    WhatappSocial: <svg id="whatsapp" xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 58 58">
        <path id="Path_9447" data-name="Path 9447" d="M29,58h0A29,29,0,0,1,0,29H0A29,29,0,0,1,29,0h0A29,29,0,0,1,58,29h0A29,29,0,0,1,29,58Z" fill="#25d366" />
        <g id="Group_553" data-name="Group 553" transform="translate(9.866 9.906)">
            <g id="WA_Logo">
                <g id="Group_552" data-name="Group 552">
                    <path id="Path_9448" data-name="Path 9448" d="M202.8,176.391a19.063,19.063,0,0,0-30,23l-2.7,9.877,10.1-2.651a19.009,19.009,0,0,0,9.106,2.32h.006A19.071,19.071,0,0,0,202.8,176.391Zm-13.479,29.325h-.006a15.793,15.793,0,0,1-8.062-2.21l-.58-.342-6,1.572,1.6-5.846-.377-.6a15.841,15.841,0,1,1,13.421,7.424Zm8.688-11.867c-.476-.238-2.819-1.392-3.254-1.549s-.754-.238-1.073.238-1.23,1.549-1.508,1.868-.557.36-1.032.122a12.954,12.954,0,0,1-3.828-2.366,14.461,14.461,0,0,1-2.651-3.3c-.278-.476-.029-.737.209-.974.215-.215.476-.557.713-.835a3.175,3.175,0,0,0,.476-.795.877.877,0,0,0-.041-.835c-.122-.238-1.073-2.581-1.467-3.538-.389-.928-.777-.8-1.073-.818-.278-.012-.6-.017-.911-.017a1.752,1.752,0,0,0-1.27.6,5.347,5.347,0,0,0-1.665,3.973,9.243,9.243,0,0,0,1.943,4.924c.238.319,3.358,5.127,8.137,7.192a27.213,27.213,0,0,0,2.714,1,6.5,6.5,0,0,0,3,.191c.916-.139,2.819-1.154,3.213-2.262a4.029,4.029,0,0,0,.278-2.262C198.8,194.209,198.485,194.093,198.01,193.849Z" transform="translate(-170.1 -170.8)" fill="#fff" fillRule="evenodd" />
                </g>
            </g>
        </g>
    </svg>,

    LinkedinSocial: <svg xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 58 58">
        <path id="linkedin" d="M49.276,46.666V35.344c0-6.066-3.238-8.889-7.555-8.889a6.514,6.514,0,0,0-5.915,3.262v-2.8H29.242c.087,1.853,0,19.746,0,19.746h6.564V35.638a4.476,4.476,0,0,1,.217-1.6,3.59,3.59,0,0,1,3.366-2.4c2.376,0,3.325,1.811,3.325,4.464V46.667h6.563ZM22.327,24.225a3.423,3.423,0,1,0,.043-6.824,3.421,3.421,0,1,0-.085,6.824ZM33,62A29,29,0,1,1,62,33,29,29,0,0,1,33,62ZM25.609,46.666V26.92H19.046V46.666Z" transform="translate(-4 -4)" fill="#007ab9" fillRule="evenodd" />
    </svg>,

    BsUnmuteSpeak: <svg id="audio_on_white" xmlns="http://www.w3.org/2000/svg" width="23.472" height="21.496" viewBox="0 0 23.472 21.496">
        <path id="Path_9624" data-name="Path 9624" d="M138.989,5.947a2.36,2.36,0,0,0-1.11-.129,2.086,2.086,0,0,0-1,.385l-6.131,4.821h-5.894c-.389,0-.7.238-.7.531v10.27c0,.294.315.531.7.531h5.907l6.194,4.584h0a1.989,1.989,0,0,0,1.182.355,2.6,2.6,0,0,0,.908-.166,1.814,1.814,0,0,0,.787-.534,1.179,1.179,0,0,0,.284-.769V7.27a1.2,1.2,0,0,0-.3-.787,1.858,1.858,0,0,0-.823-.535Zm-7.94,15.347H125.56V12.088h5.489Zm16.573-4.745c0,2.573-1.566,4.986-4.411,6.794a.867.867,0,0,1-.514.123.8.8,0,0,1-.479-.187.448.448,0,0,1,.085-.75c2.521-1.6,3.91-3.728,3.91-5.982s-1.389-4.377-3.91-5.981a.506.506,0,0,1-.248-.362.452.452,0,0,1,.163-.388.872.872,0,0,1,.994-.064c2.844,1.809,4.411,4.222,4.411,6.795Z" transform="translate(-124.15 -5.801)" fill="#fff" />
    </svg>,

    BsmuteSpeak: <svg xmlns="http://www.w3.org/2000/svg" width="28.081" height="21.496" viewBox="0 0 28.081 21.496">
        <g id="audio_off_white" transform="translate(-803.884 -13.504)">
            <path id="Path_9624" data-name="Path 9624" d="M138.989,5.947a2.36,2.36,0,0,0-1.11-.129,2.086,2.086,0,0,0-1,.385l-6.131,4.821h-5.894c-.389,0-.7.238-.7.531v10.27c0,.294.315.531.7.531h5.907l6.194,4.584h0a1.989,1.989,0,0,0,1.182.355,2.6,2.6,0,0,0,.908-.166,1.814,1.814,0,0,0,.787-.534,1.179,1.179,0,0,0,.284-.769V7.27a1.2,1.2,0,0,0-.3-.787,1.858,1.858,0,0,0-.823-.535Zm-7.94,15.347H125.56V12.088h5.489Z" transform="translate(679.734 7.703)" fill="#fff" />
            <path id="Path_9625" data-name="Path 9625" d="M.743-1.638,3.212-4.457,1.134-6.965a4.768,4.768,0,0,1-.44-.627,1.03,1.03,0,0,1-.147-.5.551.551,0,0,1,.282-.444,1.173,1.173,0,0,1,.689-.2,1.109,1.109,0,0,1,.726.215,5.677,5.677,0,0,1,.719.8l1.656,2.1,1.769-2.1.373-.453a2.539,2.539,0,0,1,.3-.312.979.979,0,0,1,.32-.185,1.243,1.243,0,0,1,.41-.062,1.131,1.131,0,0,1,.689.2.572.572,0,0,1,.267.468A1.936,1.936,0,0,1,8.166-7L5.99-4.457,8.331-1.638a4.6,4.6,0,0,1,.459.615.92.92,0,0,1,.143.462.614.614,0,0,1-.128.377.865.865,0,0,1-.361.271,1.325,1.325,0,0,1-.527.1,1.247,1.247,0,0,1-.535-.1,1.08,1.08,0,0,1-.354-.256q-.136-.153-.5-.595L4.582-3.156,2.52-.7q-.241.294-.343.412a1.727,1.727,0,0,1-.245.23,1.111,1.111,0,0,1-.339.177,1.465,1.465,0,0,1-.459.065A1.116,1.116,0,0,1,.461-.007.655.655,0,0,1,.193-.572,1.8,1.8,0,0,1,.743-1.638Z" transform="translate(823.031 28.527)" fill="#fff" />
        </g>
    </svg>,

    BrowserDevComp: <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
        <g id="browser" transform="translate(-829 -448)">
            <g id="Rectangle_4013" data-name="Rectangle 4013" transform="translate(829 448)" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
                <rect width="38" height="38" stroke="none" />
                <rect x="0.5" y="0.5" width="37" height="37" fill="none" />
            </g>
            <path id="web_browser" d="M773.1,241H749.9a2.884,2.884,0,0,0-2.885,2.875L747,261.125A2.9,2.9,0,0,0,749.9,264h23.2a2.9,2.9,0,0,0,2.9-2.875v-17.25A2.9,2.9,0,0,0,773.1,241Zm-23.2,7.188h15.225v5.031H749.9Zm0,7.906h15.225v5.031H749.9Zm23.2,5.031h-5.075V248.188H773.1Z" transform="translate(87 215)" fill="#5d5d5d" />
        </g>
    </svg>,

    CamraCheck: <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
        <g id="camera" transform="translate(-829 -522)">
            <g id="Rectangle_4014" data-name="Rectangle 4014" transform="translate(829 522)" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
                <rect width="38" height="38" stroke="none" />
                <rect x="0.5" y="0.5" width="37" height="37" fill="none" />
            </g>
            <path id="camera_enhance_FILL0_wght400_GRAD0_opsz48" d="M18.628,27.21a6.251,6.251,0,0,0,4.553-1.9,6.38,6.38,0,0,0,0-9.124,6.38,6.38,0,0,0-9.124,0,6.423,6.423,0,0,0,0,9.124A6.233,6.233,0,0,0,18.628,27.21Zm0-1.207-1.646-3.73-3.62-1.5,3.62-1.536,1.646-3.73,1.609,3.73,3.62,1.536-3.62,1.5ZM6.194,32.33a2.105,2.105,0,0,1-1.536-.658A2.105,2.105,0,0,1,4,30.136V11.376a2.126,2.126,0,0,1,.658-1.518,2.078,2.078,0,0,1,1.536-.677H11.57L14.24,6h8.777l2.67,3.182h5.376a2.1,2.1,0,0,1,1.518.677,2.1,2.1,0,0,1,.677,1.518v18.76a2.078,2.078,0,0,1-.677,1.536,2.126,2.126,0,0,1-1.518.658Zm24.867-2.194V11.376h-6.4l-2.67-3.182H15.264l-2.67,3.182h-6.4v18.76ZM18.628,20.738Z" transform="translate(829.001 522)" fill="#5d5d5d" />
        </g>
    </svg>,

    MicrophoneCheck: <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
        <g id="microphone" transform="translate(-829 -448)">
            <g id="Rectangle_4015" data-name="Rectangle 4015" transform="translate(829 448)" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
                <rect width="38" height="38" stroke="none" />
                <rect x="0.5" y="0.5" width="37" height="37" fill="none" />
            </g>
            <path id="mic_FILL0_wght400_GRAD0_opsz48" d="M21.7,23.1a3.954,3.954,0,0,1-3.01-1.3,4.424,4.424,0,0,1-1.212-3.135V8.18a4,4,0,0,1,1.233-2.968,4.291,4.291,0,0,1,5.978,0A4,4,0,0,1,25.926,8.18V18.672a4.424,4.424,0,0,1-1.212,3.135,3.954,3.954,0,0,1-3.01,1.3ZM21.7,13.572Zm-1.254,22.2V30.084a11.41,11.41,0,0,1-7.441-3.72A10.966,10.966,0,0,1,10,18.672h2.508a8.519,8.519,0,0,0,2.7,6.4,9.041,9.041,0,0,0,6.5,2.592,9.041,9.041,0,0,0,6.5-2.592,8.519,8.519,0,0,0,2.7-6.4h2.508a10.966,10.966,0,0,1-3.01,7.691,11.41,11.41,0,0,1-7.441,3.72v5.685ZM21.7,20.595a1.547,1.547,0,0,0,1.233-.564,2.025,2.025,0,0,0,.481-1.359V8.18a1.584,1.584,0,0,0-.5-1.191,1.769,1.769,0,0,0-2.424,0,1.584,1.584,0,0,0-.5,1.191V18.672a2.025,2.025,0,0,0,.481,1.359A1.547,1.547,0,0,0,21.7,20.595Z" transform="translate(826 447)" fill="#5d5d5d" />
        </g>
    </svg>,

    SpeakerCheck: <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
        <g id="speakers" transform="translate(-829 -522)">
            <g id="Rectangle_4016" data-name="Rectangle 4016" transform="translate(829 522)" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
                <rect width="38" height="38" stroke="none" />
                <rect x="0.5" y="0.5" width="37" height="37" fill="none" />
            </g>
            <path id="campaign_FILL0_wght400_GRAD0_opsz48" d="M29.067,21.5V19.184h5.785V21.5ZM31,32.681,26.329,29.21l1.388-1.851,4.666,3.471Zm-3.162-19.4-1.388-1.851L31,8l1.388,1.851ZM9.013,31.139v-6.17h-2.7A2.3,2.3,0,0,1,4,22.654V18.027a2.3,2.3,0,0,1,2.314-2.314h6.942l7.713-4.628V29.6l-7.713-4.628H11.327v6.17Zm13.5-5.63V15.173a7.045,7.045,0,0,1,1.678,2.256,6.98,6.98,0,0,1,0,5.823A7.045,7.045,0,0,1,22.511,25.508Zm-16.2-7.482v4.628h7.559l4.782,2.854V15.173l-4.782,2.854ZM12.484,20.341Z" transform="translate(828.574 520.659)" fill="#5d5d5d" />
        </g>
    </svg>,
    ResolutionCheck: <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
        <g id="resolution" transform="translate(-829 -449)">
            <g id="Rectangle_4017" data-name="Rectangle 4017" transform="translate(829 449)" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
                <rect width="38" height="38" stroke="none" />
                <rect x="0.5" y="0.5" width="37" height="37" fill="none" />
            </g>
            <path id="browser_resolution" d="M767.442,575.433H764.21v3.236h3.232Zm-8.608,0H755.6v3.236h3.232Zm14.88-4.549h-3.232v3.236h3.232Zm-10.592.224H759.89v3.236h3.232Zm13.568-3.556L774,565.759l-1.792,2.691,2.688,1.794Zm-6.88-.961h-3.232v3.236h3.232Zm-10.976.192H755.6v3.236h3.232Zm-4.544,4.325h-3.232v3.236h3.232Zm22.624-9-2.72-1.762-1.76,2.723,2.72,1.762Zm-6.688-.16-2.656-1.826-1.824,2.659,2.656,1.826Zm-7.1.513H759.89v3.236h3.232Zm5.664,17.171v.288A1.2,1.2,0,0,1,767.6,581.1H750.45a1.2,1.2,0,0,1-1.184-1.185V566.271a1.2,1.2,0,0,1,1.184-1.185h3.968v-2.915h-7.04a1.2,1.2,0,0,0-1.184,1.185v23.738a1.2,1.2,0,0,0,1.184,1.185h23.264a1.2,1.2,0,0,0,1.184-1.185V579.63Z" transform="translate(86.806 -106.12)" fill="#5d5d5d" />
        </g>
    </svg>,

    ConnectivityCheck: <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
        <g id="connectivity" transform="translate(-829 -523)">
            <g id="Rectangle_4018" data-name="Rectangle 4018" transform="translate(829 523)" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
                <rect width="38" height="38" stroke="none" />
                <rect x="0.5" y="0.5" width="37" height="37" fill="none" />
            </g>
            <path id="link_FILL0_wght400_GRAD0_opsz48_1_" data-name="link_FILL0_wght400_GRAD0_opsz48 (1)" d="M16.949,28H11a6.874,6.874,0,0,1-7-7,6.874,6.874,0,0,1,7-7h5.949v2.1H11A4.784,4.784,0,0,0,6.1,21,4.784,4.784,0,0,0,11,25.9h5.949Zm-4.375-5.949v-2.1H23.423v2.1ZM19.048,28V25.9H25A4.784,4.784,0,0,0,29.9,21,4.784,4.784,0,0,0,25,16.1H19.048V14H25a6.874,6.874,0,0,1,7,7,6.874,6.874,0,0,1-7,7Z" transform="translate(852.506 514.796) rotate(49)" fill="#5d5d5d" />
        </g>
    </svg>,

    PassedAction: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path id="tick_mark" d="M625,416.18,613.59,427.6l-4.24-4.24,1.41-1.41,2.83,2.83,10-10Zm-2.21,5.04A7.995,7.995,0,1,1,615,415a7.921,7.921,0,0,1,4.28,1.25l1.44-1.44A9.9,9.9,0,0,0,615,413a10.027,10.027,0,1,0,9.4,6.61Z" transform="translate(-605 -413)" fill="#16c443" />
    </svg>,

    FailedAction: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path id="cross_icon" d="M897.59,416,895,418.59,892.41,416,891,417.41l2.59,2.59L891,422.59l1.41,1.41,2.59-2.59,2.59,2.59,1.41-1.41L896.41,420l2.59-2.59ZM895,410a10,10,0,1,0,10,10A9.991,9.991,0,0,0,895,410Zm0,18a8,8,0,1,1,8-8A8.011,8.011,0,0,1,895,428Z" transform="translate(-885 -410)" fill="#de2f2f" />
    </svg>,

    alartInfoAction: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path id="tick_mark" d="M625,416.18,613.59,427.6l-4.24-4.24,1.41-1.41,2.83,2.83,10-10Zm-2.21,5.04A7.995,7.995,0,1,1,615,415a7.921,7.921,0,0,1,4.28,1.25l1.44-1.44A9.9,9.9,0,0,0,615,413a10.027,10.027,0,1,0,9.4,6.61Z" transform="translate(-605 -413)" fill="#F9BC34" />
    </svg>,

    LinkIcon: <svg xmlns="http://www.w3.org/2000/svg" width="15.783" height="15.792" viewBox="0 0 15.783 15.792">
        <g id="link_icon" transform="translate(0 0)">
            <path id="Path_9441" data-name="Path 9441" d="M310.458,245.52a4.6,4.6,0,0,1-3.206-1.32l-1.839-1.863a.7.7,0,0,1,.99-.99l1.862,1.862a3.1,3.1,0,1,0,4.385-4.385l-1.862-1.862a.7.7,0,1,1,.99-.99l1.862,1.862a4.51,4.51,0,0,1,0,6.389,4.576,4.576,0,0,1-3.183,1.3Z" transform="translate(-299.183 -229.728)" fill="#333" />
            <path id="Path_9442" data-name="Path 9442" d="M165.944,102.253a.679.679,0,0,1-.5-.212l-1.862-1.862a4.518,4.518,0,0,1,6.389-6.389l1.862,1.862a.7.7,0,0,1-.99.99L168.985,94.8a3.1,3.1,0,1,0-4.385,4.385l1.862,1.862a.684.684,0,0,1,0,.99.731.731,0,0,1-.519.212Z" transform="translate(-162.26 -92.472)" fill="#333" />
            <path id="Path_9443" data-name="Path 9443" d="M300.549,231.794a.679.679,0,0,1-.5-.212l-3.041-3.018a.7.7,0,0,1,.99-.99l3.041,3.041a.684.684,0,0,1,0,.99.716.716,0,0,1-.5.189Z" transform="translate(-291.137 -221.683)" fill="#333" />
        </g>
    </svg>,

    DesktopIconShare: <svg xmlns="http://www.w3.org/2000/svg" width="60.974" height="58.126" viewBox="0 0 60.974 58.126">
        <g id="share_screen" transform="translate(-240.91 -188.611)">
            <path id="Path_9627" data-name="Path 9627" d="M240.91,198.778v25.544a10.185,10.185,0,0,0,10.136,10.152h17.989v7.571H257.3a2.346,2.346,0,1,0,0,4.693H285.46a2.346,2.346,0,1,0,0-4.693H273.728v-7.57h17.989a10.183,10.183,0,0,0,10.167-10.168V214.138a2.346,2.346,0,0,0-4.693,0v10.183a5.474,5.474,0,0,1-5.474,5.459h-40.67a5.474,5.474,0,0,1-5.474-5.475V198.777a5.474,5.474,0,0,1,5.474-5.475h24.636a2.346,2.346,0,1,0,0-4.692H251.048a10.184,10.184,0,0,0-10.136,10.167Z" fill="#333" />
            <path id="Path_9628" data-name="Path 9628" d="M409.4,206.395a2.332,2.332,0,0,0,2.346-2.331V191.1a2.325,2.325,0,0,0-.172-.86,2.414,2.414,0,0,0-.5-.767,2.573,2.573,0,0,0-.767-.516,2.207,2.207,0,0,0-.813-.235H396.781a2.346,2.346,0,1,0,0,4.693h7.07l-9.667,9.667a2.33,2.33,0,0,0,0,3.316,2.363,2.363,0,0,0,1.657.689,2.331,2.331,0,0,0,1.627-.689l9.636-9.62v7.274a2.346,2.346,0,0,0,2.3,2.346Z" transform="translate(-109.962 -0.078)" fill="#333" />
        </g>
    </svg>,

    DocIconShare: <svg xmlns="http://www.w3.org/2000/svg" width="48.15" height="61.53" viewBox="0 0 48.15 61.53">
        <path id="select_pdf" d="M141.406,11.645A1.411,1.411,0,0,0,140,13.058V71.765h0a1.412,1.412,0,0,0,1.411,1.411h45.329a1.412,1.412,0,0,0,1.411-1.411V30.414l0-.04q0-.071-.011-.141c-.006-.047-.015-.093-.026-.138s-.024-.091-.039-.136-.033-.086-.052-.128v-.005c-.02-.042-.042-.083-.065-.123s-.048-.079-.076-.117-.057-.074-.089-.11c-.017-.02-.035-.039-.052-.057L170.374,12.061h0a1.413,1.413,0,0,0-1-.416H141.406Zm1.413,2.824h25.146V30.415h0a1.411,1.411,0,0,0,1.413,1.413h15.944V70.352h-42.5Zm27.971,2,12.536,12.54H170.789ZM160.8,36.107a3.546,3.546,0,0,0-1.058.112,3.5,3.5,0,0,0-2.563,2.923,7.443,7.443,0,0,0,.987,3.974,31.905,31.905,0,0,0,3.009,3.921,35.837,35.837,0,0,1-.614,4.561,35.84,35.84,0,0,1-1.747,4.256,31.913,31.913,0,0,0-4.569,1.894,7.43,7.43,0,0,0-2.845,2.939,3.5,3.5,0,0,0,4.58,4.572,7.407,7.407,0,0,0,2.939-2.837,31.929,31.929,0,0,0,1.891-4.574,35.821,35.821,0,0,1,4.255-1.748,35.853,35.853,0,0,1,4.561-.614,31.836,31.836,0,0,0,3.924,3.009,7.424,7.424,0,0,0,3.966.993,3.5,3.5,0,0,0,1.674-6.244,7.412,7.412,0,0,0-3.926-1.137,32.05,32.05,0,0,0-4.9.653,35.851,35.851,0,0,1-3.642-2.814,35.924,35.924,0,0,1-2.814-3.642,31.827,31.827,0,0,0,.643-4.9,7.438,7.438,0,0,0-1.134-3.934,3.436,3.436,0,0,0-2.618-1.361Zm-.013,2.584c.264-.046.293.046.489.319a5.724,5.724,0,0,1,.635,2.565c.031.466-.233,1.292-.248,1.834-.282-.459-.918-1.041-1.123-1.458a5.713,5.713,0,0,1-.726-2.547c.043-.446,0-.461.619-.627.155-.04.267-.072.355-.086Zm2.639,11.267c.566.624.82,1.256,1.424,1.86s1.235.862,1.86,1.429c-.824.178-1.5.085-2.325.306s-1.36.641-2.163.9c.258-.8.674-1.348.9-2.176s.13-1.494.308-2.317Zm11.659,4.789a5.735,5.735,0,0,1,2.573.645c.365.261.4.228.23.849s-.184.568-.63.611a5.633,5.633,0,0,1-2.536-.724c-.421-.207-1-.851-1.468-1.137.541-.015,1.366-.276,1.831-.246Zm-17.656,4.491c-.258.477-.44,1.323-.7,1.711a5.709,5.709,0,0,1-1.847,1.9c-.408.185-.395.232-.849-.222s-.4-.436-.217-.844a5.708,5.708,0,0,1,1.9-1.842c.388-.26,1.234-.447,1.711-.705Z" transform="translate(-139.995 -11.645)" fill="#333" />
    </svg>,

    NextArrow: <svg xmlns="http://www.w3.org/2000/svg" width="9.313" height="16" viewBox="0 0 9.313 16"><path id="arrow" d="M10.306,21.711,17,15.043l6.679,6.668L25,20.4l-8-8-8,8Z" transform="translate(21.711 -9) rotate(90)" fill="#fff" /></svg>,
    PrevArrow: <svg xmlns="http://www.w3.org/2000/svg" width="9.313" height="16" viewBox="0 0 9.313 16"><path id="speaker_back_arrow" d="M1.306,0,8,6.668,14.679,0,16,1.313l-8,8-8-8Z" transform="translate(9.313) rotate(90)" fill="#fff" /></svg>,
    BackLoginArrow: <svg xmlns="http://www.w3.org/2000/svg" width="9.313" height="16" viewBox="0 0 9.313 16"><path id="speaker_back_arrow" d="M1.306,0,8,6.668,14.679,0,16,1.313l-8,8-8-8Z" transform="translate(9.313) rotate(90)" fill="" /></svg>,

    warning: <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25.947" viewBox="0 0 26 25.947">
        <g id="warning_toast" transform="translate(-69.98 2.45)">
            <path id="Path_9639" data-name="Path 9639" d="M95.98,13.524,82.98.55l-13,12.974,13,12.973ZM84.244,6.8l-.353,9.111H82.05L81.7,6.8ZM81.5,18.664a1.48,1.48,0,1,1,1.469,1.525A1.423,1.423,0,0,1,81.5,18.664Z" transform="translate(0 -3)" fill="#fff" />
        </g>
    </svg>,

    error: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20.581" viewBox="0 0 24 20.581">
        <g id="toast_error" transform="translate(-116.899 -78.389)">
            <path id="Path_9637" data-name="Path 9637" d="M126.834,78.39a1.367,1.367,0,0,0-1.094.648L115.065,97.026a1.314,1.314,0,0,0,.01,1.3,1.361,1.361,0,0,0,1.155.648h21.33a1.391,1.391,0,0,0,1.165-.648,1.29,1.29,0,0,0,0-1.3L128.049,79.038a1.383,1.383,0,0,0-1.215-.648Zm.061,5.429a1.426,1.426,0,0,1,1.428,1.428l-.284,5.145a1.144,1.144,0,1,1-2.289,0l-.284-5.145a1.426,1.426,0,0,1,1.428-1.428Zm0,8.579a1.717,1.717,0,1,1-1.712,1.712A1.718,1.718,0,0,1,126.895,92.4Z" transform="translate(2)" fill="#fff" />
        </g>
    </svg>,

    info: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path id="info_toast" d="M12.563.563a12,12,0,1,0,12,12A12,12,0,0,0,12.563.563Zm0,5.323A2.032,2.032,0,1,1,10.53,7.917,2.032,2.032,0,0,1,12.563,5.885Zm2.71,12.29a.581.581,0,0,1-.581.581H10.433a.581.581,0,0,1-.581-.581V17.014a.581.581,0,0,1,.581-.581h.581v-3.1h-.581a.581.581,0,0,1-.581-.581V11.595a.581.581,0,0,1,.581-.581h3.1a.581.581,0,0,1,.581.581v4.839h.581a.581.581,0,0,1,.581.581Z" transform="translate(-0.563 -0.563)" fill="#fff" />
    </svg>,

    success: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path id="sucess_mark" d="M24.563,12.562a12,12,0,1,1-12-12A12,12,0,0,1,24.563,12.562ZM11.174,18.916l8.9-8.9a.774.774,0,0,0,0-1.095L18.983,7.823a.774.774,0,0,0-1.095,0l-7.261,7.261-3.39-3.39a.774.774,0,0,0-1.095,0L5.047,12.789a.774.774,0,0,0,0,1.095l5.032,5.032a.774.774,0,0,0,1.095,0Z" transform="translate(-0.563 -0.563)" fill="#fff" />
    </svg>,

    DeleteBg: <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
        <g id="delete_icon" transform="translate(-1342 -347)">
            <g id="Rectangle_4048" data-name="Rectangle 4048" transform="translate(1342 347)" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
                <rect width="48" height="48" stroke="none" />
                <rect x="0.5" y="0.5" width="47" height="47" fill="none" />
            </g>
            <path id="delete_icon-2" data-name="delete_icon" d="M4.491,26.873a2.991,2.991,0,0,0,2.982,2.982h8.946A2.991,2.991,0,0,0,19.4,26.873V11.964H4.491Zm16.4-19.382H16.418L14.927,6H8.964L7.473,7.491H3v2.982H20.891Z" transform="translate(1354.054 353.385)" fill="#333" />
        </g>
    </svg>,

    BgBlur: <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
        <g id="soft_blur" transform="translate(-1342 -347)">
            <g id="Rectangle_4049" data-name="Rectangle 4049" transform="translate(1342 347)" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
                <rect width="48" height="48" stroke="none" />
                <rect x="0.5" y="0.5" width="47" height="47" fill="none" />
            </g>
            <path id="soft_blur-2" data-name="soft_blur" d="M239.278,166.9a2.3,2.3,0,0,1,2.264,2.372,2.211,2.211,0,0,1-2.264,2.264,2.3,2.3,0,0,1-2.372-2.264A2.394,2.394,0,0,1,239.278,166.9Zm0,8.088a1.109,1.109,0,0,1,1.079,1.187,1.019,1.019,0,0,1-1.079,1.079,1.11,1.11,0,0,1-1.187-1.079A1.2,1.2,0,0,1,239.278,174.989Zm6.9-16.067A1.02,1.02,0,0,1,247.258,160a1.11,1.11,0,0,1-1.079,1.187A1.2,1.2,0,0,1,244.992,160,1.107,1.107,0,0,1,246.179,158.922Zm0,9.165a1.109,1.109,0,0,1,1.079,1.187,1.019,1.019,0,0,1-1.079,1.079,1.11,1.11,0,0,1-1.187-1.079A1.2,1.2,0,0,1,246.179,168.088Zm-6.9-13.8a1.2,1.2,0,0,1-1.187-1.187,1.107,1.107,0,0,1,1.187-1.079,1.019,1.019,0,0,1,1.079,1.079A1.11,1.11,0,0,1,239.278,154.285Zm0,3.451A2.21,2.21,0,0,1,241.541,160a2.3,2.3,0,0,1-2.264,2.372A2.394,2.394,0,0,1,236.906,160,2.3,2.3,0,0,1,239.278,157.735ZM230,154.285a1.109,1.109,0,0,1-1.079-1.187A1.019,1.019,0,0,1,230,152.019a1.11,1.11,0,0,1,1.187,1.079A1.2,1.2,0,0,1,230,154.285Zm-6.9,13.8a1.2,1.2,0,0,1,1.187,1.187,1.107,1.107,0,0,1-1.187,1.079,1.019,1.019,0,0,1-1.079-1.079A1.11,1.11,0,0,1,223.1,168.088Zm6.9,6.9a1.2,1.2,0,0,1,1.187,1.187A1.107,1.107,0,0,1,230,177.255a1.019,1.019,0,0,1-1.079-1.079A1.11,1.11,0,0,1,230,174.989Zm-6.9-16.067A1.11,1.11,0,0,1,224.288,160a1.2,1.2,0,0,1-1.187,1.187A1.107,1.107,0,0,1,222.022,160,1.019,1.019,0,0,1,223.1,158.922ZM230,166.9a2.393,2.393,0,0,1,2.372,2.372A2.3,2.3,0,0,1,230,171.537a2.211,2.211,0,0,1-2.264-2.264A2.3,2.3,0,0,1,230,166.9Zm0-9.165A2.3,2.3,0,0,1,232.374,160,2.394,2.394,0,0,1,230,162.371,2.3,2.3,0,0,1,227.738,160,2.211,2.211,0,0,1,230,157.736Z" transform="translate(1130.978 205.981)" fill="#333" />
        </g>
    </svg>,

    BgMoreBlur: <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
        <g id="hard_blur" transform="translate(-1342 -347)">
            <g id="Rectangle_4050" data-name="Rectangle 4050" transform="translate(1342 347)" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
                <rect width="48" height="48" stroke="none" />
                <rect x="0.5" y="0.5" width="47" height="47" fill="none" />
            </g>
            <path id="hard_blur-2" data-name="hard_blur" d="M146,67.346a2.347,2.347,0,1,1-2.347,2.347A2.317,2.317,0,0,1,146,67.346Zm0,6.308A2.347,2.347,0,1,1,143.657,76,2.317,2.317,0,0,1,146,73.654ZM139.7,80.694a1.541,1.541,0,1,1,0,3.079,1.54,1.54,0,1,1,0-3.079Zm0-13.349a2.347,2.347,0,1,1-2.347,2.347A2.317,2.317,0,0,1,139.7,67.345ZM146,86.12a.811.811,0,0,1,0,1.615.807.807,0,1,1,0-1.615Zm0-5.426a1.54,1.54,0,1,1,0,3.079,1.541,1.541,0,1,1,0-3.079Zm10.927-5.5a.814.814,0,0,1,.807.807.811.811,0,0,1-1.615,0A.814.814,0,0,1,156.931,75.193Zm-4.694-13.274a1.54,1.54,0,1,1-1.54,1.54A1.5,1.5,0,0,1,152.237,61.919Zm0,6.233a1.5,1.5,0,0,1,1.54,1.54,1.541,1.541,0,1,1-3.079,0A1.5,1.5,0,0,1,152.237,68.153Zm0,12.542a1.54,1.54,0,1,1-1.54,1.54A1.5,1.5,0,0,1,152.237,80.694Zm0-6.308A1.565,1.565,0,0,1,153.777,76a1.54,1.54,0,1,1-3.079,0A1.566,1.566,0,0,1,152.237,74.386ZM139.7,73.654A2.347,2.347,0,1,1,137.348,76,2.317,2.317,0,0,1,139.7,73.654Zm0-8.655a1.54,1.54,0,0,1,0-3.079,1.541,1.541,0,1,1,0,3.079Zm0-5.426a.811.811,0,0,1,0-1.615.807.807,0,0,1,0,1.615Zm0,26.548a.807.807,0,0,1,0,1.615.811.811,0,0,1,0-1.615ZM128.768,75.193a.814.814,0,0,1,.807.807.811.811,0,0,1-1.615,0A.814.814,0,0,1,128.768,75.193ZM146,59.572a.807.807,0,1,1,0-1.615.811.811,0,0,1,0,1.615ZM146,65a1.541,1.541,0,1,1,0-3.079A1.54,1.54,0,0,1,146,65Zm10.927,5.5a.814.814,0,0,1-.807-.807.811.811,0,0,1,1.615,0A.814.814,0,0,1,156.931,70.5Zm-23.469-8.58a1.54,1.54,0,1,1-1.54,1.54A1.5,1.5,0,0,1,133.462,61.919Zm-4.694,7.041a.755.755,0,0,1,.807.734.807.807,0,1,1-1.615,0A.753.753,0,0,1,128.768,68.96Zm4.694-.807a1.5,1.5,0,0,1,1.54,1.54,1.541,1.541,0,1,1-3.079,0A1.5,1.5,0,0,1,133.462,68.153Zm0,12.542a1.54,1.54,0,1,1-1.54,1.54A1.5,1.5,0,0,1,133.462,80.694Zm0-6.308A1.565,1.565,0,0,1,135,76a1.54,1.54,0,1,1-3.079,0A1.566,1.566,0,0,1,133.462,74.386Z" transform="translate(1223.039 298.042)" fill="#333" />
        </g>
    </svg>,

    ResetLanguage: <svg id="refresh_icon" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
        <path id="refresh_icon-2" data-name="refresh_icon" d="M28.194,9.819A13,13,0,1,0,31.576,22.25H28.194a9.75,9.75,0,1,1-9.187-13,9.621,9.621,0,0,1,6.862,2.892l-5.236,5.233H32.015V6Z" transform="translate(-6.015 -6)" fill="#4285f4" />
    </svg>,

    CrossIcon: <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8 8.707l3.646 3.647.708-.707L8.707 8l3.647-3.646-.707-.708L8 7.293 4.354 3.646l-.707.708L7.293 8l-3.646 3.646.707.708L8 8.707z"></path></svg>,

    NotificationArrow: (id, url) => <svg data-uid={id} data-url={url} xmlns="http://www.w3.org/2000/svg" width="15.772" height="15.398" viewBox="0 0 15.772 15.398">
        <path data-uid={id} data-url={url} id="arrow" d="M8.491,3.612,7.759,2.88a.788.788,0,0,0-1.118,0L.232,9.286a.788.788,0,0,0,0,1.118l6.409,6.409a.788.788,0,0,0,1.118,0l.732-.732a.792.792,0,0,0-.013-1.131L4.505,11.165h9.475a.789.789,0,0,0,.791-.791V9.319a.789.789,0,0,0-.791-.791H4.505L8.478,4.742A.786.786,0,0,0,8.491,3.612Z" transform="translate(0.5 -2.147)" fill="#333" stroke="#f1f3f4" strokeWidth="1" />
    </svg>,

    EmojiIcon: <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
        <g id="emoji_icon" transform="translate(-4.32 -4.32)">
            <g id="Group_543" data-name="Group 543" transform="translate(4.32 4.32)">
                <path id="Path_1063" data-name="Path 1063" d="M19.32,4.32a15,15,0,1,0,15,15A15,15,0,0,0,19.32,4.32Zm5.549,9.288A2.173,2.173,0,1,1,22.7,15.781,2.173,2.173,0,0,1,24.869,13.608Zm-11.1,0A2.173,2.173,0,1,1,11.6,15.781,2.169,2.169,0,0,1,13.771,13.608ZM28.088,25.763a12.993,12.993,0,0,1-8.7,3.338,17.007,17.007,0,0,1-8.757-2.661,1.085,1.085,0,0,1,1.246-1.777c.325.228,7.917,5.4,14.794-.542a1.083,1.083,0,0,1,1.414,1.641Z" transform="translate(-4.32 -4.32)" fill="#848484" />
            </g>
        </g>
    </svg>,
    YourBoothIcon: <svg xmlns="http://www.w3.org/2000/svg" width="20.482" height="16.417" viewBox="0 0 20.482 16.417">
        <g id="your_booth" transform="translate(-102.244 -69.454)">
        <path id="Path_9795" data-name="Path 9795" d="M122.2,72.694,112.75,69.72a.515.515,0,0,0-.253,0l-9.7,2.868a.438.438,0,0,0-.3.337.4.4,0,0,0,.19.4l2.4,1.834v7.951a.411.411,0,0,0,.3.4l7.065,2.088a.391.391,0,0,0,.253,0l7.065-2.088a.405.405,0,0,0,.3-.4V75.16l2.213-1.728a.438.438,0,0,0,.19-.4.414.414,0,0,0-.274-.337ZM112.159,84.63l-6.222-1.835V75.265l6.222-1.814Zm7.087-1.835-6.222,1.835V73.451l6.222,1.814Zm.337-8.31-6.876-2H112.5l-6.9,2-1.686-1.349,8.709-2.573,8.519,2.657Z" fill="#333" stroke="#333" stroke-width="0.5"/>
        </g>
    </svg>,
    PlayIconUT:  <svg height="100%" version="1.1" viewBox="0 0 36 36" width="100%"><path d="M 12,26 16,26 16,10 12,10 z M 21,26 25,26 25,10 21,10 z"></path></svg>,
    PauseIconUT: <svg height="100%" version="1.1" viewBox="0 0 36 36" width="100%"><path d="M 12,26 18.5,22 18.5,14 12,10 z M 18.5,22 25,18 25,18 18.5,14 z"></path></svg>,
    MuteIconUT: <svg height="100%" version="1.1" viewBox="0 0 36 36" width="100%"><path d="M8,21 L12,21 L17,26 L17,10 L12,15 L8,15 L8,21 Z M19,14 L19,22 C20.48,21.32 21.5,19.77 21.5,18 C21.5,16.26 20.48,14.74 19,14 ZM19,11.29 C21.89,12.15 24,14.83 24,18 C24,21.17 21.89,23.85 19,24.71 L19,26.77 C23.01,25.86 26,22.28 26,18 C26,13.72 23.01,10.14 19,9.23 L19,11.29 Z" fill="#fff"></path></svg>,
    UnmuteIconUT: <svg height="100%" version="1.1" viewBox="0 0 36 36" width="100%"><path d="m 21.48,17.98 c 0,-1.77 -1.02,-3.29 -2.5,-4.03 v 2.21 l 2.45,2.45 c .03,-0.2 .05,-0.41 .05,-0.63 z m 2.5,0 c 0,.94 -0.2,1.82 -0.54,2.64 l 1.51,1.51 c .66,-1.24 1.03,-2.65 1.03,-4.15 0,-4.28 -2.99,-7.86 -7,-8.76 v 2.05 c 2.89,.86 5,3.54 5,6.71 z M 9.25,8.98 l -1.27,1.26 4.72,4.73 H 7.98 v 6 H 11.98 l 5,5 v -6.73 l 4.25,4.25 c -0.67,.52 -1.42,.93 -2.25,1.18 v 2.06 c 1.38,-0.31 2.63,-0.95 3.69,-1.81 l 2.04,2.05 1.27,-1.27 -9,-9 -7.72,-7.72 z m 7.72,.99 -2.09,2.08 2.09,2.09 V 9.98 z"></path></svg>,
    FullScreenIcon: <svg height="100%" version="1.1" viewBox="0 0 36 36" width="100%"><path d="m 10,16 2,0 0,-4 4,0 0,-2 L 10,10 l 0,6 0,0 z"></path><path d="m 20,10 0,2 4,0 0,4 2,0 L 26,10 l -6,0 0,0 z"></path><path d="m 24,24 -4,0 0,2 L 26,26 l 0,-6 -2,0 0,4 0,0 z"></path><path d="M 12,20 10,20 10,26 l 6,0 0,-2 -4,0 0,-4 0,0 z"></path></svg>,
    ExitFullScreen: <svg height="100%" version="1.1" viewBox="0 0 36 36" width="100%"><path d="m 14,14 -4,0 0,2 6,0 0,-6 -2,0 0,4 0,0 z"></path><path d="m 22,14 0,-4 -2,0 0,6 6,0 0,-2 -4,0 0,0 z"></path><path d="m 20,26 2,0 0,-4 4,0 0,-2 -6,0 0,6 0,0 z"></path><path d="m 10,22 4,0 0,4 2,0 0,-6 -6,0 0,2 0,0 z"></path></svg>,
    MeetingAvailability: <svg xmlns="http://www.w3.org/2000/svg" width="20.46" height="21.739" viewBox="0 0 20.46 21.739">
    <path id="meeting_availability" d="M179.958,71.279h-1.279v-.639a.639.639,0,1,0-1.279,0v.639h-7.672v-.639a.639.639,0,1,0-1.279,0v.639H167.17a3.837,3.837,0,0,0-3.836,3.836V87.9a3.836,3.836,0,0,0,3.836,3.836h12.788a3.836,3.836,0,0,0,3.836-3.836V75.115a3.837,3.837,0,0,0-3.836-3.836ZM182.515,87.9h0a2.56,2.56,0,0,1-2.557,2.557H167.17a2.56,2.56,0,0,1-2.557-2.557V77.672h17.9Zm-2.558-3.836h0a2.557,2.557,0,1,1-.749-1.809,2.558,2.558,0,0,1,.749,1.809Z" transform="translate(-163.334 -70)" fill="#5d5d5d"/>
  </svg>,
    MyMeetings: <svg xmlns="http://www.w3.org/2000/svg" id="my_meeting_icon" width="24.565" height="21.772" viewBox="0 0 24.565 21.772">
    <path id="Path_9796" data-name="Path 9796" d="M267.3,232.268a3.163,3.163,0,1,0-3.125-3.162A3.143,3.143,0,0,0,267.3,232.268Zm6.724,9a9.753,9.753,0,0,1-3.676-4.687c-.383-2.072-1.539-3.642-3.7-3.642-2.439,0-3.777,2-3.777,4.467v10.307h7.553v-5.228a11.923,11.923,0,0,0,1.875,1.5,1.582,1.582,0,0,0,2.2-.488,1.626,1.626,0,0,0-.481-2.23Zm8.989-9a3.163,3.163,0,1,0-3.125-3.162A3.143,3.143,0,0,0,283.013,232.268Zm.651.673c-2.16,0-3.315,1.569-3.7,3.642a9.767,9.767,0,0,1-3.676,4.687,1.628,1.628,0,0,0-.483,2.231,1.583,1.583,0,0,0,2.2.488,12.078,12.078,0,0,0,1.875-1.5v5.228h7.551V237.409c0-2.468-1.336-4.468-3.774-4.468Z" transform="translate(-262.874 -225.943)" fill="#5d5d5d"/>
  </svg>,
  EyeOffIcon: (
    <svg
      id="Off"
      xmlns="http://www.w3.org/2000/svg"
      width="31.912"
      height="25.529"
      viewBox="0 0 31.912 25.529"
    >
      <path
        id="Icon_awesome-eye-slash"
        data-name="Icon awesome-eye-slash"
        d="M15.956,19.944a7.153,7.153,0,0,1-7.125-6.637L3.6,9.265a16.618,16.618,0,0,0-1.831,2.772,1.613,1.613,0,0,0,0,1.455,15.993,15.993,0,0,0,14.187,8.845,15.488,15.488,0,0,0,3.884-.522l-2.587-2a7.187,7.187,0,0,1-1.3.13Zm15.648,2.9-5.512-4.26a16.517,16.517,0,0,0,4.051-5.089,1.613,1.613,0,0,0,0-1.455A15.993,15.993,0,0,0,15.956,3.191,15.365,15.365,0,0,0,8.61,5.071L2.267.168a.8.8,0,0,0-1.12.14L.168,1.568a.8.8,0,0,0,.14,1.119L29.645,25.361a.8.8,0,0,0,1.12-.14l.979-1.26a.8.8,0,0,0-.141-1.119Zm-9.161-7.08-1.96-1.515a4.725,4.725,0,0,0-5.789-6.08,2.376,2.376,0,0,1,.464,1.407,2.326,2.326,0,0,1-.077.5l-3.67-2.837a7.1,7.1,0,0,1,4.545-1.651,7.176,7.176,0,0,1,7.18,7.18,7.01,7.01,0,0,1-.693,3Z"
        transform="translate(0 0)"
        fill="#737376"
      ></path>
    </svg>
  ),

  EyeOnIcon: (
    <svg
      id="Off"
      xmlns="http://www.w3.org/2000/svg"
      width="28.72"
      height="19.147"
      viewBox="0 0 28.72 19.147"
    >
      <path
        id="Icon_awesome-eye"
        data-name="Icon awesome-eye"
        d="M28.547,13.345A15.992,15.992,0,0,0,14.36,4.5,16,16,0,0,0,.174,13.346a1.613,1.613,0,0,0,0,1.455A15.992,15.992,0,0,0,14.36,23.647,16,16,0,0,0,28.547,14.8,1.613,1.613,0,0,0,28.547,13.345ZM14.36,21.253a7.18,7.18,0,1,1,7.18-7.18A7.18,7.18,0,0,1,14.36,21.253Zm0-11.967a4.752,4.752,0,0,0-1.262.189,2.386,2.386,0,0,1-3.336,3.336,4.776,4.776,0,1,0,4.6-3.525Z"
        transform="translate(0 -4.5)"
        fill="#737376"
      ></path>
    </svg>
  ),
}
