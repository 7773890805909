
import React, { Fragment, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import BasePage from "./BasePage";
import AuthPage from "./AuthPage";
import ReactGA from 'react-ga';
import { useRouter } from "../components/customHooks/useRouter";
import MetaData from "../components/MetaData/MetaData";
import { checkBoothOwner } from "../redux/actions";

const { initialize, pageview, ga } = ReactGA;

initialize(`${process.env.REACT_APP_GOOGLE_TRACKING_ID}`);


export function Routes() {
  const router = useRouter();
  // const dispatch = useDispatch();
  
  const { isAuthorized, eventData, isEmbeddedRegistration, checkBoothOwnerData, authToken } = useSelector(({ auth, event }) => {
    const isBoothOwner = auth?.boothOwnerData?.is_booth_owner

    return {
      isAuthorized: auth.authToken !== null && auth.authToken !== undefined && event.eventRegistrationSuccessFull && (event.eventCanCheckIn || auth?.userData?.role === 'organizer' || auth?.userData?.role === 'speaker' || (auth?.userData?.role === 'attendee' && isBoothOwner)),
      eventRegistrationSuccessFull: event.eventRegistrationSuccessFull,
      loginSuccess: auth.loginSuccess,
      authToken: auth.authToken,
      eventData: event?.eventData,
      isEmbeddedRegistration: auth?.isEmbeddedRegistration,
      checkBoothOwnerData: auth.checkBoothOwnerData
    }
  }, shallowEqual);

  // useEffect(() => {
  //   if(eventData?.uid && authToken) {
  //     dispatch(checkBoothOwner(eventData.uid));
  //   }
  // }, [authToken]);

  useEffect(() => {
    pageview(window.location.pathname + window.location.search);
    ga("send", "pageview", window.location.pathname);
  }, [router.pathname]);

  useEffect(() => {
    window._mfq = window._mfq || [];
    (function () {
      var mf = document.createElement("script");
      mf.type = "text/javascript"; mf.defer = true;
      mf.src = "//cdn.mouseflow.com/projects/4ba4e6a0-931b-418d-a401-65d05ca80799.js";
      document.getElementsByTagName("head")[0].appendChild(mf);
    })();
  }, []);

  return (
    <Fragment>
      <MetaData metaData={{
        url: `https://mixhub.godreamcast.com/${(eventData?.uid)}/registration`,
        description: (eventData?.og_description) || (eventData?.description),
        title: (eventData?.og_title) || (eventData?.title),
        image: (eventData?.og_image_url) || (eventData?.cover_image_url)
      }} />
      <Switch>
        <Route>
          {(!isAuthorized || (isAuthorized && isEmbeddedRegistration)) ? <AuthPage /> : <BasePage />}
        </Route>
      </Switch>
    </Fragment>
  );
}
