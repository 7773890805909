import { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import Loader from "../components/Loader/Loader";

const timeOut = 300;

const RegistrationPage = lazy(() => import("../pages/Registration"));
const DirectLogin = lazy(() => import("../pages/DirectLogin"));
const StageRecording = lazy(() => new Promise(resolve => { setTimeout(() => resolve(import("../pages/StageRecording")), timeOut) }));
const EventExpired = lazy(() => import("../pages/EventExpired"));
const EmbedRegistrationPage = lazy(() => import("../components/NewRegistration/NewRegistrationPage"));

const AuthRoute = [
    { path: "registration/:code?", component: RegistrationPage },
    { path: "embedded-registration/:code?", component: EmbedRegistrationPage},
    { path: "stages/:stageId/:sessionId/stageRecording", component: StageRecording },
    { path: "event-expired", component: EventExpired },
    { path: "direct-login", component: DirectLogin },

];

const AuthPage = () => {
    return (
        <Suspense fallback={<Loader />}>
            <Switch>
                {/* <Redirect exact path="/" to="/registration" /> */}
                {AuthRoute.map(({ path, component }) => {
                    return (
                        <Route key={path} path={`/:id/${path}`} component={component} />
                    )
                })}
                <Redirect path="/:id" to="/:id/registration/:searcValue?" />
            </Switch>
        </Suspense>
    );
}

export default AuthPage;
